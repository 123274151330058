import '@/styles/Clients.scss';
import { useEffect, useState } from 'react';
import Search from 'antd/es/input/Search';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';
import { TClientStoreFormat } from '@/types/store';
import { getClientByIin, setTargetedClient } from '@/store/clients.slice';
import { clientsColumns } from '@/data/clients.data';
import { getFullPageTableHeight } from '@/services';
import { VerifyOTP } from '@/components/Shared/Modal/VerifyOTP/VerifyOTP';
import {
  setShowVerifyFirstStep,
  setShowVerifySecondStep
} from '@/store/app.slice';
import { requestCreateDraft, validateOTP } from '@/store/orderStep.slice';

const Clients = () => {
  const dispatch = useAppDispatch();
  const { clients, isLoading, targetedClient } = useAppSelector(
    (state) => state.clients
  );
  // const { showVerifyFirstStep, showVerifySecondStep } = useAppSelector(
  //   (state) => state.app
  // );
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');

  const [clientsArray, setClientArray] = useState<TClientStoreFormat[]>([]);

  const handleSearch = async () => {
    await dispatch(getClientByIin(searchValue));
  };

  const handleShowClientDetails = (client: TClientStoreFormat) => {
    dispatch(setTargetedClient(client));
    navigate('/clientRequestsDetails');
  };

  // const createDraftRequest = () => {};

  // useEffect(() => {
  //   dispatch(getClients());
  // }, []);

  useEffect(() => {
    setClientArray(clients);
  }, [clients]);

  return (
    <div className="Clients">
      <div className="content">
        <div className="header">
          <div className="title">
            <h2>Количество: {clientsArray?.length || 0}</h2>
            <p>Здесь небольшое описание</p>
          </div>
          <Search
            style={{ width: '40%' }}
            size="large"
            // placeholder="Поиск по ФИО / ИИН / Номер тел."
            placeholder="Поиск по ИИН"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={handleSearch}
          />
        </div>

        <div className="table">
          <Table
            size="small"
            showSorterTooltip={true}
            columns={clientsColumns}
            dataSource={clientsArray}
            rowKey={(record) => record?.id || ''}
            pagination={{ position: ['bottomLeft'], showSizeChanger: true }}
            scroll={{
              x: 'max-content',
              y: getFullPageTableHeight()
            }}
            loading={isLoading}
            onRow={(data) => {
              return {
                onClick: () => {
                  handleShowClientDetails(data);
                }
              };
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Clients;
