import { useEffect, useState } from 'react';
import { Button, Input, Select, Spin, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import '@/styles/AutoCred.scss';
import { useAppDispatch, useAppSelector } from '@/store';
import { useRequestsChangeNavigation } from '@/hooks/useRequestsChangeNavigation';
import { TDataSource } from '@/types/dataSource';
import { cleanNameValue, cleanPhoneNumber } from '@/services/formatter';
import { apiConnector } from '@/integrations/api.connector';
import { getFullPageTableHeight } from '@/services';
import { columns } from '@/data/autoCred.data';
import { AutoCredHeader } from '@/components/App/AutoCred/AutocredHeader';
import { setPageTitle } from '@/store/newOrder.slice';
import { onSetOtpRequestToInitial } from '@/store/orderStep.slice';
import { autoCredMapper } from '@/services/mappers/autoCredMapper';

const { Search } = Input;
const { Option } = Select;

export const AutoCred = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();

  const { banksOffers, reqSuccessIndicator } = useAppSelector(
    (state) => state.offerStep
  );
  const { selectedBank } = useAppSelector((state) => state.financingStep);
  const { userInfo, dealer: userDealer } = useAppSelector(
    (state) => state.userInfo
  );

  const { verifyModalState } = useAppSelector((state) => state.app);

  const { handleRequestDetails, changeData, isRequestLoading } =
    useRequestsChangeNavigation();

  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  // Изначяальное значение фильтра поиска по типу оплаты
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState<string[]>(
    []
  );
  const [selectedDealers, setSelectedDealers] = useState<string[] | '-1'>(
    userDealer?.value ? [userDealer.value] : '-1'
  );
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const [filteredArray, setFilteredArray] = useState<TDataSource[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const [totalRequests, setTotalRequests] = useState<number>(0);
  const [paginationData, setPaginationData] = useState<{
    currentPage: number;
    itemsPerPage: number;
  }>({
    currentPage: 1,
    itemsPerPage: 10
  });
  const [isTableDataLoading, setIsTableLoading] = useState<boolean>(false);

  const getRequestsPerPage = async () => {
    if (selectedDealers === '-1') return;
    if (!selectedUser) return;
    setIsTableLoading(true);
    try {
      const formattedNameSearchValue = cleanNameValue(searchValue);
      const formattedPhoneSearchValue = cleanPhoneNumber(searchValue);
      let searchType: 'fio' | 'iinTelNum' | 'empty' = 'empty';
      if (formattedNameSearchValue === '' && formattedPhoneSearchValue === '') {
        searchType = 'empty';
      } else if (
        formattedNameSearchValue !== '' &&
        formattedPhoneSearchValue === ''
      ) {
        searchType = 'fio';
      } else {
        searchType = 'iinTelNum';
      }

      const data = await apiConnector.getRequestsForPagination({
        city_uuid: selectedCities.length > 0 ? selectedCities : '-1',
        dealer_uuid: selectedDealers.length > 0 ? selectedDealers : '-1',
        status_uuid: selectedStatuses.length > 0 ? selectedStatuses : '-1',
        repayment_type_uuid:
          selectedPaymentTypes.length > 0 ? selectedPaymentTypes : '-1',
        user_uuid: selectedUser !== '-1' ? [selectedUser] : '-1',
        // selectedApplications.length > 0 ? selectedApplications : '-1',
        search_query: {
          type: searchType === 'empty' ? 'iinTelNum' : searchType,
          value:
            searchType === 'iinTelNum' ? formattedPhoneSearchValue : searchValue
        },
        pagination: {
          page_number: paginationData.currentPage,
          rows_per_page: paginationData.itemsPerPage
        }
      });
      const dataSource: TDataSource[] = autoCredMapper(data.data);
      setFilteredArray(dataSource);
      setTotalRequests(data.count);
    } catch (e: any) {
      throw new Error(e);
    } finally {
      setIsTableLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setPageTitle(''));
    dispatch(onSetOtpRequestToInitial());
    const paginationInfoStorage = localStorage.getItem('paginationInfo');
    if (paginationInfoStorage) {
      const parsedPaginationInfo = JSON.parse(paginationInfoStorage);
      setPaginationData(parsedPaginationInfo);
    }
  }, []);

  useEffect(() => {
    if (userDealer?.value) {
      setSelectedDealers([userDealer?.value?.toString() || '']);
    }
  }, [userDealer]);

  useEffect(() => {
    // if (userInfo?.uuid) {
    //   setSelectedUser(userInfo?.uuid);
    // } else {
    //
    // }
    const userSearchStorage = localStorage.getItem('userSearchType');
    if (userSearchStorage) {
      setSelectedUser(userSearchStorage);
    } else {
      userInfo?.uuid ? setSelectedUser(userInfo?.uuid) : setSelectedUser('-1');
    }
  }, [userInfo]);

  useEffect(() => {
    if (selectedUser) {
      localStorage.setItem('userSearchType', selectedUser);
    }
    localStorage.setItem('paginationInfo', JSON.stringify(paginationData));
    getRequestsPerPage();
  }, [
    paginationData,
    selectedUser,
    // selectedApplications,
    selectedCities,
    selectedDealers,
    selectedStatuses,
    selectedPaymentTypes
  ]);

  useEffect(() => {
    if (
      reqSuccessIndicator === true &&
      !location.pathname.includes('/auto-credit/')
    ) {
      changeData();
    }
  }, [banksOffers, selectedBank]);

  useEffect(() => {
    if (
      !verifyModalState.showVerifyModal.prevClient &&
      !verifyModalState.showVerifyModal.smsVerf
    ) {
      getRequestsPerPage();
    }
  }, [verifyModalState.showVerifyModal]);

  // useEffect(() => {}, [paginationData]);

  return (
    <>
      {isRequestLoading ? (
        <Spin fullscreen={true} />
      ) : (
        <div className="AutoCred">
          <AutoCredHeader />
          <div className="table_editor">
            <Search
              style={{ flex: 1 }}
              size="large"
              placeholder="Поиск по ФИО / ИИН / Номер тел."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onSearch={getRequestsPerPage}
            />
            <Select
              className="table_editor__select"
              showSearch
              optionFilterProp={'label'}
              size="large"
              placeholder="Все заявки"
              value={selectedUser}
              onChange={(optionValue: string) => {
                setSelectedUser(optionValue);
              }}
              options={
                [
                  { value: userInfo?.uuid, label: 'Мои заявки' },
                  { value: '-1', label: 'Все заявки' }
                ] || []
              }
              maxTagCount={'responsive'}
            />
          </div>
          <div className="table">
            <Table
              rowClassName="auto-cred-table-row"
              size="small"
              showSorterTooltip={true}
              columns={columns}
              dataSource={filteredArray}
              rowKey={(record) => record?.id || ''}
              pagination={{
                position: ['bottomLeft'],
                showSizeChanger: true,
                total: totalRequests,
                pageSize: paginationData.itemsPerPage,
                // @ts-ignore
                selectComponentClass: ({
                  value,
                  onChange
                }: {
                  value: string | number;
                  onChange: (value: string | number) => void;
                }) => {
                  const currentAmount = paginationData.currentPage * +value;
                  const minAmount = currentAmount - +value + 1;
                  const maxAmount =
                    currentAmount > totalRequests
                      ? totalRequests
                      : currentAmount;
                  return (
                    <>
                      <span className="table__shown-text">
                        Показано {minAmount} - {maxAmount} из {totalRequests}{' '}
                        заявок
                      </span>
                      <Select size="small" value={value} onChange={onChange}>
                        <Option value="10">Показать 10</Option>
                        <Option value="20">Показать 20</Option>
                        <Option value="50">Показать 50</Option>
                      </Select>
                    </>
                  );
                },
                current: paginationData.currentPage,
                onChange: (step: number, itemsPerPage: number) => {
                  setPaginationData({
                    currentPage: step,
                    itemsPerPage: itemsPerPage
                  });
                }
              }}
              scroll={{
                x: 'max-content',
                y: getFullPageTableHeight()
              }}
              loading={isTableDataLoading}
              onRow={(r) => {
                return {
                  onClick: () => {
                    // console.log('request from table', r);
                    handleRequestDetails(r);
                  }
                };
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
