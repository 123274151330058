import { ColumnsType } from 'antd/es/table';

import { TopDCTableInterface } from './TopFCTable';
import { formatPrice } from '@/services/formatter';

export const topFCColumns: ColumnsType<TopDCTableInterface> = [
  {
    title: 'ФИО ФК',
    dataIndex: 'name',
    key: 'name',
    width: 250,
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (text, record) => {
      return (
        <h2 style={{ fontSize: '12px', fontWeight: '600', color: '#111827' }}>
          {record?.name || ''}
        </h2>
      );
    }
  },
  {
    title: 'Заявки',
    dataIndex: 'totalRequests',
    key: 'totalRequests',
    width: 130,
    sorter: (a, b) => Number(a.totalRequests) - Number(b.totalRequests),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {formatPrice(record?.totalRequests) || ''}
          </p>
        </div>
      );
    }
  },
  {
    title: 'Стоимость авто',
    dataIndex: 'carPrice',
    key: 'carPrice',
    width: 100,
    sorter: (a, b) => Number(a.carPrice) - Number(b.carPrice),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {formatPrice(record?.carPrice) || ''}
          </p>
        </div>
      );
    }
  },
  {
    title: 'Сумма финансирования',
    dataIndex: 'fundingSum',
    key: 'fundingSum',
    width: 100,
    sorter: (a, b) => Number(a.fundingSum) - Number(b.fundingSum),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {formatPrice(record?.fundingSum) || ''}
          </p>
        </div>
      );
    }
  }
];
