import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from '@mui/material/utils';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

type ValueType = string | number | boolean;

interface FiltersDefinition {
  key: string;
  defaultValue: ValueType;
}

function useFilters<ReturnedFilters extends { [key: string]: ValueType }>(
  filterDefinitions: FiltersDefinition[]
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const reduceFilters: (acc: any, def: FiltersDefinition) => ReturnedFilters =
    useCallback(
      (acc, def) => {
        const { key, defaultValue } = def;
        const valueInUrl = searchParams.get(key);

        acc[key] = valueInUrl
          ? parseFilterValue(typeof defaultValue, valueInUrl as string)
          : defaultValue;

        return acc;
      },
      [searchParams]
    );

  // Инициализация фильтров с применением значений из URL
  const initialFilters: ReturnedFilters = filterDefinitions.reduce(
    reduceFilters,
    {} as ReturnedFilters
  );

  const [filters, setFilters] = useState<ReturnedFilters>(initialFilters);
  const [debouncedFilters, setDebouncedFilters] =
    useState<ReturnedFilters>(initialFilters);

  useEffect(() => {
    const updatedFilters = filterDefinitions.reduce(
      reduceFilters,
      {} as ReturnedFilters
    );
    setFilters(updatedFilters);
    setDebouncedFilters(updatedFilters);
  }, [searchParams]);

  const debouncedFiltersChange = useCallback(
    debounce((args: Partial<ReturnedFilters>, newFilters: ReturnedFilters) => {
      // Обновление состояния фильтров и URL
      setDebouncedFilters(newFilters);
      setSearchParams(newFilters as URLSearchParamsInit);
    }, 300),
    []
  );

  const handleFiltersChange = (args: Partial<ReturnedFilters>) => {
    const newFilters = { ...filters, ...args };
    setFilters(newFilters);
    debouncedFiltersChange(args, newFilters);
  };

  return {
    filters,
    debouncedFilters,
    handleFiltersChange,
    deleteParam: searchParams.delete
  };
}

export default useFilters;

// функция для преобразования значений фильтров из строки в нужный тип
const parseFilterValue = (type: string, value: string) => {
  switch (type) {
    case 'number':
      return Number(value);
    case 'boolean':
      return value === 'true';
    default:
      return value;
  }
};
