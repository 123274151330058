import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import {
  modalTSItemsInsuramce,
  personalClientInsurance,
  productInfoItemsInsurance
} from '@/data/productInsurance.data';

export type TCustomSelectProps = {
  isFocused: boolean;
  value: string | boolean | Date | null | undefined;
  option: any;
  label: string;
  type: string;
  labelTitle: string;
  isError?: boolean;
  onChangeSelectHandler: (
    val: any,
    label: string,
    type: string,
    code?: string
  ) => void;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  addressType?: string;
  fieldsReadOnly?: boolean;
  keyType?: 'personal' | 'product' | 'modal' | 'autocred' | undefined;
  editable?: boolean;
};

export const CustomSelect = ({
  isFocused,
  value,
  isError,
  option,
  label,
  type,
  labelTitle,
  onChangeSelectHandler,
  setShowModal,
  addressType,
  fieldsReadOnly,
  editable,
  keyType
}: TCustomSelectProps & {
  keyType: 'product' | 'personal' | 'modal' | 'autocred';
}) => {
  const getKey = (keyType: 'product' | 'personal' | 'modal' | 'autocred') => {
    switch (keyType) {
      case 'product':
        return productInfoItemsInsurance.find((item) => item.label === label)
          ?.key;
      case 'personal':
        return personalClientInsurance.find((item) => item.label === label)
          ?.key;
      case 'modal':
        return modalTSItemsInsuramce.find((item) => item.label === label)?.key;
      default:
        return '';
    }
  };
  const [formattedValue, setFormattedValue] = useState<string>('');

  const [formattedAutoCompleteValue, setFormattedAutoCompleteValue] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const handleAutoCompleteChange = (
    event: any,
    newValue: { label: string; value: string } | null
  ) => {
    onChangeSelectHandler(
      newValue ? newValue.value.toString() : '',
      label,
      type,
      addressType
    );
  };

  useEffect(() => {
    if (label === 'markModelYear') {
      setFormattedValue(value as string);
    } else {
      const valueToFind =
        option?.length &&
        option.find(
          (data: { label: string; value: number }) =>
            data.value === Number(value) ||
            Number(data.value) === Number(value) ||
            data.label === value
        );
      setFormattedAutoCompleteValue(valueToFind ? valueToFind : null);
    }
  }, [label, option, value]);

  return label === 'markModelYear' ? (
    <FormControl fullWidth className="customItem">
      <InputLabel id="demo-simple-select-label">
        <p
          style={{
            color: '#687588',
            lineHeight: '160%',
            fontWeight: '400'
          }}
        >
          {labelTitle} <span style={{ color: '#E03137' }}> *</span>
        </p>
      </InputLabel>
      <Select
        disabled={editable ? editable : false}
        error={isError !== undefined ? isError : false}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={formattedValue}
        autoComplete={'fuck-off'}
        label={labelTitle}
        inputProps={{ readOnly: true }}
        onClick={() => (setShowModal ? setShowModal(true) : () => {})}
        sx={{ borderRadius: '6px' }}
        readOnly={fieldsReadOnly !== undefined ? fieldsReadOnly : false}
      >
        <MenuItem value={formattedValue || ''}>{formattedValue}</MenuItem>
      </Select>
    </FormControl>
  ) : (
    <FormControl fullWidth className="customItem">
      {/*<InputLabel id="demo-simple-select-label"><p style={{color: "#687588", lineHeight: "160%", fontWeight: "400", fontSize: "12px"}}>{labelTitle} <span style={{color: "#E03137"}}> *</span></p></InputLabel>*/}
      {/*<Select*/}
      {/*    labelId="demo-simple-select-label"*/}
      {/*    id="demo-simple-select"*/}
      {/*    value={formattedValue || ""}*/}
      {/*    label={labelTitle}*/}
      {/*    onChange={(e) => onChangeSelectHandler(e.target.value, label, type, addressType)}*/}
      {/*    onOpen={() => setShowModal ? setShowModal(true) : () => {}}*/}
      {/*    readOnly={fieldsReadOnly !== undefined ? fieldsReadOnly : false}*/}
      {/*    MenuProps={{*/}
      {/*        style: { maxHeight: 400, width: "auto" }, // настройте ширину по своему усмотрению*/}
      {/*      }}*/}
      {/*>*/}
      {/*    {*/}
      {/*        option ? option.map((item: any) => (<MenuItem value={item.value}>{item.label}</MenuItem>)) : */}
      {/*        <MenuItem value="">*/}
      {/*            <em>Пусто</em>*/}
      {/*        </MenuItem>*/}
      {/*    }*/}
      {/*</Select>*/}
      <Autocomplete
        sx={{
          width: '100%'
        }}
        disabled={editable ? editable : false}
        openOnFocus
        value={formattedAutoCompleteValue}
        readOnly={fieldsReadOnly !== undefined ? fieldsReadOnly : false}
        id="combo-box-demo"
        options={
          option && option.length
            ? option.map((item: any) => ({
                label: item.label,
                value: item.value
              }))
            : []
        }
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={handleAutoCompleteChange}
        renderInput={(params) => (
          <TextField
            autoComplete={'off'}
            // onClick={removeEventListener("input", inputListener, true)}
            name={new Date().toLocaleString() + `${Math.random()}`}
            {...params}
            label={
              <p
                style={{
                  color: '#687588',
                  lineHeight: '160%',
                  fontWeight: '400'
                }}
              >
                {labelTitle} <span style={{ color: '#E03137' }}> *</span>
              </p>
            }
          />
        )}
      />
    </FormControl>
  );
};
