import '@/styles/FileDownload.scss';
import React from 'react';
import { pathFileText, pathRedDownload } from '@/constants';
import { saveAs } from 'file-saver';
import { downloadFilesApi } from '@/integrations/index.api';

export type TFileDownloadProps = {
  prevStateTitle: string;
  fileLink: string;
  oldDownloadMethod?: boolean;
  canDownload?: boolean;
};

export const FileDownload = ({
  prevStateTitle,
  fileLink,
  oldDownloadMethod,
  canDownload = true
}: TFileDownloadProps) => {
  const bufferToBase64 = (buffer: any) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const saveBase64AsPDF = (base64String: string, fileName: string) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
    saveAs(pdfBlob, fileName);
  };

  const handleDownloadClick = async () => {
    try {
      if (oldDownloadMethod === true) {
        const link = document.createElement('a');
        link.href = fileLink;
        link.download = prevStateTitle;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        const response = await downloadFilesApi.downloadBankFile(fileLink);
        saveBase64AsPDF(response.base64, prevStateTitle);
      }
    } catch (error: any) {
      const { message } = error;
      if (message.includes('Try this: ')) {
        const fileLink = message.split('Try this: ')[1];
        if (fileLink) {
          fetch(
            fileLink.includes('https')
              ? fileLink.replace('https', 'http')
              : fileLink,
            {
              method: 'GET',
              headers: {
                'Content-Type': '*/*'
              }
            }
          )
            .then((response) => {
              if (response.ok) {
                return response.blob();
              } else {
                throw new Error('Error');
              }
            })
            .then((blob) => {
              saveAs(blob, `${prevStateTitle}.pdf`);
            });
        }
      }
    }
  };

  return (
    <>
      <a
        href={fileLink}
        className="prevState"
        target="_blank"
        style={{ pointerEvents: canDownload ? 'initial' : 'none' }}
        onClick={(event) => event.preventDefault()}
      >
        <div>
          <img src={pathFileText} alt="fileTextIcon" />
          <p>{prevStateTitle}</p>
        </div>
        {canDownload && (
          <button onClick={handleDownloadClick}>
            <img src={pathRedDownload} alt="download" />
          </button>
        )}
      </a>
    </>
  );
};
