import { useState } from 'react';

import { useAppDispatch } from '@/store';
import { tokenValidate } from '@/store/auth.slice';
import {
  setVerifyModalIsNewApplication,
  setVerifyModalShowType
} from '@/store/app.slice';

import Icons from '@/components/Shared/Icons';

import { VerifyModal } from './VerifyModal/VerifyModal';

export const AutoCredHeader = () => {
  const dispatch = useAppDispatch();

  const [verifyModalTitle, setVerifyModalTitle] = useState<string>('');

  return (
    <>
      <VerifyModal verifyModalTitle={verifyModalTitle} />
      <div className="header">
        <div className="title">Автокредитование</div>
        <div className="pagination">
          <button
            onClick={() => {
              dispatch(setVerifyModalIsNewApplication(true));
              dispatch(tokenValidate());
              setVerifyModalTitle('Создание заявки');
              dispatch(
                setVerifyModalShowType({ prevClient: true, smsVerf: false })
              );
            }}
          >
            <p>Новая заявка</p>
            <Icons.PlusIcon />
          </button>
        </div>
      </div>
    </>
  );
};
