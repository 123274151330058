import { Avatar, Badge, Dropdown, MenuProps, Space, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import NotificationsSideBar from '../Notifications/NotificationsSideBar';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  getActiveDealers,
  getOptimizedDictionaries,
  getProductInsurance,
  getProductsDictionary
} from '@/store/dict.slice';
import { useAppDispatch, useAppSelector } from '@/store';
import { decodeJWT } from '@/services/jwtDecoder';
import { onSetSelectedBankToInitial } from '@/store/financingStep.slice';
import { setTargetedClientToInitial } from '@/store/clients.slice';
import { resetReqSuccessIndicator } from '@/store/offerStep.slice';
import { onToggleShowNotifications } from '@/store/notifications.slice';
import { pathBellIcon } from '@/constants';
import { AdminNavBar } from '@/components/AdminPanel/NavBar/AdminNavBar';

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { userInfo, dealer } = useAppSelector((state) => state.userInfo);
  const { lastNotificationsChecked } = useAppSelector(
    (state) => state.notifications
  );

  const [current, setCurrent] = useState<string>('main');
  const [user, setUser] = useState<{
    lastName: string;
    firstName: string;
    actions: number[];
  }>({
    firstName: '',
    lastName: '',
    actions: []
  });

  const items: MenuProps['items'] = [
    {
      key: 'personal',
      label: <p>Профиль</p>
    },
    ...(user.actions.includes(0)
      ? [
          {
            key: 'adminPage',
            label: (
              <p>
                {/*<a style={{ color: 'rgba(0, 0, 0, 0.88)' }} href="https://google.com">*/}
                Панель администратора
                {/*</a>*/}
              </p>
            )
          }
        ]
      : []),
    {
      key: 'logout',
      label: <p>Выйти</p>
    }
  ];

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const data = decodeJWT(accessToken || '');
      setUser((prevState) => ({
        ...prevState,
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        actions: data?.actions || []
      }));
      dispatch(getOptimizedDictionaries());
      dispatch(getProductsDictionary());
      dispatch(getActiveDealers());
      dispatch(getProductInsurance());
    }
  }, []);

  useEffect(() => {
    let key = location.pathname.replace('/', '');
    if (key === '') key = 'main';
    if (key !== current) setCurrent(key);
  }, [location]);

  const onClick = (key: string) => {
    dispatch(onSetSelectedBankToInitial());
    dispatch(setTargetedClientToInitial());
    dispatch(resetReqSuccessIndicator());
    setCurrent(key === 'main' ? '' : key);
    navigate(`/${key === 'main' ? '' : key}`, { replace: true });
  };

  const onClickItems: MenuProps['onClick'] = ({ key }) => {
    // Значение вымышленное! Главное, чтобы не совпадало с основными пунктами меню
    setCurrent('non-in-menu');

    if (key === 'logout') {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userSearchType');
      localStorage.removeItem('isRemember');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('emailAllur');
      localStorage.removeItem('paginationInfo');
      localStorage.removeItem('dealer');
      localStorage.removeItem('isAuthenticated');
      window.location.reload();
      navigate('/login');
    } else {
      navigate(`/${key}`);
    }
  };

  return (
    <header>
      <div className="layout-header__container">
        <div className="title">TechDrive</div>

        <nav className="main-menu">
          {userInfo?.frontendBlock
            .slice()
            .filter((item) => item.is_active)
            .sort((a, b) => a.position - b.position)
            .map((e) => {
              const isActive =
                (e.code === 'main' && current === '') ||
                current === e.code ||
                current.includes(e.code);

              return (
                <a
                  className={`main-menu__link ${
                    isActive ? 'main-menu__link--active' : ''
                  }`}
                  onClick={(event) => {
                    event.preventDefault();
                    onClick(e.code);
                  }}
                  href={e.code}
                  key={e.uuid}
                >
                  {e?.name}
                </a>
              );
            })}
        </nav>

        <div className="pagination">
          <div className="lang">RU</div>
          <Badge dot={!lastNotificationsChecked} offset={[-10, 4]}>
            <button
              className="bell_btn"
              onClick={() => dispatch(onToggleShowNotifications())}
            >
              <img src={pathBellIcon} alt="bell_icon" />
            </button>
          </Badge>
          <Dropdown
            menu={{ items, onClick: onClickItems }}
            placement="bottomRight"
            arrow
          >
            <Space style={{ cursor: 'pointer' }}>
              <Avatar style={{ backgroundColor: 'black' }}>
                {user.firstName[0]?.toUpperCase()}
                {user.lastName[0]?.toUpperCase()}
              </Avatar>{' '}
              <p className="user_name">
                {user.firstName} {user.lastName} <br />
                <span className="user_name__dealer">{dealer?.label}</span>
              </p>
              <DownOutlined />
            </Space>
          </Dropdown>
        </div>
        <NotificationsSideBar />
      </div>
    </header>
  );
};
