import { AxiosInstance } from 'axios';

export class DownloadFilesApi {
  constructor(private readonly api: AxiosInstance) {}
  public async downloadBankFile(uuid: string) {
    const res = await this.api.get(`files/${uuid}/downloadmanually`);
    return res.data;
  }

  public async downloadTemplate(data: {
    template: string;
    type: string;
    values: string;
  }) {
    console.log(data);
    const res = await this.api.post('utils/xlsx_template_generate', data);
    return res.data;
  }

  public async downloadAdditionalFiles(data: any[]) {
    const res = await this.api.post(
      'responses/files/downloadAdditionalFiles',
      data
    );
    return res.data;
  }

  public async pnutUebankManually(responseId: string) {
    const res = await this.api.get(
      `responses/files/${responseId}/downloadAdditionalFilesEubankMalually`
    );
    return res.data;
  }
}
