import Column from 'antd/es/table/Column';
import React, { useEffect, useState } from 'react';
import { Button, Menu, Table } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { CreateOrEditModelModal } from '../Modals/CreateOrEditModelModal';
import { useAppDispatch, useAppSelector } from '@/store';
import { TModel } from '@/types/model.type';
import SearchInput from '@/components/Shared/Input/SearchInput';
import { setShowModalCreateOrEditModel } from '@/store/admin/admin.slice';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';
import { getModels } from '@/store/admin/adminModels.slice';

const Models = () => {
  const dispatch = useAppDispatch();
  const { showModalCreateOrEditModel } = useAppSelector((state) => state.admin);
  const { dicts } = useAppSelector((state) => state.dict);
  const [modelForEdit, setModelForEdit] = useState<TModel | null>(null);
  const [menuBrandFilter, setMenuBrandFilter] = useState<
    { value: string; label: string }[]
  >([]);
  const { models, isLoading, total } = useAppSelector(
    (state) => state.adminModels
  );

  const [paginationData, setPaginationData] = useState<{
    limit: number;
    page: number;
  }>({
    limit: 10,
    page: 1
  });
  const [param, setParam] = useState<string>('');
  const [brandFilterParam, setBrandFilterParam] = useState<string>('');

  useEffect(() => {
    dispatch(
      getModels({
        limit: paginationData.limit,
        page: paginationData.page,
        brandUuid: brandFilterParam,
        param: param
      })
    );
  }, [paginationData, brandFilterParam, param]);

  useEffect(() => {
    const brands = findDictByTitle(DictTypes.Brands, dicts).content.map(
      (b) => ({
        value: b.uuid,
        label: b.name
      })
    );
    // console.log(brands);
    brands.unshift({ value: '', label: 'Все' });
    if (brands) setMenuBrandFilter(brands);
  }, []);

  const handleStatusFilterChange = (value: string | null) => {
    setBrandFilterParam(value ? value.toString() : '');
    setPaginationData((prevState) => ({
      ...prevState,
      page: 1
    }));
  };

  return (
    <>
      <div style={{ display: 'flex', margin: '20px 0' }}>
        <SearchInput
          style={{ width: '50%', marginRight: '10px' }}
          onSearch={(value) => {
            setParam(value.trim());
            setPaginationData((prevState) => ({
              ...prevState,
              page: 1
            }));
          }}
        />
        {showModalCreateOrEditModel && (
          <CreateOrEditModelModal
            param={param}
            brand={brandFilterParam}
            pagination={paginationData}
            setShow={() => dispatch(setShowModalCreateOrEditModel(false))}
            editMode={!!modelForEdit}
            modelItem={modelForEdit}
          />
        )}

        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setModelForEdit(null);
            dispatch(setShowModalCreateOrEditModel(true));
          }}
        >
          Добавить
        </Button>
      </div>
      <Table
        rowClassName="admin-dealers-table-row"
        key="table"
        rowKey="uuid"
        dataSource={models}
        size={'small'}
        onRow={(record: TModel) => ({
          onClick: () => {
            setModelForEdit(record);
            dispatch(setShowModalCreateOrEditModel(true));
          }
        })}
        loading={isLoading}
        pagination={{
          style: { width: '500px', justifyContent: 'flex-start' },
          total: total,
          pageSize: paginationData.limit,
          current: paginationData.page,
          onChange: (step: number, itemsPerPage: number) => {
            setPaginationData({
              page: step,
              limit: itemsPerPage
            });
          }
        }}
      >
        {/*<Column key="uuid" title="ID" dataIndex="uuid" />*/}
        <Column key="name" title="Наименование" dataIndex="name" />
        <Column key="code" title="Код" dataIndex="code" />
        <Column
          key="brand_uuid"
          title="Бренд"
          dataIndex="brand_uuid"
          render={(_: TModel, record: TModel) =>
            record.brand ? (
              record.brand.name
            ) : (
              <p style={{ color: 'red', fontWeight: 'bold' }}>No data</p>
            )
          }
          filterDropdown={() => (
            <div style={{ padding: 8 }}>
              <Menu
                onClick={({ key }) => {
                  handleStatusFilterChange(key === 'all' ? null : key);
                }}
              >
                {menuBrandFilter.map((item) => (
                  <Menu.Item key={item.value}>{item.label}</Menu.Item>
                ))}
                {/*<Menu.Item key="all">Все</Menu.Item>*/}
                {/*<Menu.Item key="true">Активно</Menu.Item>*/}
                {/*<Menu.Item key="false">Не активно</Menu.Item>*/}
              </Menu>
            </div>
          )}
          // filterIcon={(filtered) => <Button>Фильтр</Button>}
          filterMultiple={false}
        />
        <Column
          key="is_commercial"
          title="Коммерческое"
          dataIndex="is_commerciial"
          render={(_, record: TModel) =>
            record.is_commercial ? (
              <p style={{ color: 'green' }}>Да</p>
            ) : (
              <p style={{ color: 'red' }}>-</p>
            )
          }
        />
        {/*<Column*/}
        {/*  key="actions"*/}
        {/*  title="Действия"*/}
        {/*  render={(_: TModel, record: TModel) => (*/}
        {/*    <>*/}
        {/*      <Typography.Link key="edit">*/}
        {/*        <EditOutlined*/}
        {/*          onClick={() => {*/}
        {/*            setModelForEdit(record);*/}
        {/*            dispatch(setShowModalCreateOrEditModel(true));*/}
        {/*          }}*/}
        {/*        />*/}
        {/*      </Typography.Link>*/}
        {/*      <Popconfirm*/}
        {/*        key="removeConf"*/}
        {/*        title={record.is_active ? 'Deactivate' : 'Activate'}*/}
        {/*        onConfirm={() => {*/}
        {/*          const { brand, ...updatedRecord } = record; // Создаем новый объект без свойства 'brand'*/}
        {/*          dispatch(*/}
        {/*            updateModel({*/}
        {/*              ...updatedRecord,*/}
        {/*              is_active: !record.is_active*/}
        {/*            })*/}
        {/*          );*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <Tooltip title={record.is_active ? 'Deactivate' : 'Activate'}>*/}
        {/*          <Typography.Link key="remove" style={{ marginLeft: '20px' }}>*/}
        {/*            {record.is_active ? (*/}
        {/*              <CloseCircleOutlined style={{ color: 'red' }} />*/}
        {/*            ) : (*/}
        {/*              <CheckCircleOutlined style={{ color: 'green' }} />*/}
        {/*            )}*/}
        {/*          </Typography.Link>*/}
        {/*        </Tooltip>*/}
        {/*      </Popconfirm>*/}
        {/*    </>*/}
        {/*  )}*/}
        {/*/>*/}
      </Table>
    </>
  );
};
export default Models;
