import './client-info.scss';

import React from 'react';

import { phoneIcon } from '@/constants';
import { TClientDataState } from '@/types/state';
import { useNavigate } from 'react-router-dom';

import { validateNumberInputValue } from '@/services';
import Icons from '@/components/Shared/Icons';

import { INSURANCE_STATUSES } from '../constants';
import { RequestedConditionsInterface } from '../InsuranceOrder/existed-insurance/hooks/useRequestedConditions';
import { Skeleton } from 'antd';

interface IProps {
  clientData: TClientDataState;
  insuranceStatus: string;
  requestedConditions?: RequestedConditionsInterface | null;
  conditionsLoading?: boolean;
}

const ClientInfo: React.FC<IProps> = ({
  clientData,
  insuranceStatus,
  requestedConditions,
  conditionsLoading
}) => {
  const navigate = useNavigate();
  const currentStatus =
    INSURANCE_STATUSES[insuranceStatus as keyof typeof INSURANCE_STATUSES];

  return (
    <div className="client_info">
      <div className="client_info__id-card" onClick={() => navigate(-1)}>
        <button className="client_info__go-back">
          <Icons.BackArrowIcon />
        </button>
        <div>
          {clientData.id && <h2>ID #{clientData.id}</h2>}
          {currentStatus && (
            <p
              style={{
                backgroundColor: currentStatus.color,
                color: currentStatus.textColor
              }}
              className="client-info__status"
            >
              {currentStatus.text}
            </p>
          )}
        </div>
      </div>

      <div className="client_info__card">
        <div
          style={{
            // @ts-ignore
            '--block-color': '#C8BBFF'
          }}
          className="client_info__block-title"
        >
          Клиент
        </div>
        <h2 className={'client_info__normal-text'}>
          {`${clientData.lastName} ${clientData.firstName} ${clientData.middleName}`}
        </h2>
        <p>ИИН: {clientData.iin}</p>
        {clientData.telNum && (
          <p className="client_info__phone">
            {clientData.telNum}
            <a
              href={`tel:${clientData.telNum.replace(/\(|\)|-|\s/g, '')}`}
              className="client_info__icon"
            >
              <img src={phoneIcon} alt="" />
            </a>
          </p>
        )}
      </div>

      {conditionsLoading && (
        <div className="client_info__card">
          <Skeleton.Input
            active
            block
            size="small"
            style={{ marginTop: '10px' }}
          />
          <Skeleton.Input
            active
            block
            size="small"
            style={{ marginTop: '16px', marginBottom: '6px' }}
          />
          <Skeleton.Input active block size="large" />
          <Skeleton.Input active block size="large" />
          <Skeleton.Input active block size="large" />
          <Skeleton.Input active block size="large" />
        </div>
      )}

      {requestedConditions && (
        <div className="client_info__card">
          <div
            style={{
              // @ts-ignore
              '--block-color': '#FFACA6'
            }}
            className="client_info__block-title"
          >
            Запрошенные условия
          </div>
          <h2>
            {requestedConditions.vehicleTitle},{' '}
            {requestedConditions.vehicleYear}
            <span className="client_info__icon">
              <Icons.CarIcon />
            </span>
          </h2>
          <p className="client_info__values">
            <b>Гос. номер:</b>
            <span>{requestedConditions.vehicleNumber}</span>
          </p>
          <p className="client_info__values">
            <b>Стоимость авто: </b>
            <span>
              {validateNumberInputValue(
                String(requestedConditions.vehiclePrice),
                9,
                true
              )}{' '}
              ₸
            </span>
          </p>
          <p className="client_info__values">
            <b>Способ оплаты:</b>
            <span>{requestedConditions.paymentMethod}</span>
          </p>
          <p className="client_info__values">
            <b>Тип оплаты:</b>
            <span>{requestedConditions.paymentType}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default ClientInfo;
