import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiConnector } from '@/integrations/api.connector';
import { TOfferStepStore } from '@/types/store';
import { bankResponsesMapper } from '@/services/mappers/bankResponsesMapper';
import { formatBackBankOfferResponse } from '@/services/formatter/formatBackBankOfferResponse';
import { TBankOffers } from '@/types/offers';

export const getResponses = createAsyncThunk(
  'offerStep/getResponses',
  async (): Promise<any> => {
    try {
      return await apiConnector.getResponses();
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getResponseByRequestId = createAsyncThunk(
  'offerStep/getResponseByRequestId',
  async (reqId: string): Promise<any> => {
    try {
      const data = await apiConnector.getResponseByRequestId(reqId);
      const halykOffer = data.find(
        (response: any) => response.bank.name === 'halyk'
      );
      if (
        halykOffer &&
        (halykOffer.status.name === 'Одобрено' ||
          halykOffer.status.name === 'Профинансировано' ||
          halykOffer.status.name === 'Ожидание финансирования')
      ) {
        const responseApprovedData = halykOffer.response_approved_body
          ? JSON.parse(halykOffer.response_approved_body)
          : null;
        if (responseApprovedData) {
          const halykOfferindex = data.findIndex(
            (response: any) => response.bank?.name === 'halyk'
          );

          if (halykOfferindex !== -1) {
            const responseApprovedData = JSON.parse(
              halykOffer?.response_approved_body || '{}'
            );

            const loanAmount =
              responseApprovedData?.additional?.[0]?.loan_amount || '';
            const downPayment =
              Number(halykOffer?.vehicle_cost || 0) -
              Number(responseApprovedData?.additional?.[0]?.down_payment || 0);
            const monthlyPayment =
              responseApprovedData?.additional?.[0]?.monthly_payment || '';
            const rate =
              responseApprovedData?.additional?.[0]?.interest_rate || '';

            data[halykOfferindex] = {
              ...data[halykOfferindex],
              loan_amount: loanAmount,
              down_payment: downPayment,
              monthly_payment: monthlyPayment,
              rate: rate,
              request: {
                ...(data[halykOfferindex]?.request || {})
              }
            };
          }
        }
      }
      const eblankOffer = data.find(
        (response: any) => response.bank.name === 'eu_bank'
      );
      if (
        (eblankOffer && eblankOffer?.status?.name === 'Одобрено') ||
        eblankOffer?.status?.name === 'Ожидается загрузка Ареста Авто' ||
        eblankOffer?.status?.name === 'Доработка по аресту' ||
        eblankOffer?.status?.name === 'Ожидание загрузки файлов' ||
        eblankOffer?.status?.name === 'Ожидание финансирования'
      ) {
        const responseApprovedData = JSON.parse(
          eblankOffer.response_approved_body
        );
        const eblankOfferIndex = data.findIndex(
          (response: any) => response.bank.name === 'eu_bank'
        );
        data[eblankOfferIndex] = {
          ...data[eblankOfferIndex],
          monthly_payment:
            responseApprovedData?.calculation?.monthlyPayment || '',

          rate: responseApprovedData?.calculation?.yearlyRate || '',
          request: {
            ...(data[eblankOfferIndex]?.request || '')
          }
        };
      }
      const shinHanOffer = data.find(
        (response: any) => response.bank.name === 'shinhan'
      );

      if (
        (shinHanOffer && shinHanOffer?.status?.name === 'Одобрено') ||
        shinHanOffer?.status?.name === 'Ожидание финансирования' ||
        shinHanOffer?.status?.name === 'Работа в Мобильном ПО'
      ) {
        const shihanOfferIndex = data.findIndex(
          (response: any) => response.bank.name === 'shinhan'
        );
        const responseApprovedData = JSON.parse(
          shinHanOffer.response_approved_body
        );
        // console.log(responseApprovedData);
        data[shihanOfferIndex] = {
          ...data[shihanOfferIndex],
          rate: responseApprovedData?.interest_rate || '',
          // loan_amount: responseApprovedData?.loan_amount || '',
          monthly_payment: responseApprovedData?.monthly_payment || ''
          // down_payment: responseApprovedData?.downpayment || '',
          // loan_period: responseApprovedData?.duration || ''
        };
      }
      return data;
    } catch (e: any) {
      console.log(e);
      throw new Error(e);
    }
  }
);

export const getEblankStatus = createAsyncThunk(
  'offerStep/getEblankStatus',
  async (data: { requestId: string; responseExtUuid: string }) => {
    try {
      return await apiConnector.getEblankStatus(data);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getForteStatus = createAsyncThunk(
  'offerStep/getForteStatus',
  async (extUuid: string) => {
    try {
      return await apiConnector.getForteStatus(extUuid);
      // console.log(res);
      // return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const changeOfferStatus = createAsyncThunk(
  'offerStep/changeOfferStatus',
  async (data: {
    res_id: string;
    is_offered: boolean;
    offered_uuid: string | null;
    status:
      | 'approved'
      | 'declined'
      | 'waiting_bank_response'
      | 'error'
      | 'financed'
      | 'mobile_client'
      | 'cancelled'
      | 'waiting_of_financing'
      | 'accepted_alternative'
      | 'waiting_for_upload_files';
  }): Promise<any> => {
    try {
      return await apiConnector.changeOfferStatus(data);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

const initialState: TOfferStepStore = {
  banksOffers: [],
  offer: null,
  isLoading: false,
  reqSuccessIndicator: null,
  offerUuid: null
};

export const offerStepSlice = createSlice({
  name: 'offerStep',
  initialState,
  reducers: {
    addNewBankOffer: (state, action: PayloadAction<any>) => {
      const formattedResponse = formatBackBankOfferResponse(action.payload);
      const offerToChangeIndex = state.banksOffers.findIndex(
        (offer) => offer.id === formattedResponse.id
      );
      if (offerToChangeIndex !== -1) {
        state.banksOffers[offerToChangeIndex] = formattedResponse;
      } else {
        state.banksOffers = state.banksOffers.concat(formattedResponse);
      }
    },

    setBankOffers: (state, action: PayloadAction<TBankOffers[]>) => {
      state.banksOffers = action.payload;
    },
    setOfferUuid: (state, action: PayloadAction<string>) => {
      state.offerUuid = action.payload;
    },
    resetOfferUuid: (state) => {
      state.offerUuid = null;
    },
    resetReqSuccessIndicator: (state) => {
      state.reqSuccessIndicator = null;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getResponses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResponses.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getResponses.fulfilled, (state) => {
        state.isLoading = false;
      })

      .addCase(getResponseByRequestId.rejected, (state) => {
        state.reqSuccessIndicator = true;
      })
      .addCase(getResponseByRequestId.fulfilled, (state, action) => {
        state.banksOffers = bankResponsesMapper(action.payload);
        state.reqSuccessIndicator = true;
      })

      .addCase(changeOfferStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeOfferStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(changeOfferStatus.fulfilled, (state) => {
        state.isLoading = false;
      })

      .addCase(getEblankStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEblankStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getEblankStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        const bankOffersHandler = [...state.banksOffers];
        const eblankOfferIndex = bankOffersHandler.findIndex(
          (bank) => bank.bank === 'eu_bank'
        );
        if (
          action?.payload?.status?.name === 'Одобрено' ||
          action?.payload?.status?.name === 'Ожидание загрузки файлов' ||
          action?.payload?.status?.name === 'Доработка по аресту' ||
          action?.payload?.status?.name === 'Ожидается загрузка Ареста Авто'
        ) {
          const responseApprovedData = JSON.parse(
            action.payload.response_approved_body
          );
          action.payload = {
            ...action.payload,
            monthly_payment:
              responseApprovedData?.calculation?.monthlyPayment || '',
            rate: responseApprovedData?.calculation?.eir || ''
          };
        }
        const mappedEblankOffer = formatBackBankOfferResponse(action.payload);
        bankOffersHandler[eblankOfferIndex] = { ...mappedEblankOffer };
        state.banksOffers = bankOffersHandler;
      })
      .addCase(getForteStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getForteStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getForteStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        const bankOffersHandler = [...state.banksOffers];
        const forteOfferIndex = bankOffersHandler.findIndex(
          (bank) => bank.bank === 'forte'
        );
        const mappedForteOffer = formatBackBankOfferResponse(action.payload);
        bankOffersHandler[forteOfferIndex] = { ...mappedForteOffer };
        state.banksOffers = bankOffersHandler;
      });
  }
});

export const {
  addNewBankOffer,
  setBankOffers,
  resetReqSuccessIndicator,
  setOfferUuid,
  resetOfferUuid
} = offerStepSlice.actions;
