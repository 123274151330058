import { useNavigate } from 'react-router-dom';
import './bread-crumbs.scss';

import React from 'react';

interface IProps {
  links: Array<{
    href: string;
    text: string;
  }>;
  current: string;
}

const BreadCrumbs: React.FC<IProps> = ({ links, current }) => {
  const navigate = useNavigate();

  return (
    <div className="bread-crumbs">
      {links.map((item) => (
        <div key={item.href} className="bread-crumbs__link-wrapper">
          <a
            className="bread-crumbs__link"
            href={item.href}
            onClick={(e) => {
              e.preventDefault();
              navigate(item.href);
            }}
          >
            {item.text}
          </a>
          <span>/</span>
        </div>
      ))}
      <span className="bread-crumbs__current">{current}</span>
    </div>
  );
};

export default BreadCrumbs;
