import './insurance-second-step.scss';

import Icons from '@/components/Shared/Icons';

import React from 'react';
import Offer from './offer/offer';
import { getFormattedBankName } from '@/services';
import {
  pathEurasLogo,
  pathFreedomlogo,
  pathHalykLogo,
  pathKmfLogo
} from '@/constants';

// interface IProps {}

const InsuranceSecondStep = () => {
  return (
    <div className="insurance-second-step">
      <div className="insurance-second-step__header">
        <h2 className="insurance-second-step__title">
          Предложения от Страховых компаний
        </h2>
        <button className="insurance-second-step__filters">
          <Icons.FiltersIcon />
        </button>
      </div>

      <div className="insurance-second-step__offers">
        <div className="insurance-second-step__offer">
          <Offer
            bank={{
              image: pathEurasLogo,
              name: getFormattedBankName('eu_bank')
            }}
            premium={120004}
            period={'12 месяцев'}
            reward={120004}
            error={false}
            waiting={false}
            onAccept={() => null}
            acceptLoading={false}
          />
        </div>
        <div className="insurance-second-step__offer">
          <Offer
            bank={{
              image: pathHalykLogo,
              name: getFormattedBankName('eu_bank')
            }}
            premium={113004}
            period={'12 месяцев'}
            error={false}
            waiting={false}
            onAccept={() => null}
            acceptLoading={false}
          />
        </div>
        <div className="insurance-second-step__offer">
          <Offer
            bank={{
              image: pathKmfLogo,
              name: getFormattedBankName('eu_bank')
            }}
            premium={163004}
            period={'12 месяцев'}
            error={false}
            waiting={false}
            onAccept={() => null}
            acceptLoading={false}
          />
        </div>
        <div className="insurance-second-step__offer">
          <Offer
            bank={{
              image: pathFreedomlogo,
              name: getFormattedBankName('eu_bank')
            }}
            premium={193004}
            period={'12 месяцев'}
            error={false}
            waiting={false}
            onAccept={() => null}
            acceptLoading={false}
          />
        </div>
      </div>
    </div>
  );
};

export default InsuranceSecondStep;
