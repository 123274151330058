export const FiltersIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.27944 16.5929C3.27944 16.1787 3.61523 15.8429 4.02944 15.8429H10.3302C10.7444 15.8429 11.0802 16.1787 11.0802 16.5929C11.0802 17.0071 10.7444 17.3429 10.3302 17.3429H4.02944C3.61523 17.3429 3.27944 17.0071 3.27944 16.5929Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3905 6.90038C12.3905 6.48616 12.7263 6.15038 13.1405 6.15038H19.4412C19.8554 6.15038 20.1912 6.48616 20.1912 6.90038C20.1912 7.31459 19.8554 7.65038 19.4412 7.65038H13.1405C12.7263 7.65038 12.3905 7.31459 12.3905 6.90038Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 6.84625C3.25 5.13132 4.64903 3.75 6.36314 3.75C8.07726 3.75 9.47629 5.13132 9.47629 6.84625C9.47629 8.56119 8.07726 9.94251 6.36314 9.94251C4.64903 9.94251 3.25 8.56119 3.25 6.84625ZM6.36314 5.25C5.46729 5.25 4.75 5.96987 4.75 6.84625C4.75 7.72263 5.46729 8.44251 6.36314 8.44251C7.259 8.44251 7.97629 7.72263 7.97629 6.84625C7.97629 5.96987 7.259 5.25 6.36314 5.25Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5237 16.5537C14.5237 14.8386 15.9229 13.4575 17.6377 13.4575C19.3521 13.4575 20.75 14.8391 20.75 16.5537C20.75 18.2683 19.3521 19.65 17.6377 19.65C15.9229 19.65 14.5237 18.2688 14.5237 16.5537ZM17.6377 14.9575C16.7408 14.9575 16.0237 15.6775 16.0237 16.5537C16.0237 17.43 16.7408 18.15 17.6377 18.15C18.5332 18.15 19.25 17.4305 19.25 16.5537C19.25 15.677 18.5332 14.9575 17.6377 14.9575Z"
        fill="#111827"
      />
    </svg>
  );
};

export const BackArrowIcon = () => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70711 0.292893C8.09763 0.683417 8.09763 1.31658 7.70711 1.70711L2.41421 7L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893Z"
        fill="#1F9AFF"
      />
    </svg>
  );
};

export const CarIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49862 13.1251H4.99862C4.65695 13.1251 4.37362 12.8417 4.37362 12.5001C4.37362 12.1584 4.65695 11.8751 4.99862 11.8751H7.49862C7.84028 11.8751 8.12362 12.1584 8.12362 12.5001C8.12362 12.8417 7.84028 13.1251 7.49862 13.1251Z"
        fill="#A0AEC0"
      />
      <path
        d="M12.4986 13.1251H14.9986C15.3403 13.1251 15.6236 12.8417 15.6236 12.5001C15.6236 12.1584 15.3403 11.8751 14.9986 11.8751H12.4986C12.1569 11.8751 11.8736 12.1584 11.8736 12.5001C11.8736 12.8417 12.1569 13.1251 12.4986 13.1251Z"
        fill="#A0AEC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.74111 8.94921L3.08795 7.29174H2.49862C2.15695 7.29174 1.87362 7.00841 1.87362 6.66674C1.87362 6.32508 2.15695 6.04174 2.49862 6.04174H3.33195L3.34948 6.04199L3.66516 4.5334C3.9735 3.07507 4.5985 1.7334 7.0735 1.7334H12.9318C15.4068 1.7334 16.0318 3.0834 16.3402 4.5334L16.6552 6.04182L16.6653 6.04174H17.4986C17.8403 6.04174 18.1236 6.32508 18.1236 6.66674C18.1236 7.00841 17.8403 7.29174 17.4986 7.29174H16.9163L17.2636 8.95436C18.1998 9.48704 18.3921 10.4561 18.482 11.3834L18.9487 16.4584C19.007 17.0917 18.7987 17.7251 18.3654 18.2001C17.9237 18.6834 17.2904 18.9584 16.632 18.9584H15.0654C13.7154 18.9584 13.457 18.1834 13.2904 17.6751L13.1237 17.1751L13.1207 17.1663C12.9068 16.541 12.8785 16.4584 12.132 16.4584H7.86535C7.11535 16.4584 7.06535 16.6001 6.87369 17.1751L6.70702 17.6751L6.70331 17.686C6.52845 18.2025 6.27251 18.9584 4.93202 18.9584H3.36535C2.70702 18.9584 2.07369 18.6834 1.63202 18.2001C1.19869 17.7251 0.990355 17.0917 1.04869 16.4584L1.51535 11.3834C1.59993 10.4531 1.79514 9.48079 2.74111 8.94921ZM15.8902 8.54173H4.0985L4.89016 4.79173C5.1235 3.70007 5.3985 2.9834 7.0735 2.9834H12.9235C14.5985 2.9834 14.8735 3.70007 15.1068 4.79173L15.8902 8.54173ZM12.132 15.2084H7.86535C6.21535 15.2084 5.90702 16.1167 5.69869 16.7834L5.53202 17.2917L5.52938 17.2995C5.39034 17.7085 5.38754 17.7167 4.94035 17.7167H3.37369C3.06535 17.7167 2.76535 17.5917 2.56535 17.3667C2.36535 17.1501 2.27369 16.8751 2.29869 16.5834L2.76535 11.5084C2.86535 10.3834 2.99869 9.80008 4.68202 9.80008H15.332C17.007 9.80008 17.1404 10.3834 17.2487 11.5084L17.7154 16.5834C17.7404 16.8751 17.6487 17.1501 17.4487 17.3667C17.2404 17.5917 16.9487 17.7167 16.6404 17.7167H15.0737C14.6264 17.7167 14.6237 17.7085 14.4846 17.2912L14.482 17.2834L14.307 16.7667C14.007 15.8834 13.6487 15.2084 12.132 15.2084Z"
        fill="#A0AEC0"
      />
    </svg>
  );
};

export const InfoFillIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 12.75C8.5875 12.75 8.25 12.4125 8.25 12V9C8.25 8.5875 8.5875 8.25 9 8.25C9.4125 8.25 9.75 8.5875 9.75 9V12C9.75 12.4125 9.4125 12.75 9 12.75ZM9.75 6.75H8.25V5.25H9.75V6.75Z"
        fill="white"
      />
    </svg>
  );
};

export const PlusIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4987 3.33301C10.9589 3.33301 11.332 3.7061 11.332 4.16634V15.833C11.332 16.2932 10.9589 16.6663 10.4987 16.6663C10.0385 16.6663 9.66536 16.2932 9.66536 15.833V4.16634C9.66536 3.7061 10.0385 3.33301 10.4987 3.33301Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83203 9.99967C3.83203 9.53944 4.20513 9.16634 4.66536 9.16634H16.332C16.7923 9.16634 17.1654 9.53944 17.1654 9.99967C17.1654 10.4599 16.7923 10.833 16.332 10.833H4.66536C4.20513 10.833 3.83203 10.4599 3.83203 9.99967Z"
        fill="white"
      />
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 17.5H15H5Z"
        fill="#A0AEC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.5L10 14.1667L10 2.5Z"
        fill="#A0AEC0"
      />
      <path
        d="M5 17.5H15M10 2.5L10 14.1667M10 14.1667L14.1667 10M10 14.1667L5.83333 10"
        stroke="#A0AEC0"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ReloadIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3346 10.0003C18.3346 14.6003 14.6013 18.3337 10.0013 18.3337C5.4013 18.3337 2.59297 13.7003 2.59297 13.7003M2.59297 13.7003H6.35964M2.59297 13.7003V17.867M1.66797 10.0003C1.66797 5.40033 5.36797 1.66699 10.0013 1.66699C15.5596 1.66699 18.3346 6.30033 18.3346 6.30033M18.3346 6.30033V2.13366M18.3346 6.30033H14.6346"
        stroke="#A0AEC0"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CloseCircleIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
        fill="#111827"
      />
    </svg>
  );
};
