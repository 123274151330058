import './insurance-third-step.scss';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from 'antd';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { FileDownload } from '@/components/Shared/Input/FileDownload';
import Icons from '@/components/Shared/Icons';

import { apiConnector } from '@/integrations/api.connector';
import { getFormattedBankName } from '@/services';
import { pathHalykLogo } from '@/constants';
import { TFile } from '@/types/request';

import { WaitingAlert } from './ui';
import { useAppSelector } from '@/store';

interface IProps {
  hello?: string;
}

const InsuranceThirdStep: React.FC<IProps> = ({}) => {
  const params = useParams();
  const { requestId } = useAppSelector((state) => state.orderInsurance);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [waitingPayment, setWaitingPayment] = useState(true);

  const modalCancelHandler = () => {
    console.log('Clicked');
  };

  const [files, setFiles] = useState<TFile[]>([]);

  const [filesDownloaded, setFilesDownloaded] = useState(false); // Добавляем переменную состояния
  const [loadingFiles, setLoadingFiles] = useState(false); // Новое состояние для загрузки файлов

  const getAttachedFilesById = async (insuranceId: string) => {
    setLoadingFiles(true); // Устанавливаем состояние загрузки файлов в true
    try {
      const attachedFiles: TFile[] = await apiConnector.findByInsuranceId(
        insuranceId
      );
      const uniqueFilesArray = attachedFiles.filter(
        (record, index, self) =>
          index === self.findIndex((file) => record.name === file.name)
      );
      setFiles(uniqueFilesArray);
      setFilesDownloaded(true); // Обновляем состояние только после успешной загрузки файлов
    } catch (error) {
      console.error('Ошибка при загрузке файлов:', error);
    } finally {
      setLoadingFiles(false); // Устанавливаем состояние загрузки файлов в false
    }
  };

  useEffect(() => {
    if (params.uuid) {
      getAttachedFilesById(params.uuid);
      // SocketConnector.insuranceFilesUpdated = async (data) => {
      //   if (data.payload.insurance_RequestsUuid !== requestId) return;
      //   await getAttachedFilesById(data.payload.insurance_RequestsUuid);
      // };
    }
  }, [params]);

  const policy = files.find((item) => item.name.includes('policy'));
  const application = files.find((item) => item.name.includes('application'));

  return (
    <>
      <div className="insurance-third-step">
        <div className="insurance-third-step__content">
          <div className="insurance-third-step__header">
            <h2 className="insurance-third-step__title">Документы</h2>
            <div className="insurance-third-step__actions">
              {files.length > 0 && (
                <Button
                  className="insurance-third-step__button"
                  icon={<Icons.DownloadIcon />}
                  onClick={() => {
                    files.forEach((file) => {
                      window.open(file.link, '_blank');
                    });
                  }}
                >
                  <span className="insurance-third-step__button-text">
                    Скачать все документы
                  </span>
                </Button>
              )}
              <Button
                className="insurance-third-step__button"
                icon={<Icons.ReloadIcon />}
                onClick={() => getAttachedFilesById(requestId)}
              >
                <span className="insurance-third-step__button-text">
                  Получить документы
                </span>
              </Button>
            </div>
          </div>

          <div className="insurance-third-step__files">
            <FileDownload
              canDownload={!!policy}
              prevStateTitle={policy ? policy.name : 'Полис'}
              fileLink={policy ? policy.link : ''}
            />
            <FileDownload
              canDownload={!!application}
              prevStateTitle={application ? application.name : 'Анкета'}
              fileLink={application ? application.link : ''}
            />
          </div>
        </div>

        <div className="insurance-third-step__bank-info">
          <div className="insurance-third-step__bank">
            <div className="insurance-third-step__img">
              <img src={pathHalykLogo} alt="" />
            </div>

            <div>
              <p className="insurance-third-step__label">Выбрана страховая</p>
              <p className="insurance-third-step__name">
                {getFormattedBankName('halyk')}
              </p>
            </div>
          </div>

          <div className="insurance-third-step__footer">
            {files.length > 0 ? (
              <div className="insurance-third-step__granted">Полис выдан</div>
            ) : (
              <Button
                onClick={() => setShowCancelModal(true)}
                className="insurance-third-step__cancel"
              >
                Отказ клиента
              </Button>
            )}
          </div>
        </div>
      </div>

      {files && files.length === 0 && !loadingFiles && (
        <WaitingAlert
          text="Ожидает оплаты страховой премии"
          isOpened={waitingPayment}
          onClose={setWaitingPayment}
        />
      )}

      <CustomModal
        editClass="cancel"
        title="Вы точно хотите отменить предложение?"
        isModalOpen={showCancelModal}
        handleOk={modalCancelHandler}
        handleCancel={() => setShowCancelModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowCancelModal(false)}>
            Нет
          </Button>,
          <Button
            className="cancel_btn"
            key="submit"
            type="primary"
            onClick={modalCancelHandler}
          >
            Да, отменить
          </Button>
        ]}
      />
    </>
  );
};

export default InsuranceThirdStep;
