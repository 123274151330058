import './offer.scss';

import React from 'react';

import { formatPrice } from '@/services/formatter';

import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface IProps {
  bank: {
    image: string;
    name: string;
  };
  premium?: number;
  period?: string;
  reward?: number;
  error: boolean;
  waiting: boolean;
  onAccept: () => void;
  acceptLoading?: boolean;
}

const Offer: React.FC<IProps> = ({
  bank,
  premium,
  period,
  reward,
  error,
  waiting,
  onAccept,
  acceptLoading
}) => {
  return (
    <div
      style={{
        borderColor: waiting ? '#FFD023' : error ? '#FF2E1F' : ''
      }}
      className="offer"
    >
      <div className="offer__header">
        <div className="offer__img">
          <img src={bank.image} />
        </div>
        <p className="offer__title">{bank.name}</p>
      </div>

      <div className="offer__info">
        <div className="offer__info-item">
          <p className="offer__info-label">Страховая премия</p>
          <b className="offer__info-value">
            {premium ? `${formatPrice(premium)} ₸` : '-'}{' '}
          </b>
        </div>
        <div className="offer__info-item">
          <p className="offer__info-label">Период:</p>
          <b className="offer__info-value">{period || '-'}</b>
        </div>
        {reward && (
          <div className="offer__info-item">
            <p className="offer__info-label">Вознограждение:</p>
            <b className="offer__info-value">{formatPrice(reward)}</b>
          </div>
        )}
      </div>

      <div className="offer__actions">
        {waiting ? (
          <Spin
            style={{ color: '#FFD023' }}
            indicator={<LoadingOutlined spin />}
            size="large"
          />
        ) : (
          !error && (
            <Button
              loading={acceptLoading}
              onClick={onAccept}
              className="offer__accept"
              type="primary"
            >
              Принять предложение
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default Offer;
