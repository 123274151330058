import { useState } from 'react';

import './styles.scss';

interface IProps {
  value: string;
  label: string;
  required: boolean;
  children: React.ReactElement;
}

const FloatInput = ({ value, label, required, children }: IProps) => {
  const [focus, setFocus] = useState(false);

  const isOccupied = focus || value !== '';

  const labelClass = isOccupied
    ? 'float-label__label float-label__as-label'
    : 'float-label__label float-label__as-placeholder';

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <label className={labelClass}>
        {label} {required && <span style={{ color: '#E03137' }}>*</span>}
      </label>
      {children}
    </div>
  );
};

export default FloatInput;
