import './bank-waiting-item.scss';

import React from 'react';

import { getFormattedBankName } from '@/services';
import { TBankOffers } from '@/types/offers';
import { Skeleton } from 'antd';

interface IProps {
  bankOffer: TBankOffers;
}

const NewBankWaitingItem: React.FC<IProps> = ({ bankOffer }) => {
  return (
    <>
      <div className="bank-waiting-item">
        <div className="bank-waiting-item__header">
          <div className="bank-waiting-item__img">
            <img src={bankOffer.icon} alt="" />
          </div>
          <div>
            <p className="bank-waiting-item__header-title">
              {getFormattedBankName(bankOffer.name)}
            </p>
            <div className="bank-waiting-item__status-wrapper">
              <div className="bank-waiting-item__status">
                {bankOffer.initialStatus?.name || 'На рассмотрении'}
              </div>
            </div>
          </div>
        </div>

        <div className="bank-waiting-item__info">
          <div className="bank-waiting-item__info-item">
            <p className="bank-waiting-item__info-label">Ежемесячный платеж:</p>
            <Skeleton paragraph={false} active />
          </div>
          <div className="bank-waiting-item__info-item">
            <p className="bank-waiting-item__info-label">Процентная ставка:</p>
            <Skeleton paragraph={false} active />
          </div>
          <div className="bank-waiting-item__info-item">
            <p className="bank-waiting-item__info-label">Срок:</p>
            <Skeleton paragraph={false} active />
          </div>
          <div className="bank-waiting-item__info-item">
            <p className="bank-waiting-item__info-label">Сумма займа:</p>
            <Skeleton paragraph={false} active />
          </div>
          <div className="bank-waiting-item__info-item">
            <p className="bank-waiting-item__info-label">
              Первоначальный взнос:
            </p>
            <Skeleton paragraph={false} active />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewBankWaitingItem;
