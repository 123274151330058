import '@/styles/ClientRequestsDetails.scss';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { TabsProps } from 'antd';
import { Tabs } from 'antd/lib';
import ClientRequestsData from './ClientRequestsData';
import { useLocation, useNavigate } from 'react-router-dom';
import ClientCalculationsData from './ClientCalculationsData';
import { useAppDispatch, useAppSelector } from '@/store';
import { useRequestsChangeNavigation } from '@/hooks/useRequestsChangeNavigation';
import { findDictByTitle, generateRandomSixDiginCode } from '@/services';
import { cleanPhoneNumber } from '@/services/formatter';
import {
  requestCreateDraft,
  requestCreateUnverifiedDraft,
  validateOTP
} from '@/store/orderStep.slice';
import {
  setClientInfo,
  setCurrent,
  setPageTitle
} from '@/store/newOrder.slice';
import { DictTypes } from '@/constants';
import { VerifyOTP } from '@/components/Shared/Modal/VerifyOTP/VerifyOTP';
import {
  setShowVerifyFirstStep,
  setShowVerifySecondStep
} from '@/store/app.slice';

export const ClientRequestsDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { dealer } = useAppSelector((state) => state.userInfo);
  const { handleRequestDetails, changeData } = useRequestsChangeNavigation();

  const { targetedClient } = useAppSelector((state) => state.clients);
  const { dicts } = useAppSelector((state) => state.dict);
  const { requestId } = useAppSelector((state) => state.orderStep);
  const { banksOffers, reqSuccessIndicator } = useAppSelector(
    (state) => state.offerStep
  );

  const [termDict, setTermDict] = useState<
    {
      code: number;
      name: string;
      uuid: string;
      brand_uuid?: string | undefined;
      model_uuid?: string | undefined;
      is_active?: boolean | undefined;
      promo_body?: string | undefined;
    }[]
  >([]);

  const [brandDict, setBrandDict] = useState<
    {
      code: number;
      name: string;
      uuid: string;
      brand_uuid?: string | undefined;
      model_uuid?: string | undefined;
      is_active?: boolean | undefined;
      promo_body?: string | undefined;
    }[]
  >([]);

  const handleCreateNewReq = async () => {
    dispatch(setShowVerifyFirstStep(true));
  };

  useEffect(() => {
    dispatch(setPageTitle('Клиенты'));
    const termDict = findDictByTitle(DictTypes.LoanTerms, dicts)?.content;
    const brandDicts = findDictByTitle(DictTypes.Brands, dicts)?.content;
    if (termDict) setTermDict(termDict);
    if (brandDicts) setBrandDict(brandDicts);
  }, []);

  useEffect(() => {
    if (
      reqSuccessIndicator === true &&
      !location.pathname.includes('/auto-credit/')
    ) {
      changeData();
    }
  }, [banksOffers]);

  const tabsItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Заявки',
      children: (
        <ClientRequestsData
          handleCreateNewReq={handleCreateNewReq}
          requests={targetedClient?.clientRequests}
          termDicts={termDict}
          handleRequestInfo={handleRequestDetails}
        />
      )
    },
    {
      key: '2',
      label: 'Расчеты',
      children: (
        <ClientCalculationsData
          calculations={targetedClient?.clientCalculations || []}
          brandDicts={brandDict}
        />
      )
    }
  ];

  return (
    <div className="ClientRequestsDetails">
      <VerifyOTP
        fioInputShow={true}
        isLoading={false}
        sendClient={(value) =>
          dispatch(
            requestCreateUnverifiedDraft({
              draftDto: value,
              dicts: dicts || []
            })
          ).then(() => {
            dispatch(setShowVerifyFirstStep(false));
            dispatch(setShowVerifySecondStep(true));
          })
        }
        sendVerifyOtp={(value) => {
          dispatch(
            validateOTP({
              uuid: requestId,
              otp: value,
              dicts: dicts || []
            })
          ).then(() => {
            dispatch(setShowVerifySecondStep(false));
            if (dicts) {
              const obj = {
                iin: targetedClient?.clientIin || '',
                fullName: [
                  targetedClient?.clientLastName || '',
                  targetedClient?.clientFirstName || '',
                  targetedClient?.clientMiddleName || ''
                ],
                telNum: targetedClient?.clientPhoneNum || '',
                id: generateRandomSixDiginCode()
              };
              dispatch(setClientInfo(obj));
              dispatch(setCurrent(0));
              navigate('/auto-credit/create');
            }
          });
        }}
        clientInfo={{
          clientPhone: targetedClient?.clientPhoneNum || '',
          firstName: targetedClient?.clientFirstName || '',
          iin: targetedClient?.clientIin || '',
          lastName: targetedClient?.clientLastName || '',
          middleName: targetedClient?.clientMiddleName || '',
          dealer_uuid: dealer?.value || ''
        }}
      />
      <div className="client_info_wrapper">
        <div>
          <h2>
            <span>
              {targetedClient?.clientLastName || ''}{' '}
              {targetedClient?.clientFirstName || ''}{' '}
              {targetedClient?.clientMiddleName || ''}
            </span>
          </h2>
          <p>ИИН: {targetedClient?.clientIin || ''}</p>
        </div>
        <div className="client_info">
          <h2>{targetedClient?.clientPhoneNum || ''}</h2>
          <p>Номер телефона</p>
        </div>
        <div className="client_info">
          <h2>
            {dayjs(targetedClient?.clientBirthDate)?.format('DD.MM.YYYY') || ''}{' '}
            г.
          </h2>
          <p>
            {new Date()?.getFullYear() -
              dayjs(targetedClient?.clientBirthDate).year()}{' '}
            года
          </p>
        </div>
        <div className="client_info">
          <h2>{targetedClient?.clientSex || ''}</h2>
          <p>Пол</p>
        </div>
      </div>

      <div className="content">
        <Tabs defaultActiveKey="1" items={tabsItems} />
      </div>
    </div>
  );
};
