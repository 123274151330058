import { TFile, TRequest } from '@/types/request';
import { TDict } from '@/types/store';
import { DictTypes } from '@/constants';
import { TNewOrder } from '@/types/newOrder';
import { formatPhoneNumber } from '@/services/formatter';
import { TPersonSpouseInfo } from '@/types/personInfo';
import { findDictByTitle, validateNumberInputValue } from '@/services';

export const orderAppMapper = (
  request: TRequest,
  dicts: TDict[]
): TNewOrder => {
  const residentStatusDict = findDictByTitle(DictTypes.ResidentStatuses, dicts);
  const statusesDict = findDictByTitle(DictTypes.Statuses, dicts);
  const clientPhotoHandler = request.Files.find(
    (file) => file.type === 'clientPhoto'
  );
  const clientPhoto: TFile = {
    link: clientPhotoHandler?.link?.replace('https', 'http') || '',
    create_date: clientPhotoHandler?.create_date || '',
    name: clientPhotoHandler?.name || '',
    request_uuid: clientPhotoHandler?.request_uuid || '',
    type: clientPhotoHandler?.type || '',
    uuid: clientPhotoHandler?.uuid || ''
  };
  const docScanSocialStatus = request.Files.find(
    (file) => file.type === 'docScanSocialStatus'
  );
  const docPhoto = request.Files.find((file) => file.type === 'docPhoto');
  const proofOfIncome = request.Files.find(
    (file) => file.type === 'incomeCert'
  );
  const noticeActivity = request.Files.find(
    (file) => file.type === 'noticeActivity'
  );
  const otherDoc = request.Files.find((file) => file.type === 'otherDoc'); //Справка за 6 месяцев
  const stockbondsArrayHandler = request.RequestsProducts.filter(
    (reqProd) => reqProd.product.product_type.code !== 2
  );
  const addProductsArrayHandler = request.RequestsProducts.filter(
    (reqProd) => reqProd.product.product_type.code === 2
  );
  const orderState: TNewOrder = {
    uuid: request.uuid,
    biometric_validated: request?.biometric_session?.is_validated || false,
    createDate: request.create_date,
    status:
      statusesDict?.content
        .find((status) => status.uuid === request.status_uuid)
        ?.code?.toString() || '',
    initialStatus: request?.status,
    otp: request.otp,
    code: request.code,
    otpDate: request.otp_create_date,
    productInfo: {
      product: {
        // autoColor: request?.color?.code || '2', //Черный цвет
        autoType: request?.auto_type?.code || '1', //Типа автомобиля
        costCar:
          validateNumberInputValue(
            request?.vehicle_cost?.toString(),
            10,
            true
          ) || '',
        requestAmount:
          validateNumberInputValue(request?.request_amount, 10, true) || '',
        dealer: request?.dealer?.code || '',
        engineType: request?.engine_type?.code || '1', //Бензиновый
        initFee:
          validateNumberInputValue(request?.init_fee?.toString(), 10, true) ||
          '',
        loanTerm: request?.loan_period_code || request?.loan_period || '',
        // paymentDate: request?.payment_date || '',
        paymentScheme: request?.repayment_type?.code || '1', //Код ануетета
        // proofInCome: request.is_proof_income,
        proofInCome: true,
        carYear:
          request?.car_year?.toString() || new Date().getFullYear().toString(),
        stocksBonds: stockbondsArrayHandler.map((reqProd) => {
          return {
            id: reqProd?.uuid || '',
            value: reqProd?.product?.bank?.name || '',
            label: reqProd?.product?.name || '',
            checked: true,
            info: reqProd?.product?.promo_body || '',
            code: reqProd?.product?.code || '',
            bank_name: reqProd?.product?.bank?.name || '',
            loan_period: reqProd?.product?.loan_period || null,
            loan_amount: reqProd?.product?.loan_amount || null,
            product_code: reqProd?.product?.product_code || null,
            partner_code: reqProd?.product?.partner_code || null,
            grace: reqProd?.product?.grace_period
              ? Number(reqProd?.product?.grace_period)
              : null
          };
        }),
        markModelYear: {
          brand: Number(request?.model?.brand?.code) || '',
          model: Number(request?.model?.code) || '',
          year: request?.car_year || new Date().getFullYear()
        }
      },
      additionalProducts: addProductsArrayHandler.map((reqProd) => {
        return {
          id: reqProd?.uuid || '',
          value: reqProd?.product?.bank?.name || '',
          label: reqProd?.product?.name || '',
          checked: true,
          info: reqProd?.product?.promo_body || '',
          code: reqProd?.product?.code || '',
          bank_name: reqProd?.product?.bank?.name || '',
          loan_period: reqProd?.product?.loan_period || null,
          loan_amount: reqProd?.product?.loan_amount || null,
          product_code: reqProd?.product?.product_code || null,
          partner_code: reqProd?.product?.partner_code || null,
          grace: reqProd?.product?.grace_period
            ? Number(reqProd?.product?.grace_period)
            : null
        };
      })
    },
    personalInfo: {
      addContactsTelNum:
        request?.client?.Client_Add_Contacts[0]?.phone_number || '',
      addContactsFullName:
        request?.client?.Client_Add_Contacts[0]?.full_name || '',
      addContactsIsRelated:
        request?.client?.Client_Add_Contacts[0]?.add_contacts_is_related?.code?.toString() ||
        '',
      salary: `${request?.client?.salary || ''}`,
      children: `${request?.client?.child_count || '0'}`,
      workTelNumber: formatPhoneNumber(request?.client?.work_tel_number || ''),
      socialStatus: request?.client.social_status?.code?.toString() || '0',
      jobTitle: request?.client?.job_title || '',
      companyName: request?.client?.title_company || '',
      additionalSalary: `${request?.client?.additional_income || ''}`,
      companyType: request?.client?.organization_type?.code?.toString() || '',
      clientIin: request?.client?.client_iin || '',
      dateBirth: new Date(request?.client?.birth_date || '').toString(),
      docType: request?.client?.document_type?.code || '',
      firstName: request?.client?.first_name || '',
      middleName: request?.client?.middle_name || '',
      lastName: request?.client?.last_name || '',
      familyStatus: request?.client?.marital_status?.code || '',
      phoneNumber: formatPhoneNumber(request?.user?.phone_number || ''),
      jobAddress: request?.client?.work_address || '',
      sex: request?.client?.gender?.code || '',
      spouseInfo:
        request?.client?.Spouses && request?.client?.Spouses?.length !== 0
          ? ({
              isMarried: true,
              spouseAddSalary:
                request?.client?.Spouses[0]?.additional_income || '',
              spouseCompanyName: request?.client?.Spouses[0]?.workplace || '',
              spouseDateBirth:
                request?.client?.Spouses[0]?.birth_date.toString() || '',
              spouseFullName: request?.client?.Spouses[0]?.full_name || '',
              spouseIin: request?.client?.Spouses[0]?.iin || '',
              spousePlaceBirth: request?.client?.Spouses[0]?.birthplace || '',
              spouseResidentStatus: residentStatusDict.content.find(
                (status) =>
                  status.uuid ===
                  // @ts-ignore
                  request?.client?.Spouses[0]?.resident_status_uuid
              )?.code,
              spouseSalary: request?.client?.Spouses[0]?.salary || '',
              spouseTelNum: request?.client?.Spouses[0]?.mobile_phone || ''
            } as TPersonSpouseInfo)
          : {
              isMarried: false,
              spouseAddSalary: '',
              spouseCompanyName: '',
              spouseDateBirth: null,
              spouseFullName: '',
              spouseIin: '',
              spousePlaceBirth: '',
              spouseResidentStatus: '',
              spouseSalary: '',
              spouseTelNum: ''
            }
    },
    additionalInfo: {
      files: {
        clientPhoto: clientPhoto,
        docPhoto: docPhoto ? docPhoto : null,
        docScanSocialStatus: docScanSocialStatus ? docScanSocialStatus : null,
        incomeCert: proofOfIncome ? proofOfIncome : null,
        noticeActivity: noticeActivity ? noticeActivity : null,
        otherDoc: otherDoc ? otherDoc : null
      } // Files
    }
  };
  return orderState;
};
