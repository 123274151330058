import '@/styles/_slices.scss';
import '@/styles/StatusContentBox.scss';
import '@/styles/InsuranceStepOrder.scss';

/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { notification, Spin, Steps } from 'antd';
import BreadCrumbs from '@/components/Shared/BreadCrumbs';

import { useAppDispatch, useAppSelector } from '@/store';
import { formatPhoneNumber } from '@/services/formatter';
import { TClientDataState } from '@/types/state';

import ClientInfo from '../../ClientInfo';
import { INSURANCE_STEPS } from '../../Insuranсe';
import NewInsurance from '../new-insurance';
import { getOneInsuranceReq } from '@/store/orderInsurance.slice';
import InsuranceSecondStep from '../insurance-second-step';
import useRequestedConditions from './hooks/useRequestedConditions';
import InsuranceThirdStep from '../insurance-third-step';
import useFilters from '@/hooks/useFilters';

dayjs.extend(duration);

export const secondStepStatuses = [
  'waiting_bank_response',
  'waiting_of_payment'
];
export const thirdStepStatuses = ['completed', 'financed', 'error'];

export type TOrderInsuranceProps = {
  createRequest: () => void;
  clientInfo: TClientDataState;
};

const ExistedInsurance = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { filters, handleFiltersChange } = useFilters<{ tab: number }>([
    { key: 'tab', defaultValue: 0 }
  ]);
  const currentStep = filters.tab - 1;

  const {
    productInsuranceInfo: storeProductInfo,
    personalInsuranceInfo: storePersonalInfo,
    requestId
  } = useAppSelector((state) => state.orderInsurance);
  const isNotFirstStepStatus =
    secondStepStatuses.includes((storeProductInfo.status as string) || '') ||
    thirdStepStatuses.includes((storeProductInfo.status as string) || '');

  const { requestedConditions } = useRequestedConditions();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (params.uuid && !loading) {
        setLoading(true);
        await dispatch(getOneInsuranceReq(params.uuid));
        setLoading(false);
        // SocketConnector.insuranceFilesUpdated = async (data) => {
        //   if (data.payload.insurance_RequestsUuid !== requestId) return;
        //   await getAttachedFilesById(data.payload.insurance_RequestsUuid);
        // };
      }
    })();
  }, [params.uuid]);

  useEffect(() => {
    if (filters.tab === 0) {
      const status = storeProductInfo.status;
      if (secondStepStatuses.includes((status as string) || ''))
        handleFiltersChange({ tab: 2 });
      else if (thirdStepStatuses.includes((status as string) || ''))
        handleFiltersChange({ tab: 3 });
      else handleFiltersChange({ tab: 1 });
    }
  }, [storeProductInfo.status]);

  const handleStepCurrentChange = (value: number) => {
    if (
      secondStepStatuses.includes((storeProductInfo.status as string) || '') &&
      value === 2
    )
      notification.warning({
        message: 'Выберите предложение',
        description:
          'Чтобы перейти на данный этап, вам необходимо выбрать предложение',
        duration: 5
      });
    else if (storeProductInfo.status === 'draft' && value !== 0)
      notification.warning({
        message: 'заполните форму',
        description:
          'Чтобы перейти на данный этап, вам необходимо создать заявку',
        duration: 5
      });
    else {
      handleFiltersChange({ tab: value + 1 });
    }
  };

  const disableFirstStepButton =
    secondStepStatuses.includes((storeProductInfo.status as string) || '') ||
    thirdStepStatuses.includes((storeProductInfo.status as string) || '');
  const STEPS = [
    <NewInsurance key="new-insurance" isDisabled={disableFirstStepButton} />,
    <InsuranceSecondStep key="insurance-offers" />,
    <InsuranceThirdStep key="insurance-policy" />
  ];

  return (
    <div className="Insurance_Step_Order">
      <div className="Insurance_Step_Order__bread-crumbs">
        <BreadCrumbs
          links={[
            { text: 'Главная', href: '/' },
            { text: 'Страхование', href: '/insurances' }
          ]}
          current="Создание"
        />
      </div>

      <div className="Insurance_Step_Order__grid">
        <div className="Insurance_Step_Order__side-info">
          <ClientInfo
            clientData={{
              firstName: storePersonalInfo?.firstName || '',
              middleName: storePersonalInfo?.middleName || '',
              lastName: storePersonalInfo?.lastName || '',
              iin: storePersonalInfo?.clientIin || '',
              telNum: formatPhoneNumber(storePersonalInfo?.clientPhone || ''),
              id: `...${requestId.slice(-15)}`,
              errorTitle: '',
              comments: ''
            }}
            insuranceStatus={(storeProductInfo.status as string) || ''}
            requestedConditions={requestedConditions}
            conditionsLoading={isNotFirstStepStatus && !requestedConditions}
          />
        </div>

        <div>
          <div className="wrapper">
            <Steps
              className="order_steps"
              current={currentStep}
              items={INSURANCE_STEPS}
              onChange={handleStepCurrentChange}
            />
          </div>

          {loading ? <Spin fullscreen={true} /> : STEPS[currentStep]}
        </div>
      </div>

      {/* <div className="status_content_box">
        <div className="info">
          <div className="status_text">
            {productInsuranceInfo.status === 'waiting_bank_response' ? (
              <p className="request_waiting">Полис ожидает оплаты</p>
            ) : null}
            {productInsuranceInfo.status === 'waiting_of_payment' ? (
              <p className="request_waiting">Полис ожидает оплаты</p>
            ) : null}
            {productInsuranceInfo.status === 'error' ? (
              <p className="request_canceled">Ошибка в процессе</p>
            ) : null}
            {productInsuranceInfo.status === 'completed' ? (
              <p className="request_financed">Полис выдан</p>
            ) : null}
            {productInsuranceInfo.status === 'financed ' ? (
              <p className="request_canceled">Полис выдан</p>
            ) : null}
            {productInsuranceInfo.status === 'SMS верификаця' ? (
              <p className="request_financed">SMS верификация</p>
            ) : null}
            {productInsuranceInfo.status === 'draft' ? (
              <p className="request_draft">Создан</p>
            ) : null}
          </div>
          <div className="info-text">
            <div className="insurance_values" style={{ marginTop: '20px' }}>
              <div>
                Сумма страхования:{' '}
                <p style={{ fontSize: '13px' }}>
                  {' '}
                  {insurance.insuranseSumm ? (
                    <strong>{insurance.insuranseSumm}</strong>
                  ) : (
                    'Нет данных'
                  )}
                </p>
              </div>
            </div>
            <div className="insurance_values" style={{ marginTop: '20px' }}>
              <div>
                Премия страхования:{' '}
                <p style={{ fontSize: '13px' }}>
                  {' '}
                  {insurance.insuransePremium ? (
                    <strong>{insurance.insuransePremium}</strong>
                  ) : (
                    'Нет данных'
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="btn-container">
            {loading ? (
              <div className="loader-container">
                <div className="loader-container">
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                </div>
              </div>
            ) : (
              <Button
                className="load-btn"
                icon={<ReloadOutlined />}
                style={{
                  color: 'white',
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row-reverse',
                  padding: '15px',
                  border: 'none',
                  borderRadius: '8px',
                  height: '40px',
                  textAlign: 'right',
                  fontSize: '13px',
                  fontStyle: 'normal'
                }}
                onClick={() => getAttachedFilesById(requestId)}
              >
                <span style={{ marginRight: '10px' }}>Запросить полис</span>
                <span
                  style={{
                    marginRight: '10px',
                    marginBottom: '5px'
                  }}
                ></span>
              </Button>
            )}
          </div>
          <>
            {files.length > 0 &&
              files.map((file) => (
                <FileDownload
                  key={file.uuid}
                  fileLink={file.uuid}
                  prevStateTitle={
                    file.name.includes('application')
                      ? 'Анкета'
                      : file.name.includes('policy')
                      ? 'Полис'
                      : ''
                  }
                />
              ))}
          </>
        </div>
      </div> */}
    </div>
  );
};

export default ExistedInsurance;
