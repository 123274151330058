import './cell-inner.scss';

import React, { FC } from 'react';

type TCellInnerProps = {
  title: string;
  content: string;
  hasAvatar?: boolean;
};
const CellInner: FC<TCellInnerProps> = ({ title, content, hasAvatar }) => {
  const showAvatar = /\d/.test(title[0]) ? ' ' : title[0]?.toUpperCase();

  return (
    <div className="cell-inner">
      {hasAvatar && <div className="cell-inner__avatar">{showAvatar}</div>}
      <div>
        <h2 style={{ fontSize: '12px', fontWeight: '600', color: '#111827' }}>
          {title}
        </h2>
        <p style={{ fontSize: '12px', fontWeight: '400', color: '#687588' }}>
          {content}
        </p>
      </div>
    </div>
  );
};

export default CellInner;
