import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiConnector } from '../integrations/api.connector';
import { TUserInfoStore } from '../types/store';
import { TUserResponse } from '../types/user';
import { userInfoMapper } from '@/services/mappers/userInfoMapper';

export const getUserAccount = createAsyncThunk(
  'userInfo/getUserAccount',
  async (): Promise<any> => {
    try {
      return await apiConnector.getUserAccount();
    } catch (e) {
      console.error(e);
      // throw new Error(e);
    }
  }
);

const initialState: TUserInfoStore = {
  userInfo: null,
  dealer: null,
  userInfoLoading: false
};

export const userInfoSlice = createSlice({
  name: 'userInfoSlice',
  initialState,
  reducers: {
    setDealer: (
      state,
      action: PayloadAction<{ value: string; label: string; code: string }>
    ) => {
      state.dealer = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getUserAccount.pending, (state) => {
      state.userInfoLoading = true;
    });
    builder.addCase(getUserAccount.rejected, (state) => {
      state.userInfoLoading = false;
    });
    builder.addCase(
      getUserAccount.fulfilled,
      (state, action: PayloadAction<TUserResponse>) => {
        state.userInfo = userInfoMapper(action.payload);
        state.userInfoLoading = false;
      }
    );
  }
});

export const { setDealer } = userInfoSlice.actions;
