import { ColumnsType } from 'antd/es/table';
import RenderBankLogo from '../components/App/AutoCred/UI/render-bank-logo/RenderBankLogo';
import { TDataSource } from '../types/dataSource';
//@ts-ignore
import dayjs from 'dayjs';
//@ts-ignore
import React from 'react';
import CellInner from '../components/App/AutoCred/UI/cell-inner/CellInner';
import { statusColors } from '../constants';

export const columns: ColumnsType<TDataSource> = [
  {
    title: 'Клиент',
    dataIndex: 'client',
    key: 'client',
    width: 210,
    sorter: (a, b) => a.client.fullName.localeCompare(b.client.fullName),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <div
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              background: '#F1F2F4',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <p
              style={{ fontSize: '10px', fontWeight: '600', color: '#687588' }}
            >
              {record?.client?.fullName[0] || ''}
            </p>
          </div>
          <div style={{ marginLeft: '12px' }}>
            <CellInner
              title={record?.client?.fullName || ''}
              content={record?.client?.telNumber || ''}
            />
          </div>
        </div>
      );
    }
  },
  {
    title: 'Залог',
    dataIndex: 'loan',
    key: 'loan',
    width: 140,
    sorter: (a, b) => a.loan.amount.localeCompare(b.loan.amount),
    render: (text, record) => {
      return record.loan?.amount ? (
        <>
          <div>
            <CellInner
              title={`${record?.loan?.amount} ₸` || ''}
              content={record?.loan?.auto || ''}
            />
          </div>
        </>
      ) : (
        <p style={{ color: 'red', fontWeight: 'bold' }}>Нет данных</p>
      );
    }
  },
  {
    title: 'Тип заявки',
    dataIndex: 'orderType',
    key: 'orderType',
    width: 160,
    sorter: (a, b) => a.orderType.autoType.localeCompare(b.orderType.autoType),
    render: (text, record) => {
      return record.status.isDraft ? (
        <p style={{ color: 'red', fontWeight: 'bold' }}>Нет данных</p>
      ) : (
        <>
          <div>
            <CellInner
              title={record?.orderType?.autoType || ''}
              content={record?.dealer.name.replace('(SAP)', '')}
            />
          </div>
        </>
      );
    }
  },
  {
    title: 'Создано',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 160,
    sorter: (a, b) =>
      new Date(a.created_at.date).getTime() -
      new Date(b.created_at.date).getTime(),
    render: (text, record) => {
      return (
        <div>
          <CellInner
            title={dayjs(record.created_at.date).format('DD.MM.YYYY, HH:mm')}
            content={record?.created_at?.author}
          />
        </div>
      );
    }
  },
  {
    title: 'Решение банка',
    key: 'banks_decision',
    dataIndex: 'banks_decision',
    width: 192,
    render: (text, record) => {
      const selected = record.responses.find(
        (item) => item.bank === record.status.selectedBank
      );

      if (selected) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <RenderBankLogo selectedBank={record.status.selectedBank} />
            <div>
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: '600',
                  color: '#111827'
                }}
              >
                {record?.status?.selectedBank === 'eu_bank'
                  ? 'Евразийский Банк'
                  : record?.status?.selectedBank === 'halyk'
                  ? 'Halyk Bank'
                  : record.status.selectedBank === 'bcc'
                  ? 'Банк Центр Кредит'
                  : record.status.selectedBank === 'shinhan'
                  ? 'Shinhan Банк'
                  : record.status.selectedBank === 'ffb'
                  ? 'Freedom Finance Банк'
                  : record.status.selectedBank === 'forte'
                  ? 'Forte Банк'
                  : record.status.selectedBank === 'altyn'
                  ? 'Алтын банк'
                  : ''}
              </p>
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#687588'
                }}
              >
                Банк выбран
              </p>
            </div>
          </div>
        );
      }

      const banks = selected
        ? []
        : record.responses.map((bank) => {
            return {
              icon: bank.bank,
              status: bank.status
            };
          });

      return (
        <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
          {banks.map((item) => {
            return (
              <RenderBankLogo
                key={item.icon}
                selectedBank={item.icon}
                statusColor={statusColors[item.status].bgColor}
                size="small"
              />
            );
          })}
        </div>
      );
    }
  },
  {
    title: 'Статус',
    key: 'status',
    dataIndex: 'status',
    width: 150,
    render: (text, record) => {
      const bgColor =
        statusColors[record.initialStatus.code as keyof typeof statusColors]
          .bgColor;

      return (
        <div
          style={{
            padding: '4px 16px',
            borderRadius: '8px',
            background: bgColor === '#FFD023' ? 'rgb(255, 247, 217)' : bgColor,
            width: 'max-content'
          }}
        >
          <p
            style={{
              textAlign: 'center',
              fontSize: '10px',
              fontWeight: '700',
              color:
                statusColors[
                  record.initialStatus.code as keyof typeof statusColors
                ].textColor
            }}
          >
            {record.initialStatus.name}
          </p>
        </div>
      );
    }
  }
];
