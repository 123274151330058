import { AxiosInstance } from 'axios';
import { TProduct } from '@/types/product.type';

export class ProductsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminProducts(
    limit?: number,
    page?: number,
    param?: string
  ): Promise<{
    total: number;
    products: TProduct[];
  }> {
    let path = `admin/products/getProducts/${param ? param : ''}?`;
    if (limit) {
      path = path + `&limit=${limit}`;
    }
    if (page) {
      path = path + `&page=${page}`;
    }
    const res = await this.api.get(path);
    return res.data;
  }

  public async createAdminProduct(product: TProduct) {
    const res = await this.api.post('admin/products/createProduct', product);
    return res.data;
  }

  public async updateAdminProduct(product: TProduct) {
    const res = await this.api.patch(
      `admin/products/${product.uuid}/updateProduct`,
      product
    );
    return res.data;
  }

  public async createPromoProductModels(data: {
    promo_product_uuid: string;
    brand_uuid: string;
    model_uuid: string;
  }) {
    const res = await this.api.post('admin/promo-product-models/create', data);
    //console.log(res);
    return res.data;
  }
}
