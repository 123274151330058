import { apiConnector } from '@/integrations/api.connector';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TPromoProductModel } from '@/types/promotion.type';
import { productsApi } from '@/integrations/index.api';

type TPromoProductModelSlice = {
  promoProductModel: TPromoProductModel[];
  total: number;
  isLoading: boolean;
};

const initialState: TPromoProductModelSlice = {
  promoProductModel: [],
  total: 0,
  isLoading: false
};

export const getAllPromoProductModel = createAsyncThunk(
  'admin/promoProductModel/getAll',
  async (): Promise<{
    promo_product_models: TPromoProductModel[];
    total: number;
  }> => {
    try {
      const res = await apiConnector.getAdminAllPromoProductModel();
      //console.log(res);
      return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const createPromoProductModel = createAsyncThunk(
  'admin/promoProductModel/create',
  async (data: {
    promo_product_uuid: string;
    brand_uuid: string;
    model_uuid: string;
  }) => {
    return await productsApi.createPromoProductModels(data);
  }
);

// export const createProduct = createAsyncThunk(
//   'products/create',
//   async (data: TProduct, { dispatch }) => {
//     const res = await apiConnector.createProduct(data);
//     dispatch(getAllProducts());
//   }
// );
//
// export const updateProduct = createAsyncThunk(
//   'products/update',
//   async (data: TProduct, { dispatch }) => {
//     await apiConnector.updateProduct(data);
//     dispatch(getAllProducts());
//   }
// );

export const adminPromoProductModelSlice = createSlice({
  name: 'admin/promoProductModel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPromoProductModel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPromoProductModel.fulfilled, (state, action) => {
        state.promoProductModel = action.payload.promo_product_models;
        state.isLoading = false;
        state.total = action.payload.total;
      })
      .addCase(getAllPromoProductModel.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createPromoProductModel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPromoProductModel.fulfilled, (state) => {
        state.isLoading = false;
      });
  }
});

export const {} = adminPromoProductModelSlice.actions;
