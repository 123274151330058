import './styles.scss';

import { FC } from 'react';
import {
  pathAltynLogo,
  pathBckLogo,
  pathBerekeLogo,
  pathEurasLogo,
  pathForteLogo,
  pathFreedomlogo,
  pathHalykLogo,
  pathKmfLogo,
  shinhanPathLogo
} from '@/constants';

type TImgProps = {
  selectedBank: string;
  size?: 'large' | 'small';
  statusColor?: string;
};
const RenderBankLogo: FC<TImgProps> = ({
  selectedBank,
  size = 'large',
  statusColor
}) => {
  const pixels = size === 'large' ? 32 : 24;
  const minusAmount = size === 'large' ? 12 : 8;

  return (
    <div
      className="render-bank-logo"
      style={{
        width: `${pixels}px`,
        height: `${pixels}px`
      }}
    >
      <img
        src={
          selectedBank === 'Freedom Finance'
            ? pathFreedomlogo
            : selectedBank === 'Евразийский Банк' || selectedBank === 'eu_bank'
            ? pathEurasLogo
            : selectedBank === 'KMF'
            ? pathKmfLogo
            : selectedBank === 'Halyk Bank' || selectedBank === 'halyk'
            ? pathHalykLogo
            : selectedBank === 'Банк ЦентрКредит' || selectedBank === 'bcc'
            ? pathBckLogo
            : selectedBank === 'Береке Банк' || selectedBank === 'bereke'
            ? pathBerekeLogo
            : selectedBank === 'Forte Bank'
            ? pathForteLogo
            : selectedBank === 'Shinhan Банк' || selectedBank === 'shinhan'
            ? shinhanPathLogo
            : selectedBank === 'Freedom Finance Bank' || selectedBank === 'ffb'
            ? pathFreedomlogo
            : selectedBank === 'Forte Банк' || selectedBank === 'forte'
            ? pathForteLogo
            : selectedBank === 'altyn' || selectedBank === 'Алтын Банк'
            ? pathAltynLogo
            : ''
        }
        className="render-bank-logo__img"
        alt="bank-logo"
        style={{
          width: `${pixels - minusAmount}px`,
          height: `${pixels - minusAmount}px`
        }}
      />
      {statusColor && (
        <span
          style={{ backgroundColor: statusColor }}
          className="render-bank-logo__status"
        ></span>
      )}
    </div>
  );
};

export default RenderBankLogo;
