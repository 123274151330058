import './main-page.scss';

import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Select } from 'antd';

import ClientMainPage from './ClientMain/ClientMainPage';
import Graphic from './ClientMain/Graphic';
import TopDCTable from './ClientMain/TopFCTable/TopFCTable';
import { topDCTable } from './mock';

const { RangePicker } = DatePicker;

declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null;

const FORMAT = 'D MMM YYYY';

const options = [{ value: 'all', label: <span>Все дилеры</span> }];

const MainPage = () => {
  const [dealer, setDealer] = useState(options[0]);
  const [datesValues, setDatesValues] = useState<{
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }>({
    startDate: dayjs(new Date()).startOf('month'),
    endDate: dayjs(new Date()).startOf('month').add(1, 'month')
  });

  const handleRangePickerChange = (value: RangeValue<Dayjs>) => {
    if (!value) {
      setDatesValues({
        startDate: null,
        endDate: null
      });
      return;
    }

    if (!value[0] || !value[1]) return;

    const startDate = dayjs(value[0]);
    const endDate = dayjs(value[1]);
    setDatesValues({ startDate, endDate });
  };

  return (
    <div className="main-page">
      <div className="main-page__filters-card">
        <h3>Сводная информация по заявкам</h3>

        <div className="main-page__filters">
          <Select
            onChange={setDealer}
            value={dealer}
            options={options}
            className="main-page__filters-item main-page__select"
          />

          <RangePicker
            placeholder={['Начало', 'Конец']}
            value={[datesValues.startDate, datesValues.endDate]}
            format={FORMAT}
            onChange={handleRangePickerChange}
            className="main-page__filters-item main-page__date-filter"
          />
        </div>
      </div>

      <div className="main-page__graphics">
        <Graphic
          title="Заявки"
          data={[
            { name: 'Евразийский банк', value: 1000, color: '#4BA9B8' },
            { name: 'Halyk Bank', value: 2850, color: '#E6BB20' },
            { name: 'Банк Центр Кредит', value: 1650, color: '#DAAB6B' },
            { name: 'Forte Bank', value: 2900, color: '#85B447' },
            { name: 'Bereke Bank', value: 156, color: '#2DD4BF' }
          ]}
        />
        <Graphic
          title="Суммы кредитования"
          data={[
            {
              name: 'Евразийский банк',
              value: 15_000_000_000,
              color: '#4BA9B8'
            },
            { name: 'Halyk Bank', value: 40_770_211_236, color: '#E6BB20' },
            {
              name: 'Банк Центр Кредит',
              value: 7_770_211_236,
              color: '#DAAB6B'
            },
            { name: 'Forte Bank', value: 46_500_211_236, color: '#85B447' },
            { name: 'Bereke Bank', value: 70_211_236, color: '#2DD4BF' }
          ]}
        />
      </div>

      <div className="main-page__table-card">
        <TopDCTable dcDataSource={topDCTable} />
      </div>
    </div>
  );
};

export default MainPage;
