import { TRequest } from '@/types/request';
import { TDataSource, TDataSourceResponses } from '@/types/dataSource';
import { formatPhoneNumber } from '@/services/formatter';
import {
  generateRandomSixDiginCode,
  validateNumberInputValue
} from '@/services';

export const autoCredMapper = (data: TRequest[]): TDataSource[] => {
  if (!Array.isArray(data) || data.length === 0) return [];

  return data.map((request: TRequest) => {
    const requestCode = request.code;
    const clientFullName = `${request?.client?.last_name || ''} ${
      request?.client?.first_name || ''
    } ${request?.client?.middle_name || ''}`;
    const telNumber =
      request?.client?.phone_number?.length === 11
        ? request?.client?.phone_number || ''
        : `7${request?.client?.phone_number || ''}`;
    const amount = request?.vehicle_cost || '';
    const auto = `${request?.model?.brand?.name || ''} ${
      request?.model?.name
    }, ${request?.car_year || ''}`;
    const autoType = request?.auto_type?.name || '';
    const autoDescription = `${request?.model?.brand?.name || ''} ${
      request?.model?.name
    }, ${request?.model?.year || ''} `;
    const date = request?.create_date || '';
    const isUnverfied = request?.status.code === 'sms_verificating';
    const author = request.user
      ? `${request.user.first_name} ${request.user.middle_name} ${request.user.last_name}`
      : '';

    const deniedOffersArray = [...(request.Responses || [])].filter(
      (response) =>
        response?.status?.code === 'declined' ||
        response?.status?.code === 'cancelled'
    );
    const waitingOffersArray = [...(request.Responses || [])].filter(
      (response) =>
        response?.status?.code === 'waiting_bank_response' ||
        response?.status?.code === 'on_rework' ||
        response?.status?.code === 'on_verification'
    );
    const approvedArray = [...(request.Responses || [])].filter(
      (response) =>
        response?.status?.code === 'approved' ||
        response?.status?.code === 'pre_approved'
    );
    const errorOffersArray = [...(request.Responses || [])].filter(
      (response) => response?.status?.code === 'error'
    );

    // console.log(errorOffersArray);

    const waitingFinancingArray = [...request.Responses].filter(
      // (response) => response.status.code === 'waiting_of_financing'
      (response) => response.is_offered
    );
    const financedOffersArray = [...request.Responses].filter(
      (response) => response?.status?.code === 'financed'
    );
    const approvedAlternativesArray = [...request.Responses].filter(
      (response) => response?.status?.code === 'accepted_alternative'
    );

    const isFinanced = financedOffersArray.length > 0;
    const isSelected = waitingFinancingArray.length > 0;
    const selectedBank = isFinanced
      ? financedOffersArray[0]?.bank?.name || ''
      : waitingFinancingArray.length
      ? waitingFinancingArray[0]?.bank?.name || ''
      : '';
    const approved = approvedArray.length > 0 ? approvedArray.length : '0';
    const expectations =
      waitingOffersArray.length > 0 ? waitingOffersArray.length : '0';
    const denied =
      deniedOffersArray.length > 0 ? deniedOffersArray.length : '0';
    const errors = errorOffersArray.length > 0 ? errorOffersArray.length : '0';
    const approvedAlternatives =
      approvedAlternativesArray.length > 0
        ? approvedAlternativesArray.length
        : '0';

    const isRequestInDraft = request.status.code === 'draft';

    const responsesAmount =
      financedOffersArray.length +
      errorOffersArray.length +
      waitingFinancingArray.length +
      waitingOffersArray.length +
      deniedOffersArray.length +
      selectedBank.length +
      approvedArray.length;
    return {
      id: request.uuid,
      responsesAmount: responsesAmount,
      client: {
        fullName: clientFullName,
        telNumber: formatPhoneNumber(telNumber),
        iin: request?.client?.client_iin,
        code: requestCode
      },
      dealer: {
        code: request?.dealer?.code,
        name: request?.dealer?.name || '',
        uuid: request?.dealer?.uuid || '',
        city_uuid: request?.dealer?.city_uuid || ''
      },
      loan: {
        financed_date: financedOffersArray[0]?.funded_date,
        amount: validateNumberInputValue(amount, 10, true),
        auto: auto,
        initialFee:
          validateNumberInputValue(request?.init_fee?.toString(), 10, true) ||
          '',
        loanTerm: request?.loan_period_code,
        dealer: request?.dealer?.name || ''
      },
      orderType: {
        autoType: autoType,
        auto: autoDescription,
        code: requestCode ? requestCode : generateRandomSixDiginCode()
      },
      created_at: {
        date: date,
        author: author,
        author_uuid: request?.user_uuid
      },
      initialStatus: request.status,
      status: {
        isFinanced: isFinanced,
        isUnverified: isUnverfied,
        isSelected: isSelected,
        selectedBank: selectedBank,
        approved: approved,
        expectations: expectations,
        denied: denied,
        isDraft: isRequestInDraft,
        approvedAlternatives: approvedAlternatives,
        errors: errors
      } as {
        isFinanced: boolean;
        isUnverified: boolean;
        isSelected: boolean;
        selectedBank: string;
        approved: string;
        expectations: string;
        denied: string;
        isDraft: boolean;
        approvedAlternatives: string;
        errors: string;
      },
      responses:
        request.Responses.length > 0
          ? request.Responses.map((response) => {
              const responseData: TDataSourceResponses = {
                id: response.uuid,
                createDate: response.create_date,
                status: response?.status?.code,
                bank: response?.bank?.name,
                responseDate: response?.response_date || response.create_date
              };
              return responseData;
            })
          : []
    };
  });
};
