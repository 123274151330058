import Icons from '@/components/Shared/Icons';
import './waiting-alert.scss';

import React from 'react';

interface IProps {
  text: string;
  isOpened: boolean;
  onClose: (value: boolean) => void;
}

const WaitingAlert: React.FC<IProps> = ({ text, isOpened, onClose }) => {
  return (
    <div className={`waiting-alert ${isOpened ? 'waiting-alert--opened' : ''}`}>
      <Icons.InfoFillIcon />
      <p className="waiting-alert__text">{text}</p>
      <button onClick={() => onClose(false)} className="waiting-alert__close">
        <Icons.CloseCircleIcon />
      </button>
    </div>
  );
};

export default WaitingAlert;
