import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TNewOrder, TNewOrderDto } from '../types/newOrder';
import { apiConnector } from '../integrations/api.connector';
import { getResponseByRequestId } from './offerStep.slice';
import { TDict, TNewOrderStore } from '../types/store';
import { orderAppMapper } from '@/services/mappers/orderAppMapper';
import { tsModalInfoMapper } from '@/services/mappers/tsModalInfoMapper';
import { TRequest } from '@/types/request';

export const createNewOrder = createAsyncThunk(
  'newOrder/createNewOrder',
  async (
    reqDto: {
      data: TNewOrderDto;
      dicts: TDict[];
    },
    { dispatch }
  ): Promise<any> => {
    try {
      try {
        localStorage.setItem(reqDto?.data?.uuid, JSON.stringify(reqDto?.data));
      } catch {}
      const response = await apiConnector.postOrder(reqDto.data);
      if (response.id) {
        await dispatch(
          getRequestById({
            reqId: response?.inputs?.req_body.request_id,
            dicts: reqDto.dicts
          })
        );
        await dispatch(
          getResponseByRequestId(
            response?.inputs?.req_body.request_id.toString()
          )
        );
        localStorage.removeItem(reqDto?.data?.uuid);
        return response;
      } else {
        throw new Error();
      }
    } catch (e) {
      // @ts-ignore
      throw new Error(e);
    }
  }
);

export const responseCreate = createAsyncThunk(
  'newOrder/responseCreate',
  async (reqId: string): Promise<any> => {
    try {
      return await apiConnector.responseCreate(reqId);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getRequestById = createAsyncThunk(
  'newOrder/gerRequestById',
  async (dataObject: { reqId: string; dicts: TDict[] }): Promise<any> => {
    try {
      const data = await apiConnector.getRequestById(dataObject.reqId);
      return {
        data: data,
        dicts: dataObject.dicts
      };
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

const initialState: TNewOrderStore = {
  isError: false,
  orderApp: null as TNewOrder | null,
  tsModalValues: {
    requestUid: '',
    licensePlate: '',
    vin: '',
    bodyNum: '',
    paymentDate: '',
    registerCompany: '',
    engineCapacity: '',
    carColor: '',
    registerDate: '',
    registerNum: ''
  },
  pageTitle: '',
  isLoading: false,
  isCreateLoading: false,
  clientInfo: null as {
    iin: string;
    fullName: string[];
    telNum: string;
    id: string;
  } | null,
  requestId: '',
  isNewOrderError: false,
  fieldsEditable: false,
  current: 0,
  loan: {
    amount: '',
    auto: ''
  }
};

export const newOrderSlice = createSlice({
  name: 'newOrder',
  initialState,
  reducers: {
    setOrderApp: (state, action: PayloadAction<TNewOrder>) => {
      state.orderApp = action.payload;
    },
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
    setClientInfo: (state, action) => {
      state = {
        ...state,
        clientInfo: action.payload
      };
      return state;
    },
    setIsNewOrderError: (state, action: PayloadAction<boolean>) => {
      state.isNewOrderError = action.payload;
    },
    setCurrent: (state, action: PayloadAction<number>) => {
      state.current = action.payload;
    },
    setRequestData: (state, action: PayloadAction<string>) => {
      state.requestId = action.payload;
    },
    setLoanData: (
      state,
      action: PayloadAction<{ amount: string; auto: string }>
    ) => {
      state.loan = action.payload;
    },
    setFieldsEditable: (state, action: PayloadAction<boolean>) => {
      state.fieldsEditable = action.payload;
    },
    setNewOrderToInitial: (state) => {
      state.orderApp = null;
    },
    resetIsError: (state) => {
      state.isError = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewOrder.pending, (state) => {
        state.isLoading = true;
        state.isCreateLoading = true;
        state.isError = false;
      })
      .addCase(createNewOrder.rejected, (state) => {
        console.log('rejected');
        state.isLoading = false;
        state.isNewOrderError = false;
        state.isCreateLoading = false;
        state.isError = true;
      })
      .addCase(createNewOrder.fulfilled, (state, action) => {
        console.log('fulfilled');

        state.isLoading = false;
        state.isNewOrderError = false;
        state.isCreateLoading = false;
        state.isError = false;
        state.requestId = action.payload.inputs.req_body.request_id;
      })

      .addCase(getRequestById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRequestById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        getRequestById.fulfilled,
        (state, action: PayloadAction<{ data: TRequest; dicts: TDict[] }>) => {
          state = {
            ...state,
            isLoading: false,
            requestId: action.payload?.data?.uuid,
            orderApp: orderAppMapper(action.payload.data, action.payload.dicts),
            tsModalValues: tsModalInfoMapper(action.payload.data)
          };
          return state;
        }
      );
  }
});

export const {
  setOrderApp,
  setPageTitle,
  setClientInfo,
  setCurrent,
  setRequestData,
  setLoanData,
  setFieldsEditable,
  setNewOrderToInitial,
  resetIsError
} = newOrderSlice.actions;
