import React from 'react';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import {
  modalTSItemsInsuramce,
  personalClientInsurance,
  productInfoItemsInsurance
} from '@/data/productInsurance.data';

export type TCustomDatePickerProps = {
  isFocused: boolean;
  value: dayjs.Dayjs | null;
  label: string;
  type: string;
  labelTitle: string;
  fieldsReadOnly?: boolean;
  keyType?: 'personal' | 'product' | 'modal' | 'autocred' | undefined;
  onChangeDatePickerHandler: (val: any, label: string, type: string) => void;
  editable?: boolean;
};

export const CustomDatePicker = ({
  isFocused,
  value,
  label,
  type,
  labelTitle,
  onChangeDatePickerHandler,
  fieldsReadOnly,
  keyType,
  editable
}: TCustomDatePickerProps & {
  keyType: 'product' | 'personal' | 'modal' | 'autocred';
}) => {
  const getKey = (keyType: 'product' | 'personal' | 'modal' | 'autocred') => {
    switch (keyType) {
      case 'product':
        return productInfoItemsInsurance.find((item) => item.label === label)
          ?.key;
      case 'personal':
        return personalClientInsurance.find((item) => item.label === label)
          ?.key;
      case 'modal':
        return modalTSItemsInsuramce.find((item) => item.label === label)?.key;
      default:
        return '';
    }
  };

  const key = getKey(keyType);
  return (
    <div className="custonItem">
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={
          ruRU.components.MuiLocalizationProvider.defaultProps.localeText
        }
        adapterLocale="ru"
      >
        <DatePicker
          disabled={editable}
          format="DD-MM-YYYY"
          readOnly={fieldsReadOnly !== undefined ? fieldsReadOnly : false}
          slotProps={{ textField: { fullWidth: true } }}
          label={
            <p
              style={{
                color: '#687588',
                lineHeight: '160%',
                fontWeight: '400'
              }}
            >
              {labelTitle} <span style={{ color: '#E03137' }}> *</span>
            </p>
          }
          value={value}
          onChange={(val) => onChangeDatePickerHandler(val, label, type)}
        />
      </LocalizationProvider>
    </div>
  );
};
