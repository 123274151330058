export const INSURANCE_STATUSES = {
  waiting_bank_response: {
    text: 'На рассмотрении',
    color: '#FFD023',
    textColor: 'black'
  },
  waiting_of_payment: {
    text: 'Полис ожидает оплаты',
    color: '#FFD023',
    textColor: 'black'
  },
  error: {
    text: 'Ошибка в процессе',
    color: '#FF2E1F',
    textColor: 'white'
  },
  completed: {
    text: 'Полис выдан',
    color: '#0CAF60',
    textColor: 'white'
  },
  financed: {
    text: 'Полис выдан',
    color: '#0CAF60',
    textColor: 'white'
  },
  sms_verificating: {
    text: 'SMS верификация',
    color: '#687588',
    textColor: 'white'
  },
  draft: {
    text: 'Черновик',
    color: '#687588',
    textColor: 'white'
  }
};
