import './bank-declined-item.scss';

import React, { useState } from 'react';

import { getFormattedBankName } from '@/services';
import { TBankOffers } from '@/types/offers';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import Icons from '@/components/Shared/Icons';

interface IProps {
  bankOffer: TBankOffers;
}

const NewBankDeclinedItem: React.FC<IProps> = ({ bankOffer }) => {
  const [showDescriptionModal, setShowDescriptionModal] =
    useState<boolean>(false);

  const shouldShowData = bankOffer.initialStatus?.code === 'cancelled';

  return (
    <>
      <div className="bank-declined-item">
        <div className="bank-declined-item__header">
          <div className="bank-declined-item__img">
            <img src={bankOffer.icon} alt="" />
          </div>
          <div>
            <p className="bank-declined-item__header-title">
              {getFormattedBankName(bankOffer.name)}
            </p>
            <div className="bank-declined-item__status-wrapper">
              <div className="bank-declined-item__status">
                {bankOffer.initialStatus?.name || 'Ошибка'}
              </div>
            </div>
          </div>
        </div>

        <div className="bank-declined-item__info">
          <div className="bank-declined-item__info-item">
            <p className="bank-declined-item__info-label">
              Ежемесячный платеж:
            </p>
            <b className="bank-declined-item__info-value">
              {shouldShowData ? `${bankOffer.monthlyPayment} ₸` : '-'}
            </b>
          </div>
          <div className="bank-declined-item__info-item">
            <p className="bank-declined-item__info-label">Процентная ставка:</p>
            <b className="bank-declined-item__info-value bank-declined-item__info-value--red">
              {shouldShowData ? `${bankOffer.rate} %` : '-'}
            </b>
          </div>
          <div className="bank-declined-item__info-item">
            <p className="bank-declined-item__info-label">Срок:</p>
            <b className="bank-declined-item__info-value">
              {shouldShowData ? `${bankOffer.term} месяца` : '-'}
            </b>
          </div>
          <div className="bank-declined-item__info-item">
            <p className="bank-declined-item__info-label">Сумма займа:</p>
            <b className="bank-declined-item__info-value">
              {shouldShowData ? `${bankOffer.loanAmount} ₸` : '-'}
            </b>
          </div>
          <div className="bank-declined-item__info-item">
            <p className="bank-declined-item__info-label">
              Первоначальный взнос:
            </p>
            <b className="bank-declined-item__info-value">
              {shouldShowData ? `${bankOffer.initialFee} ₸` : '-'}
            </b>
          </div>
          {(bankOffer.bank === 'halyk' ||
            bankOffer.bank === 'bcc' ||
            bankOffer.bank === 'eu_bank' ||
            bankOffer.bank === 'bereke') && (
            <div className="bank-declined-item__info-item">
              <p className="bank-declined-item__info-label">Доп. продукты:</p>
              <b className="bank-declined-item__info-value">КАСКО в подарок</b>
            </div>
          )}
        </div>

        {bankOffer.reason && (
          <div className="bank-declined-item__actions">
            <button
              onClick={() => setShowDescriptionModal(true)}
              className="bank-declined-item__details"
            >
              Детали ошибки
              <Icons.InfoFillIcon />
            </button>
          </div>
        )}
      </div>

      <CustomModal
        editClass={'description'}
        handleCancel={() => setShowDescriptionModal(false)}
        handleOk={() => setShowDescriptionModal(false)}
        isModalOpen={showDescriptionModal}
        title=""
        footer={[]}
      >
        <p style={{ marginTop: '30px', fontSize: '16px', fontWeight: '600' }}>
          {bankOffer.statusType === 'cancelled'
            ? 'Отклонено клиентом'
            : bankOffer.reason}
        </p>
      </CustomModal>
    </>
  );
};

export default NewBankDeclinedItem;
