import './range-picker.scss';

import React, { useState } from 'react';
import { Dayjs } from 'dayjs';

import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

interface IProps {
  placeholder: string[];
  value: [Dayjs | null, Dayjs | null] | null | undefined;
  onChange:
    | ((
        dates: [Dayjs | null, Dayjs | null] | null,
        dateStrings: [string, string]
      ) => void)
    | undefined;
  format: string;
  label?: string;
}

const CustomRangePicker: React.FC<IProps> = ({
  placeholder,
  value,
  onChange,
  format,
  label
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const moveLabel = value && (value[0] || value[1]);

  return (
    <div
      className={`range-picker ${
        isFocused || moveLabel ? 'range-picker--focused' : ''
      }`}
    >
      {label && <label className="range-picker__label">{label}</label>}
      <RangePicker
        placeholder={
          isFocused || moveLabel ? (placeholder as [string, string]) : ['', '']
        }
        value={value}
        format={format}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="range-picker__field"
      />
    </div>
  );
};

export default CustomRangePicker;
