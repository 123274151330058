import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sqlExecutor } from '@/integrations/index.api';

type TInitialState = {
  response: any[];
  isLoading: boolean;
};

const initialState: TInitialState = {
  response: [],
  isLoading: false
};

export const getExecutorSql = createAsyncThunk(
  'admin/executorSql',
  async (data: { query: string }) => {
    const res = await sqlExecutor.executeSQL(data);
    //console.log(res);
    return res;
  }
);

export const adminExecutorSqlSlice = createSlice({
  name: 'admin/executor',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExecutorSql.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getExecutorSql.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getExecutorSql.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const {} = adminExecutorSqlSlice.actions;
