export const topDCTable = [
  {
    name: '(SAP) B Skoda Auto Almaty',
    totalRequests: 1850,
    carPrice: 14_800_000_000,
    fundingSum: 1_480_000_000
  },
  {
    name: '(SAP) Jac Motors Kostanay',
    totalRequests: 1522,
    carPrice: 12_176_000_000,
    fundingSum: 1_217_600_000
  },
  {
    name: '(SAP) Jac Рус-Моторс Atyrau',
    totalRequests: 1499,
    carPrice: 11_992_000_000,
    fundingSum: 1_199_200_000
  },
  {
    name: '(SAP) Kia Auto Astana',
    totalRequests: 1479,
    carPrice: 19_992_000_000,
    fundingSum: 1_999_200_000
  },
  {
    name: '(SAP) Jac Semey',
    totalRequests: 455,
    carPrice: 11_992_000_000,
    fundingSum: 1_199_200_000
  },
  {
    name: '(SAP) Jetour Aqtau',
    totalRequests: 449,
    carPrice: 11_992_000_000,
    fundingSum: 1_199_200_000
  },
  {
    name: '(SAP) Jetour Astana',
    totalRequests: 401,
    carPrice: 11_992_000_000,
    fundingSum: 1_199_200_000
  },
  {
    name: '(SAP) Jetour Almaty',
    totalRequests: 399,
    carPrice: 11_992_000_000,
    fundingSum: 1_199_200_000
  },
  {
    name: '(SAP) Jetour Kyzylorda',
    totalRequests: 355,
    carPrice: 11_992_000_000,
    fundingSum: 1_199_200_000
  },
  {
    name: '(SAP) Jetour Shymkent',
    totalRequests: 146,
    carPrice: 11_992_000_000,
    fundingSum: 1_199_200_000
  }
];
