import React, { useEffect, useRef, useState } from 'react';
import '@/styles/FileUpload.scss';
import { pathTrashIcon } from '@/constants';
import { calculateFileSizeInMB } from '@/services/helpers/calculateFileSizeInMB';

export type TFileUploadProps = {
  onFileChange: (
    file: File | null,
    fileToBase64: string | null,
    type: string
  ) => void;
  title: string;
  type: string;
  subType?: boolean | undefined;
  customeStyles?: React.CSSProperties | undefined;
  positionLeft?: string;
  marginLeft?: string;
  isLg?: boolean;
  fileNameMargin?: string;
  selectedmargin?: string;
  docPhoto?: boolean;
  setBackFrontDoc?: React.Dispatch<React.SetStateAction<boolean>>;
  file?: any;
  fileName?: string;
  preventActions: boolean;
  fileFormat?: string;
};
export const FileUpload = ({
  file,
  onFileChange,
  title,
  type,
  subType,
  customeStyles,
  positionLeft,
  marginLeft,
  isLg,
  fileNameMargin,
  selectedmargin,
  docPhoto,
  setBackFrontDoc,
  fileName,
  preventActions,
  fileFormat
}: TFileUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (preventActions) return;
    const files = event.target.files;

    if (docPhoto) {
      if (selectedFile) setBackFrontDoc && setBackFrontDoc(true);
    }

    if (files && files.length > 0) {
      const file = files[0];
      const base64String = await convertFileToBase64(file);
      setSelectedFile(file);
      onFileChange(file, base64String, type);
    }
  };

  const convertFileToBase64 = async (file: File): Promise<string> => {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleRemoveFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedFile(null);
    onFileChange(null, null, type);
    setBackFrontDoc && setBackFrontDoc(false);
    if (inputRef.current) inputRef.current.value = '';
  };

  const handleFileUploadClick = () => {
    if (preventActions) return;
    if (inputRef.current) inputRef.current.click();
  };

  useEffect(() => {
    setSelectedFile(file);
  }, [file]);

  return (
    <div
      style={customeStyles}
      className={`fileUpload ${subType ? 'fileUpload_lastStep' : ''}`}
      onClick={handleFileUploadClick}
    >
      <div>
        <label className="customPlaceHolder" style={{ left: positionLeft }}>
          {title}
        </label>
        <input
          accept={fileFormat}
          type="file"
          onChange={handleFileChange}
          ref={inputRef}
        />

        {!preventActions ? (
          <>
            {selectedFile ? (
              <div className="selected" style={{ marginLeft: selectedmargin }}>
                <div
                  className="fileName"
                  style={{ marginLeft: fileNameMargin }}
                >
                  <p>{selectedFile.name}</p>
                  {selectedFile.size ? (
                    <span>
                      {`${calculateFileSizeInMB(selectedFile.size)}`}&nbsp;МБ
                    </span>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="choose" style={{ marginLeft: marginLeft }}>
                Выберите файл
              </div>
            )}
          </>
        ) : (
          <div className="selected" style={{ marginLeft: selectedmargin }}>
            <div className="fileName" style={{ marginLeft: fileNameMargin }}>
              <p>{fileName}</p>
            </div>
          </div>
        )}
      </div>

      <button
        className={`icon-button ${isLg ? 'btn_lg' : ''}`}
        onClick={handleRemoveFile}
      >
        <img src={pathTrashIcon} alt="remove_icon" />
      </button>
    </div>
  );
};
