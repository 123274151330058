import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { FamilyStatusModal } from '../Modals/FamilyStatusModal';
import { TextField } from '@mui/material';
import { TDict } from '@/types/store';
import { TPersonInfo, TPersonSpouseInfo } from '@/types/personInfo';
import { TClientDataState, TFocusState } from '@/types/state';
import { dispatch, useAppSelector } from '@/store';
import { isFocusData } from '@/data/orderStep.data';
import {
  getCityOptions,
  getRegionOptions,
  getVillageOptions
} from '@/store/dict.slice';
import {
  findDictByTitle,
  findDocType,
  setDictionaryOptions,
  validateNumberInputValue,
  validateStringInputLength
} from '@/services';
import { DictTypes } from '@/constants';
import {
  formatPhoneNumber,
  validateDocumentNumber
} from '@/services/formatter';
import {
  personalClientInfoItems,
  personalClientJobData
} from '@/data/personalInfo.data';
import { CustomDatePicker } from '@/components/Shared/DatePicker/CustomDatePicker';
import { CustomSelect } from '@/components/Shared/Select/CustomSelect';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { CustomPhoneInput } from '@/components/Shared/Input/CustomPhoneInput';
import { checkObjectValues } from '@/services/validators/checkObjectValues';

export type TSecondStepProps = {
  setPersonSpouseInfo: React.Dispatch<React.SetStateAction<TPersonSpouseInfo>>;
  personSpouseInfo: TPersonSpouseInfo;
  setShowFamilyStatusModal: React.Dispatch<React.SetStateAction<boolean>>;
  showFamilyStatusModal: boolean;
  personalInfo: TPersonInfo;
  setPersonalInfo: React.Dispatch<React.SetStateAction<TPersonInfo>>;
  // personalAddressInfo: TPersonAddressInfo;
  // setPersonalAddressInfo: React.Dispatch<
  //   React.SetStateAction<TPersonAddressInfo>
  // >;
  // setAddressValues: React.Dispatch<React.SetStateAction<TAddressValues>>;
  dicts: TDict[] | null;
  fieldsReadOnly: boolean;
  clientInfo: TClientDataState;
};

export const SecondStep = ({
  clientInfo,
  dicts,
  fieldsReadOnly,
  setPersonSpouseInfo,
  personSpouseInfo,
  personalInfo,
  setPersonalInfo,
  setShowFamilyStatusModal,
  showFamilyStatusModal
}: TSecondStepProps) => {
  const { regionOption, cityOption } = useAppSelector((state) => state.dict);
  const { orderApp } = useAppSelector((state) => state.newOrder);
  const [isFocused, setIsFocused] = useState<TFocusState>(isFocusData);
  const [addressType, setAddressType] = useState<string>('');
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [validSpouseInfo, setValidSpouseInfo] = useState<boolean>(true);
  const [optionsValidate, setOptionsValidate] = useState<{
    isMainCity: boolean;
    isRegion: boolean;
    isSimpleCity: boolean;
    isVillage: boolean;
  }>({
    isMainCity: false,
    isRegion: false,
    isSimpleCity: false,
    isVillage: false
  });

  const onChangeSelectHandler = (
    val: string,
    label: string,
    type: string,
    addressType?: string
  ) => {
    // console.log('value:', val);
    if (type === 'secondStep') {
      setPersonalInfo((prevState) => ({
        ...prevState,
        [label]: val.toString()
      }));
    } else if (type === 'secondStepSpouse') {
      setPersonSpouseInfo((prevState) => ({
        ...prevState,
        [label]: val.toString()
      }));
    } else if (type === 'secondStepAddress') {
      // setPersonalAddressInfo((prevState) => ({
      //   ...prevState,
      //   [label]: val.toString()
      // }));

      if (
        ['г.Астана', 'г.Алматы', 'г.Шымкент'].some((str) => val.includes(str))
      ) {
        setOptionsValidate((prevState) => ({
          ...prevState,
          isMainCity: true,
          isRegion: false,
          isSimpleCity: false,
          isVillage: false
        }));

        if (addressType === 'Адрес прописки') {
          // setPersonalAddressInfo((prevState) => ({
          //   ...prevState,
          //   regionResidence: '—',
          //   villageSettlementResidence: '—'
          // }));
        } else if (addressType === 'Фактический адрес') {
          // setPersonalAddressInfo((prevState) => ({
          //   ...prevState,
          //   regionPersonal: '—',
          //   villageSettlementPersonal: '—'
          // }));
          // setIsFocused(prevState => ({...prevState, regionPersonal: true, villageSettlementPersonal: true}));
        } else if (addressType === 'Рабочий адрес') {
          // setPersonalAddressInfo((prevState) => ({
          //   ...prevState,
          //   regionJob: '—',
          //   villageSettlementJob: '—'
          // }));
          // setIsFocused(prevState => ({...prevState, regionJob: true, villageSettlementJob: true}));
        }
      } else {
        if (
          regionOption?.some((str) =>
            val.toLowerCase().includes(str.value.toLowerCase())
          )
        ) {
          if (addressType === 'Адрес прописки') {
            // setPersonalAddressInfo((prevState) => ({
            //   ...prevState,
            //   cityDistrictResidence: '—'
            // }));
            // setIsFocused(prevState => ({...prevState, cityDistrictResidence: true}));
            // setIsFocused(prevState => ({...prevState, villageSettlementResidence: personalAddressInfo.villageSettlementResidence ? true : false}));
          } else if (addressType === 'Фактический адрес') {
            // setPersonalAddressInfo((prevState) => ({
            //   ...prevState,
            //   cityDistrictPersonal: '—'
            // }));
            // setIsFocused(prevState => ({...prevState, cityDistrictPersonal: true}));
            // setIsFocused(prevState => ({...prevState, villageSettlementPersonal: personalAddressInfo.villageSettlementPersonal ? true : false}));
          } else if (addressType === 'Рабочий адрес') {
            // setPersonalAddressInfo((prevState) => ({
            //   ...prevState,
            //   cityDistrictJob: '—'
            // }));
            // setIsFocused(prevState => ({...prevState, cityDistrictJob: true}));
            // setIsFocused(prevState => ({...prevState, villageSettlementJob: personalAddressInfo.villageSettlementJob ? true : false}));
          }

          setOptionsValidate((prevState) => ({
            ...prevState,
            isRegion: true,
            isMainCity: false,
            isSimpleCity: false,
            isVillage: false
          }));
          dispatch(
            getCityOptions(
              regionOption?.filter((item) => item.value === val)[0]?.code || ''
            )
          );
          dispatch(
            getVillageOptions(
              regionOption?.filter((item) => item.value === val)[0]?.code || ''
            )
          );
        } else if (
          cityOption?.some((str) =>
            val.toLowerCase().includes(str.value.toLowerCase())
          )
        ) {
          if (addressType === 'Адрес прописки') {
            // setPersonalAddressInfo((prevState) => ({
            //   ...prevState,
            //   villageSettlementResidence: '—'
            // }));
            // setIsFocused(prevState => ({...prevState, villageSettlementResidence: true}));
          } else if (addressType === 'Фактический адрес') {
            // setPersonalAddressInfo((prevState) => ({
            //   ...prevState,
            //   villageSettlementPersonal: '—'
            // }));
            // setIsFocused(prevState => ({...prevState, villageSettlementPersonal: true}));
          } else if (addressType === 'Рабочий адрес') {
            // setPersonalAddressInfo((prevState) => ({
            //   ...prevState,
            //   villageSettlementJob: '—'
            // }));
            // setIsFocused(prevState => ({...prevState, villageSettlementJob: true}));
          }

          setOptionsValidate((prevState) => ({
            ...prevState,
            isSimpleCity: true,
            isMainCity: false,
            isRegion: false,
            isVillage: false
          }));
        } else {
          if (addressType === 'Адрес прописки') {
            // setPersonalAddressInfo((prevState) => ({
            //   ...prevState,
            //   cityDistrictResidence: '—'
            // }));
            // setIsFocused(prevState => ({...prevState, cityDistrictResidence: true}));
          } else if (addressType === 'Фактический адрес') {
            // setPersonalAddressInfo((prevState) => ({
            //   ...prevState,
            //   cityDistrictPersonal: '—'
            // }));
            // setIsFocused(prevState => ({...prevState, cityDistrictPersonal: true}));
          } else if (addressType === 'Рабочий адрес') {
            // setPersonalAddressInfo((prevState) => ({
            //   ...prevState,
            //   cityDistrictJob: '—'
            // }));
            // setIsFocused(prevState => ({...prevState, cityDistrictJob: true}));
          }

          setOptionsValidate((prevState) => ({
            ...prevState,
            isVillage: true,
            isMainCity: false,
            isRegion: false,
            isSimpleCity: false
          }));
        }
      }
    }
  };

  const onChangeFamilyStatus = (val: string, label: string, type: string) => {
    onChangeSelectHandler(val.toString(), label, type);
    const dictToFind = dicts?.find(
      (dict) => dict.dictType === 'Marital_Statuses'
    );
    const marriedCode = dictToFind?.content?.find(
      (dict) => dict.name === 'Женат/замужем'
    )?.code;
    marriedCode === parseInt(val) &&
      !fieldsReadOnly &&
      setShowFamilyStatusModal(true);
    setPersonSpouseInfo((prevState) => ({
      ...prevState,
      isMarried: val === 'Женат/Замужем'
    }));
  };

  const onChangeInputHandler = (val: string, label: string, type: string) => {
    if (type === 'secondStep') {
      if (label === 'docNum') {
        const dictionaryToSearch = findDictByTitle(
          DictTypes.DocumentTypes,
          dicts
        );
        const docType = findDocType(
          Number(personalInfo.docType),
          dictionaryToSearch
        );
        val = validateDocumentNumber(val, docType);
      } else if (label === 'docAuth') {
        val = validateStringInputLength(val, 30);
      } else if (label === 'addContactsTelNum' || label === 'workTelNumber') {
        val = formatPhoneNumber(val);
      } else if (label === 'children') {
        val = validateNumberInputValue(val, 2);
      } else if (label === 'salary' || label === 'additionalSalary') {
        val = validateNumberInputValue(val, 8, true);
      }
      // setIsFocused(prevState => ({...prevState, [label]: val !== "" ? true : false}));
      setPersonalInfo((prevState) => ({ ...prevState, [label]: val }));
    } else if (type === 'secondStepSpouse') {
      // if (label === "spouseTelNum") val = formatPhoneNumber(val);
      if (
        label === 'spouseIin' ||
        label === 'spouseSalary' ||
        label === 'spouseAddSalary'
      )
        val = validateNumberInputValue(val, 12);
      if (label === 'spouseTelNum') val = formatPhoneNumber(val);
      // setIsFocused(prevState => ({...prevState, [label]: val !== "" ? true : false}));
      setPersonSpouseInfo((prevState) => ({ ...prevState, [label]: val }));
    } else if (type === 'secondStepAddress') {
      // setIsFocused(prevState => ({...prevState, [label]: val !== "" ? true : false}));
      // setPersonalAddressInfo((prevState) => ({ ...prevState, [label]: val }));
    }
  };

  const modalFamilyStatusHandler = () => {
    setShowFamilyStatusModal(false);
    setPersonSpouseInfo((prevState) => ({ ...prevState, isMarried: true }));
  };

  const modalAddressHandler = () => {
    setShowAddressModal(false);
    // const obj = {
    //   residence: formatAddress({ personalAddressInfo, type: 'residence' }),
    //   personal: formatAddress({ personalAddressInfo, type: 'personal' }),
    //   job: formatAddress({ personalAddressInfo, type: 'job' })
    // };
    let selectedLabel: 'residentAddress' | 'jobAddress' | 'personalAddress' =
      'jobAddress';
    let objKeyHandler: 'personal' | 'job' | 'residence' = 'job';

    if (addressType === 'Адрес прописки') {
      selectedLabel = 'residentAddress';
      objKeyHandler = 'residence';
    } else if (addressType === 'Фактический адрес') {
      selectedLabel = 'personalAddress';
      objKeyHandler = 'personal';
    } else if (addressType === 'Рабочий адрес') {
      selectedLabel = 'jobAddress';
      objKeyHandler = 'job';
    }

    // onChangeInputHandler(
    //   obj[objKeyHandler],
    //   personalInfoItems.find((item) => item.key === selectedLabel)?.key || '',
    //   'secondStep'
    // );

    // const residenceObject = {
    //   cityDistrict: personalAddressInfo.cityDistrictResidence,
    //   appartmentNum: personalAddressInfo.appartmentNumResidence,
    //   houseNum: personalAddressInfo.houseNumResidence,
    //   region: personalAddressInfo.regionResidence,
    //   streetName: personalAddressInfo.streetNameResidence,
    //   villageSettlement: personalAddressInfo.villageSettlementResidence
    // };
    // const personAddressObject = {
    //   appartmentNum: personalAddressInfo.appartmentNumPersonal,
    //   cityDistrict: personalAddressInfo.cityDistrictPersonal,
    //   houseNum: personalAddressInfo.houseNumPersonal,
    //   region: personalAddressInfo.regionPersonal,
    //   streetName: personalAddressInfo.streetNamePersonal,
    //   villageSettlement: personalAddressInfo.villageSettlementPersonal
    // };
    // const jobAddressObject = {
    //   appartmentNum: personalAddressInfo.appartmentNumJob,
    //   cityDistrict: personalAddressInfo.cityDistrictJob,
    //   houseNum: personalAddressInfo.houseNumJob,
    //   region: personalAddressInfo.regionJob,
    //   streetName: personalAddressInfo.streetNameJob,
    //   villageSettlement: personalAddressInfo.villageSettlementJob
    // };
    // setAddressValues({
    //   residenceObject,
    //   personAddressObject,
    //   jobAddressObject
    // });
  };

  const onChangeDatePickerHandler = (val: any, label: string, type: string) => {
    if (type === 'secondStep') {
      // setIsFocused(prevState => ({...prevState, [label]: val !== "" ? true : false}));
      setPersonalInfo((prevState) => ({ ...prevState, [label]: val }));
    } else if (type === 'secondStepSpouse') {
      // setIsFocused(prevState => ({...prevState, [label]: val !== "" ? true : false}));
      setPersonSpouseInfo((prevState) => ({ ...prevState, [label]: val }));
    }
  };

  useEffect(() => {
    setSubmitDisabled(true);
    if (addressType === 'Адрес прописки') {
      // const residenceObject = {
      //   cityDistrictResidence: personalAddressInfo.cityDistrictResidence,
      //   // appartmentNumResidence: personalAddressInfo.appartmentNumResidence,
      //   houseNumResidence: personalAddressInfo.houseNumResidence,
      //   regionResidence: personalAddressInfo.regionResidence,
      //   streetNameResidence: personalAddressInfo.streetNameResidence,
      //   villageSettlementResidence:
      //     personalAddressInfo.villageSettlementResidence
      // };
      // if (optionsValidate.isMainCity) {
      //   residenceObject.regionResidence = '-';
      //   residenceObject.villageSettlementResidence = '-';
      // } else if (optionsValidate.isRegion) {
      //   residenceObject.cityDistrictResidence = '-';
      //   residenceObject.villageSettlementResidence = '-';
      // } else if (optionsValidate.isSimpleCity) {
      //   residenceObject.villageSettlementResidence = '-';
      // } else if (optionsValidate.isVillage) {
      //   residenceObject.cityDistrictResidence = '-';
      // }
      // const isValid = checkObjectValues(residenceObject);
      // setSubmitDisabled(!isValid);
    } else if (addressType === 'Фактический адрес') {
      // const personAddressObject = {
      //   cityDistrictPersonal: personalAddressInfo.cityDistrictPersonal,
      //   houseNumPersonal: personalAddressInfo.houseNumPersonal,
      //   regionPersonal: personalAddressInfo.regionPersonal,
      //   streetNamePersonal: personalAddressInfo.streetNamePersonal,
      //   villageSettlementPersonal: personalAddressInfo.villageSettlementPersonal
      // };
      // if (optionsValidate.isMainCity) {
      //   personAddressObject.regionPersonal = '-';
      //   personAddressObject.villageSettlementPersonal = '-';
      // } else if (optionsValidate.isRegion) {
      //   personAddressObject.cityDistrictPersonal = '-';
      //   personAddressObject.villageSettlementPersonal = '-';
      // } else if (optionsValidate.isSimpleCity) {
      //   personAddressObject.villageSettlementPersonal = '-';
      // } else if (optionsValidate.isVillage) {
      //   personAddressObject.cityDistrictPersonal = '-';
      // }
      // const isValid = checkObjectValues(personAddressObject);
      // setSubmitDisabled(!isValid);
    } else if (addressType === 'Рабочий адрес') {
      // const jobAddressObject = {
      //   appartmentNumJob: personalAddressInfo.appartmentNumJob,
      //   cityDistrictJob: personalAddressInfo.cityDistrictJob,
      //   houseNumJob: personalAddressInfo.houseNumJob,
      //   regionJob: personalAddressInfo.regionJob,
      //   streetNameJob: personalAddressInfo.streetNameJob,
      //   villageSettlementJob: personalAddressInfo.villageSettlementJob
      // };
      // if (optionsValidate.isMainCity) {
      //   jobAddressObject.regionJob = '-';
      //   jobAddressObject.villageSettlementJob = '-';
      // } else if (optionsValidate.isRegion) {
      //   jobAddressObject.cityDistrictJob = '-';
      //   jobAddressObject.villageSettlementJob = '-';
      // } else if (optionsValidate.isSimpleCity) {
      //   jobAddressObject.villageSettlementJob = '-';
      // } else if (optionsValidate.isVillage) {
      //   jobAddressObject.cityDistrictJob = '-';
      // }
      // const isValid = checkObjectValues(jobAddressObject);
      // setSubmitDisabled(!isValid);
    }

    const spouseDataToCheck: TPersonSpouseInfo = {
      spouseSalary: personSpouseInfo.spouseSalary,
      spouseResidentStatus: personSpouseInfo.spouseResidentStatus,
      spouseIin: personSpouseInfo.spouseIin,
      spousePlaceBirth: personSpouseInfo.spousePlaceBirth,
      spouseFullName: personSpouseInfo.spouseFullName,
      spouseDateBirth: dayjs(personSpouseInfo.spouseDateBirth).toString(), //.toDate(),
      spouseCompanyName: personSpouseInfo.spouseCompanyName,
      spouseAddSalary: personSpouseInfo.spouseAddSalary,
      spouseTelNum: personSpouseInfo.spouseTelNum,
      isMarried: true
    };
    const isValid = checkObjectValues(spouseDataToCheck);
    setValidSpouseInfo(!isValid);
  }, [
    addressType,
    // personalAddressInfo,
    optionsValidate,
    showFamilyStatusModal,
    personSpouseInfo
  ]);

  useEffect(() => {
    let firstSixLetters = clientInfo.iin.slice(0, 6);
    const year = firstSixLetters.slice(0, 2);
    const month = firstSixLetters.slice(2, 4);
    const day = firstSixLetters.slice(4, 6);
    const parsedDate =
      parseInt(year) > 24
        ? dayjs(`19${year}-${month}-${day}`)
        : dayjs(`20${year}-${month}-${day}`);

    let firstSixLettersSpouse = personSpouseInfo.spouseIin.slice(0, 6);
    const yearSpouse = firstSixLettersSpouse.slice(0, 2);
    const monthSpouse = firstSixLettersSpouse.slice(2, 4);
    const daySpouse = firstSixLettersSpouse.slice(4, 6);
    const parsedDateSpouse =
      parseInt(year) > 24
        ? dayjs(`19${yearSpouse}-${monthSpouse}-${daySpouse}`)
        : dayjs(`20${yearSpouse}-${monthSpouse}-${daySpouse}`);

    clientInfo.iin &&
      setPersonalInfo((prevState) => ({
        ...prevState,
        dateBirth: parsedDate.toString()
      }));
    personSpouseInfo.spouseIin &&
      setPersonSpouseInfo((prevState) => ({
        ...prevState,
        spouseDateBirth: parsedDateSpouse.toString()
      }));
    // personalInfo.docIssueDate &&
    //   setPersonalInfo((prevState) => ({
    //     ...prevState,
    //     docValidPeriod: dayjs(personalInfo.docIssueDate)
    //       .add(10, 'year')
    //       .subtract(1, 'day')
    //       .toDate()
    //   }));
  }, [clientInfo.iin, personSpouseInfo.spouseIin]);

  const onCancel = () => {
    setShowFamilyStatusModal(false);
    onChangeSelectHandler('', 'Семейное положение', 'secondStep');
  };

  const onClickAddress = (addressType: string) => {
    setAddressType(addressType);
    !fieldsReadOnly && setShowAddressModal(true);
    dispatch(getRegionOptions('0'));
  };

  const onCancelAddress = (title: string) => {
    setShowAddressModal(false);
    // (title === "Адрес прописки" && formatAddress({personalAddressInfo, type: "residence"}) === "") && setIsFocused(prevState => ({...prevState, residentAddress: false}));
    // (title === "Личный адрес" && formatAddress({personalAddressInfo, type: "personal"}) === "") && setIsFocused(prevState => ({...prevState, personalAddress: false}));
    // (title === "Рабочий адрес" && formatAddress({personalAddressInfo, type: "job"}) === "") && setIsFocused(prevState => ({...prevState, jobAddress: false}));
  };

  const onChangeCheckedHandler = (
    val: boolean,
    label: string,
    type: string
  ) => {
    if (type === 'secondStep') {
      setPersonalInfo((prevState) => ({ ...prevState, [label]: val }));
    } else if (type === 'secondStepSpouse') {
      setPersonSpouseInfo((prevState) => ({ ...prevState, [label]: val }));
    } else if (type === 'secondStepAddress') {
      // setPersonalAddressInfo((prevState) => ({ ...prevState, [label]: val }));
      if (val) {
        // setAddressValues((prevState) => ({
        //   ...prevState,
        //   personAddressObject: {
        //     cityDistrict: '',
        //     villageSettlement: '',
        //     region: '',
        //     streetName: '',
        //     houseNum: '',
        //     appartmentNum: ''
        //   }
        // }));
        // setPersonalAddressInfo((prevState) => ({
        //   ...prevState,
        //   cityDistrictPersonal: '',
        //   regionPersonal: '',
        //   villageSettlementPersonal: '',
        //   appartmentNumPersonal: '',
        //   houseNumPersonal: '',
        //   streetNamePersonal: ''
        // }));
        onChangeInputHandler('', 'personalAddress', 'secondStep');
        // setIsFocused(prevState => ({...prevState, personalAddress: false}));
      }
    }
  };

  return (
    <div className="second_step">
      <FamilyStatusModal
        dicts={dicts || []}
        showFamilyStatusModal={showFamilyStatusModal}
        modalFamilyStatusHandler={modalFamilyStatusHandler}
        onCancel={onCancel}
        isFocused={isFocused}
        personalSpouseInfo={personSpouseInfo}
        onChangeInputHandler={onChangeInputHandler}
        onChangeSelectHandler={onChangeSelectHandler}
        onChangeDatePickerHandler={onChangeDatePickerHandler}
        validSpouseInfo={validSpouseInfo}
      />
      {/*<AddressModal*/}
      {/*  adressType={addressType}*/}
      {/*  showAddressModal={showAddressModal}*/}
      {/*  modalAddressHandler={modalAddressHandler}*/}
      {/*  onCancel={onCancelAddress}*/}
      {/*  isFocused={isFocused}*/}
      {/*  personalAddressInfo={personalAddressInfo}*/}
      {/*  onChangeInputHandler={onChangeInputHandler}*/}
      {/*  onChangeSelectHandler={onChangeSelectHandler}*/}
      {/*  onchangeCheckedHandler={onChangeCheckedHandler}*/}
      {/*  submitButtonDisabled={submitDisabled}*/}
      {/*  optionsValidate={optionsValidate}*/}
      {/*/>*/}
      <h2>Информация о Клиенте</h2>

      <div className="personal_info">
        {personalClientInfoItems.map((item) => {
          if (item.type === 'datepicker') {
            let firstSixLetters = clientInfo.iin.slice(0, 6);
            const year = firstSixLetters.slice(0, 2);
            const month = firstSixLetters.slice(2, 4);
            const day = firstSixLetters.slice(4, 6);
            const parsedDate =
              parseInt(year) > 24
                ? dayjs(`19${year}-${month}-${day}`)
                : dayjs(`20${year}-${month}-${day}`);
            return (
              <CustomDatePicker
                key={item.key}
                isFocused={isFocused[item.key]}
                value={
                  personalInfo[item.key]
                    ? dayjs(personalInfo[item.key] as Dayjs)
                    : item.key === 'dateBirth'
                    ? (parsedDate as Dayjs)
                    : null
                }
                label={item.key}
                type={'secondStep'}
                labelTitle={item.label}
                fieldsReadOnly={fieldsReadOnly}
                onChangeDatePickerHandler={() => {
                  onChangeDatePickerHandler(
                    dayjs(personalInfo[item.key] as Date),
                    item.key,
                    'secondStep'
                  );
                }}
                keyType="autocred"
              />
            );
          } else if (item.type === 'select') {
            return (
              <CustomSelect
                key={item.key}
                isFocused={isFocused[item.key]}
                value={personalInfo[item.key] as string}
                option={
                  !item.snakeCaseValues
                    ? item.option
                    : setDictionaryOptions(item.snakeCaseValues, dicts)
                }
                label={item.key}
                type={'secondStep'}
                labelTitle={item.label}
                onChangeSelectHandler={
                  item.key === 'familyStatus'
                    ? onChangeFamilyStatus
                    : onChangeSelectHandler
                }
                fieldsReadOnly={fieldsReadOnly}
                keyType="autocred"
              />
            );
          } else if (item.type === 'input') {
            return (
              item.key !== 'jobAddress' &&
              item.key !== 'personalAddress' &&
              item.key !== 'residentAddress' && (
                <CustomInput
                  key={item.key}
                  isFocused={isFocused[item.key]}
                  value={personalInfo[item.key] as string}
                  label={item.key}
                  type={'secondStep'}
                  labelTitle={item.label}
                  onChangeInputHandler={onChangeInputHandler}
                  fieldsReadOnly={fieldsReadOnly}
                />
              )
            );
          }
        })}
      </div>

      <h2>Информация о работе клиента</h2>
      <div className="personal_info">
        {personalClientJobData.map((item) => {
          if (item.type === 'select') {
            return (
              <CustomSelect
                key={item.key}
                isFocused={isFocused[item.key]}
                value={personalInfo[item.key] as string}
                option={
                  !item.snakeCaseValues
                    ? item.option
                    : setDictionaryOptions(item.snakeCaseValues, dicts)
                }
                label={item.key}
                type={'secondStep'}
                labelTitle={item.label}
                onChangeSelectHandler={
                  item.key === 'familyStatus'
                    ? onChangeFamilyStatus
                    : onChangeSelectHandler
                }
                fieldsReadOnly={fieldsReadOnly}
                keyType="autocred"
              />
            );
          } else if (item.type === 'input' && item.key !== 'workTelNumber') {
            return (
              // item.key !== 'jobAddress' &&
              item.key !== 'workTelNumber' &&
              item.key !== 'personalAddress' &&
              item.key !== 'residentAddress' && (
                <CustomInput
                  key={item.key}
                  isFocused={isFocused[item.key]}
                  value={personalInfo[item.key] as string}
                  label={item.key}
                  type={'secondStep'}
                  labelTitle={item.label}
                  onChangeInputHandler={onChangeInputHandler}
                  fieldsReadOnly={fieldsReadOnly}
                />
              )
            );
          } else if (item.type === 'input' && item.key === 'workTelNumber') {
            return (
              <CustomPhoneInput
                key={item.key}
                value={personalInfo[item.key]}
                label={item.key}
                type={'secondStep'}
                onChangeInputHandler={onChangeInputHandler}
              />
            );
          }
        })}
      </div>
      {/*<div className="adress">*/}
      {/*  <div className="adress_switch">*/}
      {/*    <h2>Адрес</h2>*/}
      {/*    <p className="adress_switch_paragraph">*/}
      {/*      {personalAddressInfo.isLiveRegistred*/}
      {/*        ? 'Проживает там же где прописан'*/}
      {/*        : 'Не проживает там же где прописан'}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  <CustomInput*/}
      {/*    isFocused={isFocused.residentAddress}*/}
      {/*    value={formatAddress({ personalAddressInfo, type: 'residence' })}*/}
      {/*    label={*/}
      {/*      personalInfoItems.find((item) => item.key === 'residentAddress')*/}
      {/*        ?.key || ''*/}
      {/*    }*/}
      {/*    type={'secondStep'}*/}
      {/*    labelTitle={'Адрес прописки'}*/}
      {/*    onClickInputHandler={() => onClickAddress('Адрес прописки')}*/}
      {/*  />*/}

      {/*  {!personalAddressInfo.isLiveRegistred && (*/}
      {/*    <CustomInput*/}
      {/*      isFocused={isFocused.personalAddress}*/}
      {/*      value={formatAddress({ personalAddressInfo, type: 'personal' })}*/}
      {/*      label={*/}
      {/*        personalInfoItems.find((item) => item.key === 'personalAddress')*/}
      {/*          ?.key || ''*/}
      {/*      }*/}
      {/*      type={'secondStep'}*/}
      {/*      labelTitle={'Фактический адрес'}*/}
      {/*      onClickInputHandler={() => onClickAddress('Фактический адрес')}*/}
      {/*    />*/}
      {/*  )}*/}

      {/*  <CustomInput*/}
      {/*    isFocused={isFocused.jobAddress}*/}
      {/*    value={formatAddress({ personalAddressInfo, type: 'job' })}*/}
      {/*    label={*/}
      {/*      personalInfoItems.find((item) => item.key === 'jobAddress')?.key ||*/}
      {/*      ''*/}
      {/*    }*/}
      {/*    type={'secondStep'}*/}
      {/*    labelTitle={'Рабочий адрес'}*/}
      {/*    onClickInputHandler={() => onClickAddress('Рабочий адрес')}*/}
      {/*  />*/}
      {/*</div>*/}

      <h2>Контактные лица</h2>

      <div className="addContacts">
        <div className="customItem">
          <TextField
            id="outlined-controlled"
            sx={{ width: '100%' }}
            autoComplete={'off'}
            label={
              <p
                style={{
                  color: '#687588',
                  lineHeight: '160%',
                  fontWeight: '400'
                }}
              >
                ФИО <span style={{ color: '#E03137' }}> *</span>
              </p>
            }
            variant="outlined"
            name="addContactsFullName"
            // value={additionalInfo.addContactsFullName}
            value={personalInfo.addContactsFullName}
            onChange={(e) => {
              onChangeInputHandler(e.target.value, e.target.name, 'secondStep');
            }}
            // InputProps={{
            //   readOnly: fieldsReadOnly
            // }}
          />
        </div>
        <CustomPhoneInput
          value={personalInfo.addContactsTelNum}
          label={'addContactsTelNum'}
          type={'secondStep'}
          onChangeInputHandler={onChangeInputHandler}
        />
        {/*<CustomInput*/}
        {/*  key="addContactsTelNum"*/}
        {/*  isFocused={false}*/}
        {/*  value={personalInfo.addContactsTelNum}*/}
        {/*  label="addContactsTelNum"*/}
        {/*  type={'secondStep'}*/}
        {/*  labelTitle="Номер телефона"*/}
        {/*  onChangeInputHandler={onChangeInputHandler}*/}
        {/*  fieldsReadOnly={fieldsReadOnly}*/}
        {/*/>*/}
        <CustomSelect
          key={'addContactsIsRelated'}
          isFocused={false}
          value={personalInfo.addContactsIsRelated}
          option={setDictionaryOptions(DictTypes.AddContactsIsRelated, dicts)}
          label={'addContactsIsRelated'}
          type={'secondStep'}
          labelTitle="Кем приходится"
          onChangeSelectHandler={onChangeSelectHandler}
          fieldsReadOnly={fieldsReadOnly}
          keyType="autocred"
        />
      </div>
    </div>
  );
};
