import {
  TAddProductDictionaryResponse,
  TProductDictionary
} from '@/types/store';

export const addProductsDictionaryMapper = (
  serverResponse: TAddProductDictionaryResponse[]
): TProductDictionary[] => {
  return serverResponse
    .filter((res) => res.is_active)
    .map((res) => {
      const mappedValue: TProductDictionary = {
        uuid: res?.uuid || '',
        bank_name: res?.bank?.name || '',
        insurance_uuid: res?.insurance_uuid || null,
        bank: res?.bank_uuid || '',
        name: res?.name || '',
        code: res?.code || '',
        productType: res?.product_type?.name || '',
        description: res?.promo_body || '',
        grace: res?.grace_period ? Number(res?.grace_period) : null,
        loan_amount: res?.loan_amount || null,
        loan_period: res?.loan_period || null,
        partner_code: res?.partner_code || null,
        product_code: res?.product_code || null,
        enabled_by_default: res.enabled_by_default
      };
      return mappedValue;
    });
};
