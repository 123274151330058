import '@/styles/Insurance.scss';
import React, { useEffect, useState } from 'react';
import InsuranceHeader from './InsuranceHeader';
import { Input, Select, Spin, Table } from 'antd';
import { dispatch, useAppSelector } from '@/store';
import { columnsInsurance } from '@/data/insurance.data';
import { getFullPageTableHeight } from '@/services';
import {
  filterInsurance,
  getOneInsuranceReq,
  onSetInsurancesStoreToInitial
} from '@/store/orderInsurance.slice';
import { useNavigate } from 'react-router-dom';
import { TNewInsuranceResponse } from '@/types/insuranceMapper';
import {
  secondStepStatuses,
  thirdStepStatuses
} from './InsuranceOrder/existed-insurance/existed-insurance';

export const INSURANCE_STEPS = [
  {
    key: 'order',
    icon: <span>1</span>,
    title: 'Заявка'
  },
  {
    key: 'offer',
    icon: <span>2</span>,
    title: 'Предложения'
  },
  {
    key: 'financing',
    icon: <span>3</span>,
    title: 'Финансирование'
  }
];

export default function Insurance() {
  const { Search } = Input;

  const [selectedUser, setSelectedUser] = useState<string>('all');
  const [selectedUserBasel, setSelectedUserBasel] = useState<string>('-1');
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState<string[]>(
    []
  );
  const navigate = useNavigate();
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [filteredArray, setFilteredArray] = useState<TNewInsuranceResponse[]>(
    []
  );
  // const [searchValue, setSearchValue] = useState<string>('');
  const [telNumIin, setTelNumIin] = useState<string>('');

  const [userSearchType, setUserSearchType] = useState<string>('');
  const [paginationData, setPaginationData] = useState<{
    currentPage: number;
    itemsPerPage: number;
  }>({
    currentPage: 1,
    itemsPerPage: 10
  });
  const [isTableDataLoading, setIsTableLoading] = useState<boolean>(false);
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const { insurances, isLoading } = useAppSelector(
    (state) => state.orderInsurance
  );

  // const getRequestsPerPage = async () => {
  //   setIsTableLoading(true);
  //   try {
  //     const formattedNameSearchValue = cleanNameValue(searchValue);
  //     const formattedPhoneSearchValue = cleanPhoneNumber(searchValue);
  //     let searchType: 'iinTelNum' | 'empty' = 'empty';
  //     if (formattedNameSearchValue === '' && formattedPhoneSearchValue === '') {
  //       searchType = 'empty';
  //     } else if (
  //       formattedNameSearchValue !== '' &&
  //       formattedPhoneSearchValue === ''
  //     ) {
  //     } else {
  //       searchType = 'iinTelNum';
  //     }

  //const fetchFilteredInsurance = async () => {

  //       try {

  //         const data: any = await apiConnector.getFilterInsurance({ telNumIin, userSearchType });
  // console.log('---->', telNumIin, userSearchType)
  //         const dataSource: TNewInsuranceResponse[] = insuranceMapperResp(data);
  //         setFilteredArray(dataSource);
  //       } catch (e: any) {
  //         console.error('Error fetching filtered insurance:', e);
  //         throw new Error(e);
  //       } finally {
  //         setIsTableLoading(false);
  //       }
  //  };

  // function getRequestsPerPage() {
  //   throw new Error('Function not implemented.');
  // }
  // useEffect(() => {
  //   fetchFilteredInsurance();
  // }, [
  //   paginationData,
  //   selectedUser,
  //   selectedCities,
  //   selectedStatuses,
  //   selectedPaymentTypes
  // ]);
  useEffect(() => {
    if (selectedUser === 'all') {
      dispatch(filterInsurance({ telNumIin, userSearchType }));
    } else {
      dispatch(
        filterInsurance({ telNumIin: telNumIin, userSearchType: selectedUser })
      );
    }
  }, [selectedUser]);

  useEffect(() => {
    dispatch(onSetInsurancesStoreToInitial());
    dispatch(filterInsurance({ telNumIin, userSearchType }));
    // dispatch(getAllInsuranseReq({ any: [] }));
  }, []);

  const handleRowClicked = (row: TNewInsuranceResponse) => {
    const status = row.status.code;
    let tab = 1;
    if (secondStepStatuses.includes((status as string) || '')) tab = 2;
    else if (thirdStepStatuses.includes((status as string) || '')) tab = 3;
    navigate('/insurances/' + row.uuid + `?tab=${tab}`);
  };

  return (
    <>
      <div className="Insurance">
        <InsuranceHeader />
        <div className="table_editor_insurance">
          <Search
            style={{ flex: 1 }}
            size="large"
            placeholder="Поиск по ИИН / Номер тел."
            value={telNumIin}
            onChange={(e) => setTelNumIin(e.target.value)}
            onSearch={() =>
              dispatch(filterInsurance({ telNumIin, userSearchType }))
            }
          />
          {/* <Select
            showSearch
            optionFilterProp={'label'}
            style={{ width: '22%' }}
            size="large"
            placeholder="Basel"
            value={selectedUserBasel}
            onChange={setSelectedUserBasel}
            options={
              [
                { value: userInfo?.uuid, label: 'Каско' },
                { value: '-1', label: 'Basel' }
              ] || []
            }
            maxTagCount={'responsive'}
          /> */}
          <Select
            showSearch
            optionFilterProp={'label'}
            className="table_editor_insurance__select"
            size="large"
            placeholder="Все заявки"
            value={selectedUser}
            onChange={(value) => setSelectedUser(value)}
            options={
              [
                { value: userInfo?.uuid, label: 'Мои заявки' },
                { value: 'all', label: 'Все заявки' }
              ] || []
            }
            maxTagCount={'responsive'}
          />
        </div>
        <div className="table">
          {isLoading ? (
            <Spin fullscreen={true} />
          ) : (
            <Table
              rowClassName="insurance-table-row"
              size="small"
              showSorterTooltip={true}
              columns={columnsInsurance}
              rowKey={(record) => record.uuid || ''}
              dataSource={insurances}
              pagination={{
                position: ['bottomLeft'],
                showSizeChanger: true,
                total: insurances.length,
                pageSize: paginationData.itemsPerPage,
                // @ts-ignore
                selectComponentClass: ({
                  value,
                  onChange
                }: {
                  value: string | number;
                  onChange: (value: string | number) => void;
                }) => {
                  const currentAmount = paginationData.currentPage * +value;
                  const minAmount = currentAmount - +value + 1;
                  const maxAmount =
                    currentAmount > insurances.length
                      ? insurances.length
                      : currentAmount;
                  return (
                    <>
                      <span className="table__shown-text">
                        Показано {minAmount} - {maxAmount} из{' '}
                        {insurances.length} заявок
                      </span>
                      <Select size="small" value={value} onChange={onChange}>
                        <Select.Option value="10">Показать 10</Select.Option>
                        <Select.Option value="20">Показать 20</Select.Option>
                        <Select.Option value="50">Показать 50</Select.Option>
                      </Select>
                    </>
                  );
                },
                current: paginationData.currentPage,
                onChange: (step: number, itemsPerPage: number) => {
                  setPaginationData({
                    currentPage: step,
                    itemsPerPage: itemsPerPage
                  });
                }
              }}
              scroll={{
                x: 'max-content',
                y: getFullPageTableHeight()
              }}
              loading={isTableDataLoading}
              onRow={(r) => {
                return {
                  onClick: async () => {
                    handleRowClicked(r);
                  }
                };
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
