import '@/styles/_slices.scss';
import '@/styles/StatusContentBox.scss';
import '@/styles/InsuranceStepOrder.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { notification, Steps } from 'antd';

import { TClientDataState } from '@/types/state';
import { useAppDispatch, useAppSelector } from '@/store';
import { getOneInsuranceReq } from '@/store/orderInsurance.slice';
import { formatPhoneNumber } from '@/services/formatter';
import ClientInfo from '../ClientInfo';
import BreadCrumbs from '@/components/Shared/BreadCrumbs';
import { INSURANCE_STEPS } from '../Insuranсe';
import NewInsurance from './new-insurance';

dayjs.extend(duration);

export type TOrderInsuranceProps = {
  createRequest: () => void;
  clientInfo: TClientDataState;
};

export const InsuranceFirstStep = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    productInsuranceInfo: storeProductInfo,
    personalInsuranceInfo: storePersonalInfo,
    requestId
  } = useAppSelector((state) => state.orderInsurance);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (requestId && !loading) {
        setLoading(false);
        await dispatch(getOneInsuranceReq(requestId));
        setLoading(true);
      }
    })();
  }, [dispatch, loading, requestId]);

  useEffect(() => {
    if (!requestId) {
      navigate('/insurances');
    }
  }, [requestId, navigate]);

  const handleStepCurrentChange = () => {
    notification.warning({
      message: 'заполните форму',
      description:
        'Чтобы перейти на данный этап, вам необходимо создать заявку',
      duration: 5
    });
  };

  const STEPS = [<NewInsurance key={'new-insurance'} />];

  return (
    <div className="Insurance_Step_Order">
      <div className="Insurance_Step_Order__bread-crumbs">
        <BreadCrumbs
          links={[
            { text: 'Главная', href: '/' },
            { text: 'Страхование', href: '/insurances' }
          ]}
          current="Создание"
        />
      </div>

      <div className="Insurance_Step_Order__grid">
        <div className="Insurance_Step_Order__side-info">
          <ClientInfo
            clientData={{
              firstName: storePersonalInfo?.firstName || '',
              middleName: storePersonalInfo?.middleName || '',
              lastName: storePersonalInfo?.lastName || '',
              iin: storePersonalInfo?.clientIin || '',
              telNum: formatPhoneNumber(storePersonalInfo?.clientPhone || ''),
              id: `...${requestId.slice(-15)}`,
              errorTitle: '',
              comments: ''
            }}
            insuranceStatus={(storeProductInfo.status as string) || ''}
          />
        </div>

        <div>
          <div className="wrapper">
            <Steps
              className="order_steps"
              current={0}
              items={INSURANCE_STEPS}
              onChange={handleStepCurrentChange}
            />
          </div>

          {STEPS[0]}
        </div>
      </div>
    </div>
  );
};
