import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

import { Button, Input, notification, Select, Spin } from 'antd';
import { CustomDatePicker } from '@/components/Shared/DatePicker/CustomDatePicker';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { CustomPhoneInput } from '@/components/Shared/Input/CustomPhoneInput';
import CustomRangePicker from '@/components/Shared/Input/range-picker';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { CustomSelect } from '@/components/Shared/Select/CustomSelect';
import { CustomSwitch } from '@/components/Shared/Switch/CustomSwitch';

import { isFocusData } from '@/data/orderStep.data';
import {
  modalTSItemsInsuramce,
  personalClientInsurance,
  productInfoItemsInsurance
} from '@/data/productInsurance.data';

import { DictTypes } from '@/constants';
import { useMarkModel } from '@/hooks/useMarkModel';
import { setDictionaryOptions, validateNumberInputValue } from '@/services';
import { cleanPhoneNumber } from '@/services/formatter';
import { checkObjectValues } from '@/services/validators/checkObjectValues';
import {
  validateAutoInsuranceInfo,
  validatePersonalInsuranceInfo
} from '@/services/validators/insuranceValidator';
import { dispatch, useAppSelector } from '@/store';
import { createInsuranceOrder } from '@/store/orderInsurance.slice';
import { TAutoInsuranceInfo } from '@/types/avtoInsuranceInfo';
import { TClientInsuranceInfo } from '@/types/clientInsurance';
import { TInsuranceInfo } from '@/types/insuranceInfo';
import { TNewInsuranceDto } from '@/types/newInsurance';
import { TPersonInsuranceInfo } from '@/types/personInfo';
import { TProductInsuranceInfo } from '@/types/productInfo';
import { TFocusState } from '@/types/state';

interface IProps {
  isDisabled?: boolean;
}

const NewInsurance: React.FC<IProps> = ({ isDisabled }) => {
  const navigate = useNavigate();

  const {
    productInsuranceInfo: storeProductInfo,
    personalInsuranceInfo: storePersonalInfo,
    autoInsuranceInfo: storeAutoInsuranceInfo,
    insurance: storeInsuranceInfo,
    clientInsuranceInfo: storeClientInsuranceInfo,
    otpCode,
    uuid,
    requestId
  } = useAppSelector((state) => state.orderInsurance);

  const dealer = useAppSelector((state) => state.userInfo.dealer);
  const { dicts } = useAppSelector((state) => state.dict);

  const { clientInsuranceInfo: clientInsuranceInfoStore } = useAppSelector(
    (state) => state.orderInsurance
  );

  const {
    newOrder: { clientInfo }
  } = useAppSelector((state) => state);

  const [productInsuranceInfo, setProductInsuranceInfo] =
    useState<TProductInsuranceInfo>(storeProductInfo);
  const [personalInsuranceInfo, setPersonalInsuranceInfo] =
    useState<TPersonInsuranceInfo>(storePersonalInfo);

  const [insurance, setInsurance] =
    useState<TInsuranceInfo>(storeInsuranceInfo);

  const [autoInsuranceInfo, setAutoInsuranceInfo] =
    useState<TAutoInsuranceInfo>(storeAutoInsuranceInfo);

  const [clientInsuranceInfo, setClientInsuranceInfo] =
    useState<TClientInsuranceInfo>(storeClientInsuranceInfo);

  const [brandModelYearValueHandler, setBrandModelYearValueHandler] =
    useState<string>('');
  const [saveMarkModelYearDisabled, setSaveMarkModelYearDisabled] =
    useState<boolean>(true);
  const [isFocused, setIsFocused] = useState<TFocusState>(isFocusData);

  const [showModal, setShowModal] = useState<boolean>(false);

  const [showInstallmentInput, setShowInstallmentInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const {
    possibleModels,
    handleModalSelectChange,
    modalSelectState,
    possibleMarks
  } = useMarkModel(autoInsuranceInfo);
  const [stateButton, setStateButton] = useState(true);

  const handleInsuranceInputChange = (value: string, key: string) => {
    if (key === 'insuranseSumm') {
      if (Number(autoInsuranceInfo.priseAvto) < Number(value)) {
        return;
      }
    }
    setInsurance((prevInfo) => ({ ...prevInfo, [key]: value }));
  };

  const calculatePremiumInsuranceValues = (priseAvto: number) => {
    const insuranseSummValue = priseAvto.toString();
    handleInsuranceInputChange(insuranseSummValue, 'insuranseSumm');

    const insuransePremiumValue = Math.round(priseAvto * 0.035).toString();
    handleInsuranceInputChange(insuransePremiumValue, 'insuransePremium');
  };

  const calculateAutoServiceInsuranceValues = (priseAvto: number) => {
    const insuranseSummValue = Math.round(priseAvto * 0.8).toString();
    handleInsuranceInputChange(insuranseSummValue, 'insuranseSumm');

    const insuransePremiumValue = Math.round(
      Number(insuranseSummValue) * 0.02
    ).toString();
    handleInsuranceInputChange(insuransePremiumValue, 'insuransePremium');
  };

  useEffect(() => {
    const currentDate = dayjs();
    const nextDay = currentDate.add(1, 'day');
    //@ts-ignore
    setProductInsuranceInfo((prevState) => ({
      ...prevState,
      dateForm: currentDate,
      datepolis: nextDay
    }));
  }, []);

  useEffect(() => {
    if (productInsuranceInfo?.datepolis) {
      const updatedDate = dayjs(productInsuranceInfo?.datepolis).add(
        dayjs.duration({ days: -1, years: 1 })
      );
      //@ts-ignore
      setProductInsuranceInfo((prevState) => ({
        ...prevState,
        dateEndpolis: updatedDate.toString()
      }));
    }
  }, [productInsuranceInfo.datepolis, productInsuranceInfo.dateForm]);

  useEffect(() => {
    if (!autoInsuranceInfo.priseAvto) return;

    const priseAvto = Number(autoInsuranceInfo.priseAvto);

    if (productInsuranceInfo.productType == '78') {
      // premium
      calculatePremiumInsuranceValues(priseAvto);
    } else if (productInsuranceInfo.productType == '79') {
      // autoservice
      calculateAutoServiceInsuranceValues(priseAvto);
    }
  }, [autoInsuranceInfo.priseAvto]);

  useEffect(() => {
    if (clientInsuranceInfoStore) {
      setPersonalInsuranceInfo((prevState) => ({
        ...prevState,
        clientIin: clientInsuranceInfoStore.iin as string,
        clientPhone: clientInsuranceInfoStore.clientPhone as string
      }));
    }
  }, [clientInsuranceInfoStore]);

  useEffect(() => {
    setProductInsuranceInfo(storeProductInfo);
    setPersonalInsuranceInfo(storePersonalInfo);
    setAutoInsuranceInfo(storeAutoInsuranceInfo);
    setClientInsuranceInfo(storeClientInsuranceInfo);
    setInsurance(storeInsuranceInfo);
  }, [
    storeProductInfo,
    storePersonalInfo,
    storeAutoInsuranceInfo,
    storeClientInsuranceInfo,
    storeInsuranceInfo
  ]);

  useEffect(() => {
    if (dealer) {
      setAutoInsuranceInfo((prevState) => ({
        ...prevState,
        dealer: dealer.code as string,
        productType: '1',
        clientInfo: clientInfo?.iin as string,
        userInfo: dealer.code as string
      }));
    }
  }, [dealer, clientInfo, setAutoInsuranceInfo]);

  useEffect(() => {
    const { brand, model, year } = autoInsuranceInfo.markModelYear;
    const brandName = dicts
      ?.find((dict: { dictType: string }) => dict.dictType === 'Brands')
      ?.content.find((item) => item.code === parseInt(brand as string))?.name;
    const modelName = dicts
      ?.find((dict) => dict.dictType === 'Models')
      ?.content.find((item) => item.code === parseInt(model as string))?.name;
    const brandValue = brandName ? brandName : '-';
    const modelValue = modelName ? modelName : '-';
    const yearValue = year ? year : '-';
    const val = `${brandValue}, ${modelValue}, ${yearValue}`;
    setBrandModelYearValueHandler(val);
  }, [autoInsuranceInfo, dicts]);

  useEffect(() => {
    if (productInsuranceInfo.paymentType === '2') {
      setShowInstallmentInput(true);
    } else {
      setShowInstallmentInput(false);
    }
  }, [productInsuranceInfo.paymentType]);

  useEffect(() => {
    const isInsuranseValid = validatePersonalInsuranceInfo(
      personalInsuranceInfo
    );
    const idAutoValid = validateAutoInsuranceInfo(autoInsuranceInfo);
    if (isInsuranseValid && idAutoValid) {
      setStateButton(false);
    } else setStateButton(true);
  }, [
    personalInsuranceInfo,
    autoInsuranceInfo,
    productInsuranceInfo,
    autoInsuranceInfo
  ]);

  useEffect(() => {
    const isDisabled = !checkObjectValues(modalSelectState);
    setSaveMarkModelYearDisabled(isDisabled);
  }, [
    modalSelectState.markAuto,
    modalSelectState.modelAuto,
    modalSelectState.issueYear
  ]);

  const openModal = () => {
    setShowModal(true);
  };

  const onChangeSelectHandler = (val: string, label: string, type: string) => {
    // console.log(val, label);
    setProductInsuranceInfo((prevState) => ({
      ...prevState,
      [label]: val.toString()
    }));
    setPersonalInsuranceInfo((prevState) => ({
      ...prevState,
      [label]: val.toString()
    }));
    setAutoInsuranceInfo((prevState) => ({
      ...prevState,
      [label]: val.toString()
    }));
  };

  const modalSaveHandler = () => {
    setAutoInsuranceInfo({
      ...autoInsuranceInfo,
      markModelYear: {
        brand: modalSelectState.markAuto,
        model: modalSelectState.modelAuto,
        year: modalSelectState.issueYear
      }
    });
    setShowModal(false);
  };

  const onChangeInputHandler = (val: string, label: string, type: string) => {
    val = validateNumberInputValue(val, 8, true);
    setPersonalInsuranceInfo((prevState) => ({ ...prevState, [label]: val }));
  };

  const handleInputChange = (value: string, key: string) => {
    setProductInsuranceInfo((prevInfo) => ({ ...prevInfo, [key]: value }));
  };

  const handlePersonalInputChange = (value: string, key: string) => {
    // setPersonalInsuranceInfo((prevInfo) => {
    let transformedValue = value;

    if (key === 'clientPhone' || key === 'clientIin') {
      return;
    }

    if (key === 'firstName' || key === 'lastName' || key === 'middleName') {
      const capitalize = (str: string) => {
        if (!str) return str;
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      };
      transformedValue = capitalize(value);
    } else if (key === 'numberdocument') {
      if (value.length > 9) {
        return;
      } else {
        transformedValue = transformedValue.replace(/\D/g, '');
      }
    }
    setPersonalInsuranceInfo((prevInfo) => ({
      ...prevInfo,
      [key]: transformedValue
    }));
  };

  const handleAutoInputChange = (value: string, key: string) => {
    let autotransformValue = value;

    if (key === 'tehNum') {
      autotransformValue = value.toUpperCase();

      if (autotransformValue.length > 10) {
        return;
      }
    } else if (key === 'vinCode') {
      autotransformValue = value.toUpperCase();

      if (autotransformValue.length > 17) {
        return;
      }
    } else if (key === 'numberGos') {
      autotransformValue = value.toUpperCase();
      if (autotransformValue.length > 8) {
        return;
      }
    }

    setAutoInsuranceInfo((prevInfo) => ({
      ...prevInfo,
      [key]: autotransformValue
    }));
  };

  const onChangeCheckedHandler = (
    val: boolean,
    label: string,
    type: string
  ) => {
    //setProductInfo((prevState) => ({ ...prevState, [label]: val }));
  };

  const onChangeDatePickerHandler = (
    val: any,
    label: string,
    keyType: string
  ) => {
    switch (keyType) {
      case 'personal':
        setPersonalInsuranceInfo((prevState) => ({
          ...prevState,
          [label]: val
        }));
        break;
      case 'product':
        setProductInsuranceInfo((prevState) => ({
          ...prevState,
          [label]: val
        }));
        break;
      case 'modal':
        setAutoInsuranceInfo((prevState) => ({ ...prevState, [label]: val }));
        break;
      default:
        break;
    }
  };

  const handleRangePickerChange = (
    value: [Dayjs | null, Dayjs | null] | null,
    keys: string[]
  ) => {
    const [startKey, endKey] = keys;
    if (!value) {
      setProductInsuranceInfo((prevState) => ({
        ...prevState,
        [startKey]: null,
        [endKey]: null
      }));
      return;
    }

    if (!value[0] || !value[1]) return;

    const startDate = value[0];
    const endDate = value[1];
    setProductInsuranceInfo((prevState) => ({
      ...prevState,
      [startKey]: startDate.toString(),
      [endKey]: endDate.toString()
    }));
  };

  const onContinueHandler = async () => {
    setLoading(true);
    const newInsuranceDto: TNewInsuranceDto = {
      createDate: new Date().toISOString(),
      uuid: uuid || requestId,
      status: 'draft',
      otp: otpCode || '',
      otpDate: new Date().toISOString(),
      code: productInsuranceInfo?.id?.toString() || '',
      productInsuranceInfo: {
        product: {
          ...productInsuranceInfo,
          productTypeDate: productInsuranceInfo?.productTypeDate
            ? Number(productInsuranceInfo?.productTypeDate)
            : null,
          productType: productInsuranceInfo?.productType,
          paymentType: Number(productInsuranceInfo?.paymentType),
          dateForm: productInsuranceInfo.dateForm,
          payment: Number(productInsuranceInfo?.payment),
          datepolis: productInsuranceInfo.datepolis,
          dateEndpolis: productInsuranceInfo.dateEndpolis
        }
      },
      personalInsuranceInfo: {
        adress: personalInsuranceInfo?.adress,
        docType: Number(personalInsuranceInfo?.docType),
        issuebdy: Number(personalInsuranceInfo?.issuebdy),
        resident: Number(personalInsuranceInfo?.resident),

        numberdocument: personalInsuranceInfo?.numberdocument,
        dateDocument: personalInsuranceInfo.dateDocument,
        sex: Number(personalInsuranceInfo?.sex),
        placeBirth: personalInsuranceInfo?.placeBirth,
        validity: personalInsuranceInfo.validity,
        dateBirth: personalInsuranceInfo.dateBirth,
        firstName: personalInsuranceInfo?.firstName,
        // middleName: personalInsuranceInfo?.middleName,
        lastName: personalInsuranceInfo?.lastName,
        clientIin: personalInsuranceInfo?.clientIin,
        clientPhone: cleanPhoneNumber(personalInsuranceInfo?.clientPhone),
        ...(personalInsuranceInfo?.middleName &&
          personalInsuranceInfo?.email && {
            middleName: personalInsuranceInfo?.middleName || '',
            email: personalInsuranceInfo?.email || ''
          })
      },
      autoInsuranceInfo: {
        dealer: autoInsuranceInfo?.dealer,
        priseAvto: autoInsuranceInfo?.priseAvto,
        markModelYear: {
          model: Number(autoInsuranceInfo?.markModelYear?.model),
          brand: Number(autoInsuranceInfo?.markModelYear?.brand),
          year:
            autoInsuranceInfo?.markModelYear?.year ||
            new Date().getFullYear().toString()
        },
        tehNum: autoInsuranceInfo?.tehNum,
        dateOfTechPasspord: autoInsuranceInfo.dateOfTechPasspord,
        numberGos: autoInsuranceInfo?.numberGos,
        vinCode: autoInsuranceInfo?.vinCode
      },
      insurance: {
        insuranseSumm: insurance?.insuranseSumm,
        insuransePremium: insurance?.insuransePremium
      },
      clientInsuranceInfo: {
        innInsurance: clientInsuranceInfo?.innInsurance,
        numberInsurance: clientInsuranceInfo?.numberInsurance
      }
    };

    try {
      await dispatch(createInsuranceOrder(newInsuranceDto)).then(
        async (response) => {
          if (response.payload) {
            navigate(
              '/insurances/' +
                (response as { payload: { uuid: string } }).payload.uuid +
                '?tab=2'
            );
            setIsButtonVisible(false);
          }
        }
      );

      // Показать уведомление
      notification.success({
        message: 'Успех',
        description: 'Заявка успешно создана',
        placement: 'topRight'
      });
    } catch (error) {
      // Обработка ошибок
      notification.error({
        message: 'Ошибка',
        description: 'Не удалось создать заявку. Попробуйте снова.',
        placement: 'topRight'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="product_info">
      <div className="auto_selects">
        <h2>Информация о продукте</h2>

        <div className="selects_box">
          {productInfoItemsInsurance.map((item, index) => {
            if (item.type === 'input') {
              return (
                <CustomInput
                  key={item.key + index}
                  value={productInsuranceInfo[item.key] as string}
                  label={item.key}
                  type={'product'}
                  labelTitle={item.label}
                  handleInputChange={handleInputChange}
                  //fieldsReadOnly={fieldsReadOnly}
                />
              );
            } else if (item.type === 'select') {
              if (item.key !== 'productTypeDate') {
                return (
                  <>
                    <CustomSelect
                      key={item.key + index}
                      isFocused={isFocused[item.key]}
                      value={productInsuranceInfo[item.key] as string}
                      option={setDictionaryOptions(
                        item.snakeCaseValues as DictTypes,
                        dicts
                      )}
                      label={item.key}
                      type={'product'}
                      labelTitle={item.label}
                      onChangeSelectHandler={onChangeSelectHandler}
                      setShowModal={item.editable ? setShowModal : undefined}
                      keyType="product"
                    />
                  </>
                );
              } else if (item.key === 'productTypeDate' && showInstallmentInput)
                return (
                  <>
                    {showInstallmentInput && (
                      <CustomSelect
                        type="text"
                        label={item.key}
                        isFocused={isFocused[item.key]}
                        value={productInsuranceInfo[item.key] as string}
                        labelTitle={item.label}
                        option={setDictionaryOptions(
                          item.snakeCaseValues as DictTypes,
                          dicts
                        )}
                        onChangeSelectHandler={onChangeSelectHandler}
                        keyType="product"
                      />
                    )}
                  </>
                );
            } else if (item.type === 'switch') {
              return (
                <CustomSwitch
                  key={item.key + index}
                  value={productInsuranceInfo[item.key] as string}
                  label={item.key}
                  type={'product'}
                  labelTitle={item.label}
                  onchangeCheckedHandler={onChangeCheckedHandler}
                  fieldsReadOnly={true}
                />
              );
            } else if (item.type === 'datepicker') {
              return (
                <CustomDatePicker
                  key={item.key + index}
                  isFocused={isFocused[item.key]}
                  value={
                    productInsuranceInfo[item.key]
                      ? dayjs(productInsuranceInfo[item.key] as Date)
                      : null
                  }
                  label={item.key}
                  type={'product'}
                  labelTitle={item.label}
                  onChangeDatePickerHandler={onChangeDatePickerHandler}
                  keyType="product"
                />
              );
            } else if (item.type === 'rangePicker') {
              const keys = item.key.split(',');
              const dates: [Dayjs | null, Dayjs | null] = [
                productInsuranceInfo[keys[0]]
                  ? dayjs(productInsuranceInfo[keys[0]] as Date)
                  : null,
                productInsuranceInfo[keys[1]]
                  ? dayjs(productInsuranceInfo[keys[1]] as Date)
                  : null
              ];
              return (
                <CustomRangePicker
                  key={item.key}
                  placeholder={item.label.split(' - ') as [string, string]}
                  value={dates}
                  format={'DD.MM.YYYY'}
                  onChange={(value) => handleRangePickerChange(value, keys)}
                  label={item.label}
                />
              );
            }
          })}
        </div>
      </div>

      <div className="auto_selects">
        <h2>Данные об авто</h2>

        <div className="first_step">
          <CustomModal
            editClass="markModelYear"
            title="Марка, модель и год выпуска"
            isModalOpen={showModal}
            handleOk={modalSaveHandler}
            handleCancel={() => setShowModal(false)}
            footer={[
              <Button key="back" onClick={() => setShowModal(false)}>
                Отмена
              </Button>,
              <Button
                disabled={saveMarkModelYearDisabled}
                key="submit"
                type="primary"
                onClick={modalSaveHandler}
              >
                Сохранить
              </Button>
            ]}
          >
            <div className="auto_selects">
              <Select
                showSearch
                optionFilterProp={'label'}
                value={modalSelectState.markAuto}
                placeholder="Марка авто"
                options={possibleMarks}
                onChange={(val) => handleModalSelectChange(val, 'markAuto')}
              />
              <Select
                showSearch
                optionFilterProp={'label'}
                value={modalSelectState.modelAuto}
                placeholder="Модель авто"
                options={possibleModels}
                onChange={(val) => handleModalSelectChange(val, 'modelAuto')}
              />
              <Input
                value={modalSelectState.issueYear || new Date().getFullYear()}
                placeholder="Год выпуска"
                onChange={(e) =>
                  handleModalSelectChange(e.target.value, 'issueYear')
                }
              />
            </div>
          </CustomModal>

          <div className="selects_box">
            <CustomInput
              value={brandModelYearValueHandler}
              label={'Марка модель и год выпуска'}
              type={'modal'}
              labelTitle={'Марка модель и год выпуска'}
              handleAutoInputChange={() => openModal()}
              onClickInputHandler={openModal}
            />
            {modalTSItemsInsuramce.map((item, index) => {
              if (item.type === 'input') {
                return (
                  <CustomInput
                    key={item.key}
                    isFocused={isFocused[item.key]}
                    value={autoInsuranceInfo[item.key] as string}
                    label={item.key}
                    type={'modal'}
                    labelTitle={item.label}
                    handleAutoInputChange={handleAutoInputChange}
                  />
                );
              } else if (item.type === 'select') {
                return (
                  <CustomSelect
                    key={item.key}
                    isFocused={isFocused[item.key]}
                    value={
                      item.editable
                        ? brandModelYearValueHandler
                        : (autoInsuranceInfo[item.key] as string)
                    }
                    option={setDictionaryOptions(
                      item.snakeCaseValues as DictTypes,
                      dicts
                    )}
                    label={item.key}
                    type={'modal'}
                    labelTitle={item.label}
                    onChangeSelectHandler={onChangeSelectHandler}
                    setShowModal={item.editable ? setShowModal : undefined}
                    keyType="modal"
                  />
                );
              } else if (item.type === 'datepicker') {
                return (
                  <CustomDatePicker
                    key={item.key}
                    isFocused={isFocused[item.key]}
                    value={
                      autoInsuranceInfo[item.key]
                        ? dayjs(autoInsuranceInfo[item.key] as Date)
                        : null
                    }
                    label={item.key}
                    type={'modal'}
                    labelTitle={item.label}
                    onChangeDatePickerHandler={onChangeDatePickerHandler}
                    keyType="modal"
                  />
                );
              }
            })}
          </div>
        </div>
      </div>

      <div className="auto_selects">
        <h2>Данные о клиенте</h2>
        <div className="personal_info">
          {personalClientInsurance.map((item, index) => {
            if (item.type === 'select') {
              return (
                <CustomSelect
                  key={item.key + index}
                  isFocused={isFocused[item.key]}
                  value={personalInsuranceInfo[item.key] as string}
                  option={setDictionaryOptions(
                    item.snakeCaseValues as DictTypes,
                    dicts
                  )}
                  label={item.key}
                  type={'personal'}
                  labelTitle={item.label}
                  onChangeSelectHandler={onChangeSelectHandler}
                  keyType="personal"
                  //fieldsReadOnly={fieldsReadOnly}
                />
              );
            } else if (item.type === 'input' && item.key !== 'workTelNumber') {
              return (
                // item.key !== 'jobAddress' &&
                // item.key !== 'workTelNumber' &&
                item.key !== 'personalAddress' &&
                item.key !== 'residentAddress' && (
                  <CustomInput
                    key={item.key}
                    isFocused={isFocused[item.key]}
                    value={personalInsuranceInfo[item.key] as string}
                    label={item.key}
                    type={'personal'}
                    labelTitle={item.label}
                    handlePersonalInputChange={handlePersonalInputChange}
                    fieldsReadOnly={item.key === 'clientIin'}
                    // readOnly={item.key === 'clientPhone' || item.key === 'clientIin'}
                    // disabled={item.key === 'clientPhone' || item.key === 'clientIin'}
                  />
                )
              );
            } else if (item.type === 'datepicker') {
              return (
                <CustomDatePicker
                  key={item.key}
                  isFocused={isFocused[item.key]}
                  value={
                    personalInsuranceInfo[item.key]
                      ? dayjs(personalInsuranceInfo[item.key] as Date)
                      : null
                  }
                  label={item.key}
                  type={'personal'}
                  labelTitle={item.label}
                  onChangeDatePickerHandler={onChangeDatePickerHandler}
                  keyType="personal"
                  //fieldsReadOnly={fieldsReadOnly}
                />
              );
            } else if (item.type === 'phoneNumber') {
              return (
                <CustomPhoneInput
                  key={item.key}
                  value={personalInsuranceInfo[item.key] as string}
                  label={item.key}
                  type={'personal'}
                  onChangeInputHandler={onChangeInputHandler}
                  readOnly={item.key === 'clientPhone'}
                  // fieldsReadOnly={fieldsReadOnly}
                />
              );
            }
          })}
        </div>
        <div>
          {loading ? (
            <Spin
              size="large"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh' // Если хотите центрировать по вертикали
              }}
            />
          ) : (
            isButtonVisible && (
              <Button
                style={{ height: '50px' }}
                className="continue_btn"
                onClick={onContinueHandler}
                disabled={stateButton || isDisabled}
              >
                Создать заявку
              </Button>
            )
          )}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default NewInsurance;
function handleInsuranceInputChange(insuranseSummValue: string, arg1: string) {
  throw new Error('Function not implemented.');
}
