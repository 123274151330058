import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/store';
import {
  getResponseByRequestId,
  resetReqSuccessIndicator
} from '@/store/offerStep.slice';
import {
  getRequestById,
  setClientInfo,
  setLoanData,
  setRequestData
} from '@/store/newOrder.slice';
import { onSetRequestId } from '@/store/orderStep.slice';
import { findDictByTitle } from '@/services';
import { formatPhoneNumber } from '@/services/formatter';
import { DictTypes } from '@/constants';
import { TClientInfoStore } from '@/types/store';
import { TClientDataState } from '@/types/state';
import { onSetSelectedBank } from '@/store/financingStep.slice';
import useFilters from './useFilters';

interface IProps {
  setClientData: React.Dispatch<React.SetStateAction<TClientDataState>>;
}

const useGetOrderData = ({ setClientData }: IProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dicts } = useAppSelector((state) => state.dict);
  const { orderApp, requestId } = useAppSelector((state) => state.newOrder);
  const { banksOffers } = useAppSelector((state) => state.offerStep);
  const { selectedBank } = useAppSelector((state) => state.financingStep);

  const { filters, handleFiltersChange } = useFilters<{
    tab: number;
  }>([{ key: 'tab', defaultValue: 0 }]);

  const [isLoading, setIsLoading] = useState(true);

  const findCurrentTab = () => {
    const financedIndex = banksOffers.findIndex(
      (offer) =>
        offer.statusType === 'financed' ||
        offer.statusType === 'waiting_of_financing' ||
        offer.statusType === 'on_rework' ||
        offer.statusType === 'financed_pledge_missing' ||
        offer.statusType === 'waiting_for_upload_files' ||
        offer.statusType ===
          'financed_waiting_for_modification_before_pledge' ||
        offer.statusType === 'mobile_client'
    );
    let currentHandler = 0;
    if (financedIndex !== -1) {
      dispatch(onSetSelectedBank(banksOffers[financedIndex]));
      currentHandler = 2;
    } else if (orderApp?.status !== 'draft') {
      currentHandler = 1;
    }
    if (filters.tab === 0) {
      handleFiltersChange({ tab: currentHandler + 1 });
    }
    dispatch(resetReqSuccessIndicator());
  };

  useEffect(() => {
    (async () => {
      if (!orderApp?.uuid && params.uuid && dicts) {
        setIsLoading(true);
        const response = await dispatch(getResponseByRequestId(params.uuid));
        if ('error' in response && response.error) navigate('/auto-credit');
        const request = await dispatch(
          getRequestById({ reqId: params.uuid, dicts: dicts || [] })
        );
        if ('error' in request && request.error) navigate('/auto-credit');
        dispatch(onSetRequestId(params.uuid));
        dispatch(setRequestData(params.uuid));
      }
      setIsLoading(false);
    })();
  }, [dicts, params.uuid, orderApp?.uuid, dispatch]);

  useEffect(() => {
    // Обновление статуса всей заявки, если обновился статус выбранного банка
    if (requestId)
      dispatch(
        getRequestById({
          reqId: requestId,
          dicts: dicts || []
        })
      );
  }, [selectedBank, requestId]);

  useEffect(() => {
    if (orderApp?.uuid) {
      const brandToFind = findDictByTitle(DictTypes.Brands, dicts).content.find(
        (brand) =>
          brand.code === orderApp.productInfo.product.markModelYear.brand
      );
      const modelToFind = findDictByTitle(DictTypes.Models, dicts).content.find(
        (model) =>
          model.code === orderApp.productInfo.product.markModelYear.model
      );
      dispatch(
        setLoanData({
          amount: orderApp.productInfo.product.costCar.replace(/\s/g, ''),
          auto: `${brandToFind?.name || ''} ${modelToFind?.name || ''}, ${
            orderApp.productInfo.product.markModelYear.year
          }`
        })
      );

      const clientInfo: TClientInfoStore = {
        id: orderApp.code,
        fullName: [
          orderApp.personalInfo.firstName,
          orderApp.personalInfo.lastName,
          orderApp.personalInfo.middleName
        ],
        iin: orderApp.personalInfo.clientIin || '',
        telNum: orderApp.personalInfo.phoneNumber
      };
      setClientData((prevState) => ({
        ...prevState,
        id: clientInfo?.id || '',
        firstName: clientInfo?.fullName[1] || '',
        middleName: clientInfo?.fullName[2] || '',
        lastName: clientInfo?.fullName[0] || '',
        iin: clientInfo?.iin || '',
        telNum: formatPhoneNumber(clientInfo?.telNum || '')
      }));
      dispatch(setClientInfo(clientInfo));
      findCurrentTab();
    }
  }, [orderApp?.uuid]);

  return { isLoading: isLoading || !orderApp?.uuid };
};

export default useGetOrderData;
