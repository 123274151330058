import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import '@/styles/App.scss';
import { dispatch, useAppSelector } from 'store';
import { tokenValidate } from './store/auth.slice';
import { getUserAccount } from './store/userInfo.slice';
import { ProtectedRoute } from './components/Shared/Route/PrivateRoute';
import { Layout } from './components/Layout/Layout';
import MainPage from './components/App/MainPage/MainPage';
import { AutoCred } from '@/components/App/AutoCred/AutoCred';
import Calculator from './components/App/Calculator/Calculator';
import { NewOrder } from '@/components/App/NewOrder/NewOrder';
import Insurance from '@/components/App/Insurance/Insuranсe';
import Personal from '@/components/App/Personal/Personal';
import Leasing from '@/components/App/Leasing/Leasing';
import Clients from '@/components/App/Clients/Clients';
import { ClientRequestsDetails } from '@/components/App/ClientRequestsDetails/ClientRequestsDetails';
import Reports from '@/components/App/Reports/Reports';
import { LoginPage } from '@/components/Auth/Auth';
import { ResetPassword } from '@/components/Auth/ResetPassword';
import { InsuranceFirstStep } from './components/App/Insurance/InsuranceOrder/InsuranseFirstStep';
import NewLeasing from '@/components/App/NewLeasing/NewLeasing';
// import FreedomPostCred from '@/components/PostCred/FreedomPostCred/FreedomPostCred';
import { jwtDecode } from 'jwt-decode';
import Dictionaries from '@/components/AdminPanel/Dictionaries/Dictionaries';
import Executor from '@/components/AdminPanel/Executor/Executor';
import DealersPoints from '@/components/AdminPanel/DealersPoints/DealersPoints';
import Models from '@/components/AdminPanel/Models/Models';
import Products from '@/components/AdminPanel/Products/Products';
import PromoProductModel from '@/components/AdminPanel/PromoProductModel/PromoProductModel';
import { AdminClients } from './components/AdminPanel/Clients/AdminClients';
import { SalePoints } from '@/components/AdminPanel/SalePoints/SalePoints';
import { Users } from './components/AdminPanel/Users/Users';
import { Banks } from '@/components/AdminPanel/Banks/Banks';
import { FlashLogs } from '@/components/AdminPanel/FlashLogs/FlashLogs';
import { Ide } from '@/components/AdminPanel/Ide/Ide';
import { Litro } from '@/components/App/LitroPnd/Litro';
import { LitroForm } from '@/components/App/LitroPnd/LitroForm/LitroForm';
import BiometricScan from '@/components/BiometricScan/BiometricScan';
import { apiConnector } from '@/integrations/api.connector';
import { ExistedOrder } from './components/App/NewOrder/ExistedOrder';
import ExistedInsurance from './components/App/Insurance/InsuranceOrder/existed-insurance';
// import Insurance from './components/App/Insurance/Insuranсe';
// import Insurance from 'components/Insurance/Insuranсe';

// SocketConnector.onResponses = async (data) => {
//   const currentState = store.getState();
//   const isUpdated = !!data?.response_date;
//   const isCreateError = data?.ext_uuid?.length <= 6;
//   // console.log('trash >>', data);
//   if (isCreateError) {
//     // console.log('create error', data);
//   }
//   if (isUpdated) {
//     // console.log('specific update >>', data);
//   }
//   const reqId: string =
//     currentState?.newOrder?.requestId ||
//     currentState?.newOrder?.orderApp?.uuid ||
//     '';
//   if (reqId && data?.request?.request_uuid === reqId) {
//     await dispatch(getResponseByRequestId(reqId));
//   }
// };
//
// SocketConnector.onBankFlashLogCreated = (data) => {
//   // console.log(data);
//   const validMessage = serverNotificationsMapper(data);
//   dispatch(addNewNotification(validMessage));
// };
// SocketConnector.insuranceFilesUpdated = (data) => {
//   // const validMessage = serverNotificationsMapper(data);
//   // dispatch(addNewNotification(validMessage));
//   console.log(data);
// };

const Redirect = () => {
  const navigate = useNavigate();
  const { isUserAuth } = useAppSelector((state) => state.auth);
  useEffect(() => {
    navigate(isUserAuth ? '/auto-credit' : 'login');
  }, [isUserAuth, navigate]);
  return <></>;
};

export const App = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const { isUserAuth } = useAppSelector((state) => state.auth);
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    // const isSsl = process.env.REACT_APP_IS_SSL === 'true';
    dispatch(tokenValidate());

    const token = localStorage.getItem('accessToken');
    if (token) {
      const user: { actions: number[] } = jwtDecode(token);
      if (user.actions.includes(0)) {
        setIsAdmin(true);
      }
    }
    setInterval(async () => {
      await apiConnector.refreshToken();
    }, 180000);
  }, []);

  useEffect(() => {
    const modifiedString = params.pathname.replace(/\/([^\/]+)\/.*/, '/$1');
    if (
      modifiedString !== '/reset_password' &&
      !modifiedString.includes('/freedom-finance-postcred') &&
      !modifiedString.includes('biometric')
    ) {
      if (!isUserAuth) {
        navigate('/login');
      } else {
        userInfo?.position.name === 'Отчеты' && navigate('/reports');
      }
    }
    if (isUserAuth) {
      dispatch(getUserAccount());
      // SocketConnector.initSocketIO();
    }
    const token = localStorage.getItem('accessToken');
    if (token) {
      const user: { actions: number[] } = jwtDecode(token);
      if (user.actions.includes(0)) {
        setIsAdmin(true);
      }
    }
  }, [isUserAuth]);

  useEffect(() => {
    dispatch(tokenValidate());
    //
    // if (userInfo) {
    //   userInfo?.position.name === 'Отчеты'
    //     ? navigate('/reports')
    //     : navigate('/auto-credit');
    // }
  }, []);

  return (
    <>
      <BiometricScan />
      <Routes>
        <Route path="*" element={<Redirect />} />
        {isUserAuth ? (
          <>
            <Route path="/" element={<Layout />}>
              <Route
                element={
                  <ProtectedRoute
                    redirectPath={'/login'}
                    isAllowed={isUserAuth}
                  />
                }
              >
                <Route path="/" element={<MainPage />} />
                <Route path="/auto-credit" element={<AutoCred />} />
                <Route path="/calculator" element={<Calculator />} />
                <Route path="/auto-credit/create" element={<NewOrder />} />
                <Route path="/auto-credit/:uuid" element={<ExistedOrder />} />
                <Route path="/insurances" element={<Insurance />} />
                <Route
                  path="/insurances/create"
                  element={<InsuranceFirstStep />}
                />
                <Route
                  path="/insurances/:uuid"
                  element={<ExistedInsurance />}
                />
                <Route path="/personal" element={<Personal />} />
                <Route path="/pos-cred" element={<></>} />
                <Route path="/settlement" element={<></>} />
                <Route path="/leasing" element={<Leasing />} />
                <Route path="/newLeasing" element={<NewLeasing />} />
                <Route path="/clients" element={<Clients />} />
                <Route
                  path="/clientRequestsDetails"
                  element={<ClientRequestsDetails />}
                />
                <Route path="/additional-product-pnd" element={<Litro />} />
                <Route path="/pnd-form" element={<LitroForm />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/editor" element={<></>} />
                {isAdmin && (
                  <>
                    <Route path="/adminPage" element={<>"admin"</>} />
                    <Route
                      path="/adminPage/sale-points"
                      element={<SalePoints />}
                    />
                    <Route path="/adminPage/banks" element={<Banks />} />
                    <Route
                      path="/adminPage/clients"
                      element={<AdminClients />}
                    />
                    <Route
                      path="/adminPage/promotion"
                      element={<PromoProductModel />}
                    />
                    <Route path="/adminPage/models" element={<Models />} />
                    <Route path="/adminPage/products" element={<Products />} />
                    <Route path="/adminPage/users" element={<Users />} />
                    <Route path="/adminPage/executor" element={<Executor />} />
                    <Route path="/adminPage/ide" element={<Ide />} />
                    <Route path="/adminPage/logs" element={<FlashLogs />} />
                    <Route path="/adminPage/reports" element={<Reports />} />
                    <Route
                      path="/adminPage/dealers"
                      element={<DealersPoints />}
                    />
                    <Route
                      path="adminPage/dictionaries/:dictType"
                      element={<Dictionaries />}
                    />
                  </>
                )}
              </Route>
            </Route>
          </>
        ) : (
          <>
            <Route path="/login" element={<LoginPage />} />
            <Route
              path={`/reset_password/:token`}
              element={<ResetPassword />}
            />
          </>
        )}
        {/*<Route*/}
        {/*  path={'/biometric/:biometricSession'}*/}
        {/*  element={<BiometricScan />}*/}
        {/*/>*/}
        {/*<Route path={'/freedom-finance-postcred'} element={<FreedomPostCred />} />*/}
      </Routes>
    </>
  );
};
