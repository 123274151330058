import '@/styles/Personal.scss';

import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from '@/store';
import { setPageTitle } from '@/store/newOrder.slice';

import { Spin } from 'antd';
import BreadCrumbs from '@/components/Shared/BreadCrumbs';
import { PhotoUpload } from '@/components/Shared/Input/PhotoUpload';

const formatPhoneNumber = (phone: string) => {
  return phone.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
};

const Personal = () => {
  const dispatch = useAppDispatch();
  const { userInfo, userInfoLoading } = useAppSelector(
    (state) => state.userInfo
  );

  const [uploadFiles, setUploadFiles] = useState<string | null>(null);

  useEffect(() => {
    dispatch(setPageTitle('Личный кабинет'));
  }, []);

  // TODO: Проверить, есть ли необходимость в этой части
  // useEffect(() => {
  //   if (reqSuccessIndicator === true && !location.pathname.includes('/auto-credit/')) {
  //     changeData();
  //   }
  // }, [banksOffers]);

  const handleFileChange = (
    file: File | null,
    fileBase64: string | null,
    type: string
  ) => {
    setUploadFiles(fileBase64);
  };

  if (userInfoLoading) {
    return <Spin fullscreen={true} />;
  }

  return (
    <div className="personal">
      <BreadCrumbs links={[{ href: '/', text: 'Главная' }]} current="Профиль" />

      <div className="personal__content">
        <h2 className="personal__title">Профиль сотрудника</h2>

        <div className="personal__main">
          <div className="personal__photo">
            <PhotoUpload
              urlImg={''}
              file={uploadFiles}
              onFileChange={handleFileChange}
              title={`Фотография сотрудника`}
              type="clientPhoto"
            />
          </div>
          <div className="personal__info">
            <div className="personal__info-item">
              <b className="personal__info-label">ФИО:</b>
              <span className="personal__info-value">
                {userInfo?.last_name} {userInfo?.first_name}{' '}
                {userInfo?.middle_name}
              </span>
            </div>
            <div className="personal__info-item">
              <b className="personal__info-label">Дата рождения:</b>
              <span className="personal__info-value">
                {dayjs(userInfo?.birth_date || new Date()).format('DD.MM.YYYY')}
              </span>
            </div>
            <div className="personal__info-item">
              <b className="personal__info-label">Номер телефона:</b>
              <span className="personal__info-value">
                +{formatPhoneNumber(userInfo?.phone_number || '')}
              </span>
            </div>
            <div className="personal__info-item">
              <b className="personal__info-label">Email:</b>
              <span className="personal__info-value">{userInfo?.email}</span>
            </div>
            {userInfo?.work_date_start && (
              <div className="personal__info-item">
                <b className="personal__info-label">Дата регистрации:</b>
                <span className="personal__info-value">
                  {dayjs(userInfo.work_date_start).format('DD.MM.YYYY')}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Personal;
