import * as React from 'react';
import * as dayjs from 'dayjs';

import { ColumnsType } from 'antd/es/table';

import CellInner from '../components/App/AutoCred/UI/cell-inner/CellInner';

import { formatPrice } from '../services/formatter';
import { TNewInsuranceResponse } from '../types/insuranceMapper';

export const columnsInsurance: ColumnsType<TNewInsuranceResponse> = [
  {
    title: 'Клиент',
    dataIndex: 'client',
    key: 'clientIin',
    width: 240,
    render: (text, record) => {
      const fullName = `${record?.lastName} ${record?.firstName} ${record?.middleName}`;
      return (
        <CellInner
          hasAvatar
          title={fullName.trim() === '' ? record?.clientIin : fullName}
          content={record?.clientPhone || ''}
        />
      );
    }
  },
  {
    title: 'Марка/модель авто',
    dataIndex: 'model',
    key: 'model',
    width: 180,

    render: (text, record) => {
      return record.model ? (
        <>
          <div>
            <CellInner
              title={`${formatPrice(record.vehicleCost)} ₸` || ''}
              content={
                `${record?.brand} ${record.model}, ${record.year}г.` || ''
              }
            />
          </div>
        </>
      ) : (
        <p style={{ color: 'red', fontWeight: 'bold' }}>Нет данных</p>
      );
    }
  },
  {
    title: 'Страховая премия',
    dataIndex: 'insurance_premium',
    key: 'insurance_premium',
    width: 150,
    render: (text, record) => {
      return (
        <div style={{ fontSize: '12px', fontWeight: '500' }}>
          {formatPrice(record.insurancePrem) || 0}
        </div>
      );
    }
  },
  {
    title: 'Создано',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 150,
    render: (text, record) => {
      return (
        <div>
          <CellInner
            title={dayjs(record.createDate).format('DD.MM.YYYY, HH:mm')}
            content={`${record?.user?.first_name || ''} ${
              record?.user?.last_name
            } ${record?.user?.middle_name}`}
          />
        </div>
      );
    }
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    width: 120,
    sorter: (a, b) => a.status.code.localeCompare(b.status.code),
    render: (text, record) => {
      let statusColor;
      let statusText;
      let textColor = 'white';

      switch (record.status.code) {
        case 'waiting_bank_response':
          statusColor = '#FFF7D9';
          statusText = 'Ожидает оплаты';
          textColor = 'black';
          break;
        case 'waiting_of_payment':
          statusColor = '#FFF7D9';
          statusText = 'Ожидает оплаты';
          textColor = 'black';
          break;
        case 'error':
          statusColor = '#ff2e1f';
          statusText = 'Ошибка в процессе';
          break;
        case 'completed':
          statusColor = '#0CAF60';
          statusText = 'Полис выдан';
          break;
        case 'financed':
          statusColor = '#0CAF60';
          statusText = 'Полис выдан';
          break;
        case 'sms_verificating':
          statusColor = '#687588';
          statusText = 'SMS верификаця';
          break;
        case 'draft':
          statusColor = '#687588';
          statusText = 'Создан';
          break;
        default:
          statusColor = 'rgb(17 24 39)';
          statusText = 'DRAFT';
      }

      return (
        <div>
          <div
            style={{
              padding: '4px 8px',
              borderRadius: '8px',
              background: statusColor,
              minWidth: '108px',
              width: 'max-content'
            }}
          >
            <p
              style={{
                textAlign: 'center',
                fontSize: '10px',
                fontWeight: '700',
                color: textColor
              }}
            >
              {statusText}
            </p>
          </div>
        </div>
      );
    }
  }
];
