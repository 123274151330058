import { DictTypes } from '@/constants';
import { findDictByTitle } from '@/services';
import { useAppSelector } from '@/store';
import { useEffect, useState } from 'react';

const useRequestedConditions = () => {
  const { dicts } = useAppSelector((state) => state.dict);
  const { productInsuranceInfo: storeProductInfo, autoInsuranceInfo } =
    useAppSelector((state) => state.orderInsurance);

  const [requestedConditions, setRequestedConditions] =
    useState<RequestedConditionsInterface | null>(null);

  useEffect(() => {
    if (
      !autoInsuranceInfo.markModelYear.brand ||
      !autoInsuranceInfo.markModelYear.model ||
      !storeProductInfo.payment ||
      !storeProductInfo.paymentType ||
      !dicts
    )
      return;

    const brandsDict = findDictByTitle(DictTypes.Brands, dicts);
    const brand = brandsDict.content.find(
      (brand) => brand.code === Number(autoInsuranceInfo.markModelYear.brand)
    );

    const modelsArray = findDictByTitle(DictTypes.Models, dicts);
    const model = modelsArray.content.find(
      (content) =>
        content.code === Number(autoInsuranceInfo.markModelYear.model || '')
    );

    const paymentsArray = findDictByTitle(DictTypes.InsurancePayment, dicts);
    const paymentMethod = paymentsArray.content.find(
      (content) => content.code === Number(storeProductInfo.payment || '')
    );

    const paymentTypesArray = findDictByTitle(
      DictTypes.InsuranсeInstallmentTypes,
      dicts
    );
    const paymentType = paymentTypesArray.content.find(
      (content) => content.code === Number(storeProductInfo.paymentType || '')
    );

    setRequestedConditions({
      vehicleTitle: `${brand?.name} ${model?.name}`,
      vehicleYear: `${model?.year}г.`,
      vehicleNumber: autoInsuranceInfo.numberGos as string,
      vehiclePrice: Number(autoInsuranceInfo.priseAvto) || 0,
      paymentMethod: paymentMethod?.name || '',
      paymentType: paymentType?.name || ''
    });
  }, [autoInsuranceInfo, storeProductInfo, dicts]);

  return { requestedConditions };
};

export default useRequestedConditions;

export interface RequestedConditionsInterface {
  vehicleTitle: string;
  vehicleYear: string;
  vehicleNumber: string;
  vehiclePrice: number;
  paymentMethod: string;
  paymentType: string;
}
